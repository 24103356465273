const settingsConfig = {
	layout: {
		style: 'layout1', // layout-1 layout-2 layout-3
		config: {
			toolbar: {
				display: true
			}
		} // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
	},
	customScrollbars: false,
	animations: true,
	direction: 'ltr', // rtl, ltr
	theme: {
		main: `${process.env.REACT_APP_THEME_LAYOUT}`,
		navbar: 'mainThemeLight',
		// toolbar: 'mainThemeLight2',
		footer: 'mainThemeDark'
	}
}

export default settingsConfig
