import React from 'react'
import { Redirect } from 'react-router-dom'
import globalConfig from 'app/fuse-configs/globalConfig'

const PermissionsConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: ['/user/permissions/group/:groupId?'],
			component: React.lazy(() => import('./PermissionsApp'))
		},
		{
			path: '/user/permissions',
			component: () => <Redirect to="/user/permissions/group/all" />
		}
	]
}

export default PermissionsConfig
