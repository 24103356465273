import FuseAnimate from '@fuse/core/FuseAnimate'
import { useDeepCompareEffect } from '@fuse/hooks'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { TableLoader } from 'app/@artemis/Loader'
import { createPath } from 'app/store/breadcrumbSlice'
import { hideTabs } from 'app/store/settingsSlice'
import withReducer from 'app/store/withReducer'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import ForecastDialog from './ForecastDialog'
import Forecasts from './Forecasts'
import Shortcut from './Shortcut'
import ShortcutAdd from './ShortcutAdd'
import ShortcutDialog from './ShortcutDialog'
import reducer from './store'
import { getShortcuts, reorderShortcuts, selectShortcuts } from './store/dashboardSlice'
import { getProjectsForecasts, openForecastDialog, selectProjectsForecasts } from './store/forecastsSlice'
import { getFormShortcuts, selectFormShortcuts } from './store/formsSlice'
import { getProjects } from './store/projectsSlice'

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}))(TableRow)

const useStyles = makeStyles(theme => ({
	background: {
		background: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main)
	},
	board: {
		cursor: 'pointer',
		transitionProperty: 'box-shadow border-color',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		background: theme.palette.primary.dark,
		color: theme.palette.getContrastText(theme.palette.primary.dark)
	},
	newBoard: {
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: fade(theme.palette.getContrastText(theme.palette.primary.main), 0.6),
		'&:hover': {
			borderColor: fade(theme.palette.getContrastText(theme.palette.primary.main), 0.8)
		}
	},
	typeIcon: {
		'&.chart:before': {
			content: "'insert_chart'",
			color: '#4CAF50'
		},
		'&.copy:before': {
			content: "'file_copy'",
			color: '#1565C0'
		},
		'&.report:before': {
			content: "'trending_up'",
			color: '#4CAF50'
		}
	}
}))

const Breadcrumb = {
	id: 'root',
	title: 'Root',
	type: 'group',
	icon: 'star',
	children: [
		{
			id: 'dashboard',
			title: 'Dashboard',
			type: 'item',
			url: '/dashboard'
		}
	]
}

const Dashboard = props => {
	const dispatch = useDispatch()

	const classes = useStyles(props)

	/**
	 * Local instance.
	 */
	const [isLoading, setIsLoading] = useState(true)

	const [isFormsLoading, setIsFormsLoading] = useState(true)

	/**
	 * Use Selectors.
	 */
	// eslint-disable-next-line no-shadow
	const shortcuts = useSelector(selectShortcuts)
	// eslint-disable-next-line no-shadow
	const formShortcuts = useSelector(selectFormShortcuts)
	// eslint-disable-next-line no-shadow
	const projectsForecasts = useSelector(selectProjectsForecasts)
	// eslint-disable-next-line no-shadow
	const tabsEnabled = useSelector(({ settings }) => settings.showTabs)

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 */
	useDeepCompareEffect(() => {
		/**
		 * Get shortcuts data
		 */
		dispatch(getShortcuts()).then(() => {
			setIsLoading(false)
		})
		/**
		 * Get form shortcuts data
		 */
		dispatch(getFormShortcuts()).then(() => {
			setIsFormsLoading(false)
		})
		dispatch(getProjects()).then(() => {
			dispatch(getProjectsForecasts())
		})
		return () => {
			// optionally return a cleanup function if necessary
			setIsLoading(true)
			setIsFormsLoading(true)
		}
		// Run when the variables object actually has changes.
	}, [dispatch])

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 */
	useEffect(() => {
		/**
		 * Generate breadcrumbs
		 */
		dispatch(createPath(Breadcrumb))
		/**
		 * Hide tabs
		 */
		if (tabsEnabled) {
			dispatch(hideTabs())
		}
	}, [dispatch, tabsEnabled])

	const onDragEnd = result => {
		const { source, destination } = result

		// Dropped nowhere
		if (!destination) {
			return
		}

		// Did not move anywhere - can bail early
		if (source.droppableId === destination.droppableId && source.index === destination.index) {
			return
		}

		if (result.type === 'list') {
			dispatch(reorderShortcuts(result))
		}
	}

	// Return JSX code
	return (
		<div className="flex flex-grow flex-col items-center h-full">
			<div className={clsx(classes.background, 'w-full h-full')}>
				<div className="flex flex-grow flex-shrink-0 flex-col items-center container px-16 md:px-24">
					<FuseAnimate>
						<Typography className="mt-22 sm:mt-22 sm:py-12 text-32 sm:text-40 font-300" color="inherit">
							Dashboard
						</Typography>
					</FuseAnimate>

					{isLoading && (
						<FuseAnimate className="justify-center py-32" delay={50}>
							<div className="flex flex-1 items-center justify-center h-full">
								<Typography color="textSecondary" variant="h6">
									Loading.. Please wait
								</Typography>
							</div>
						</FuseAnimate>
					)}

					{!isLoading && (
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="list" type="list" direction="horizontal">
								{provided => (
									<div
										ref={provided.innerRef}
										className="flex container py-16 md:py-16 px-8 md:px-12"
									>
										<div
											className="flex flex-wrap w-full justify-center py-16 px-16"
											enter={{
												animation: 'transition.slideUpBigIn',
												duration: 300
											}}
										>
											{shortcuts.map((item, index) => (
												<Shortcut key={item.id} shortcut={item} index={index} id={item.id} />
											))}

											{provided.placeholder}

											<ShortcutAdd key={shortcuts.length} />
										</div>
									</div>
								)}
							</Droppable>
						</DragDropContext>
					)}
				</div>
			</div>

			<div className="w-full h-full">
				<div className="flex flex-col md:flex-row sm:p-8 container">
					<div className="flex flex-1 flex-col min-w-0">
						<FuseAnimate delay={600}>
							<Typography className="p-16 pb-8 text-18 font-300">Projects - Shortcuts</Typography>
						</FuseAnimate>
						<div className="flex flex-col sm:flex sm:flex-row pb-32">
							<div className="widget flex w-full p-16">
								<Paper className="w-full rounded-8 shadow">
									<div className="table-responsive">
										<TableLoader length={formShortcuts.length} loading={isFormsLoading}>
											{formShortcuts.length === 0 && (
												<FuseAnimate delay={50}>
													<div className="flex flex-col md:flex-row sm:p-8 container">
														<div className="flex flex-1 flex-col h-full p-16">
															<Typography color="textSecondary" variant="h6">
																No projects where found
															</Typography>
														</div>
													</div>
												</FuseAnimate>
											)}

											{formShortcuts.length > 0 && (
												<Table className="w-full">
													<TableHead>
														<TableRow>
															<TableCell className="max-w-64 w-64 p-0 text-center">
																{' '}
															</TableCell>
															<TableCell>Name</TableCell>
															<TableCell className="hidden sm:table-cell">Type</TableCell>
															<TableCell className="hidden sm:table-cell">
																Owner
															</TableCell>
															<TableCell className="hidden sm:table-cell">
																Modified
															</TableCell>
														</TableRow>
													</TableHead>

													<TableBody>
														{formShortcuts.map((shortcut, key) => {
															const {
																type,
																shortcut_name,
																icon,
																creator,
																updated,
																link
															} = shortcut

															// Return JSX code
															return (
																<StyledTableRow
																	hover
																	className="cursor-pointer"
																	onClick={() => {
																		props.history.push(link, { lock: 1 })
																	}}
																	key={key}
																>
																	<TableCell className="max-w-64 w-64 p-0 text-center">
																		<Icon
																			className={clsx(classes.typeIcon, icon)}
																		/>
																	</TableCell>
																	<TableCell>{shortcut_name}</TableCell>
																	<TableCell>{type}</TableCell>
																	<TableCell>{creator}</TableCell>
																	<TableCell>{updated}</TableCell>
																</StyledTableRow>
															)
														})}
													</TableBody>
												</Table>
											)}
										</TableLoader>
									</div>
								</Paper>
							</div>
						</div>
					</div>

					<div className="flex-1 flex-col min-w-0">
						<FuseAnimate delay={600}>
							<Typography className="p-16 pb-8 text-18 font-300">Projects - Forecasts</Typography>
						</FuseAnimate>
						{projectsForecasts
							.map((item, key) => {
								return (
									<div key={item.project_id} className="widget flex w-full sm:w-1/2 p-16">
										<Forecasts project_id={item.project_id} forecasts={item.forecasts} />
									</div>
								)
							})
							.reduce(function (r, element, index) {
								index % 2 === 0 && r.push([])
								r[r.length - 1].push(element)
								return r
							}, [])
							.map(function (rowContent, key) {
								return (
									<div className="flex" key={key}>
										{rowContent}
									</div>
								)
							})}
						<div className="flex">
							<div className="widget flex w-full sm:w-1/2 p-16">
								<Card className="w-full rounded-8 shadow">
									<div className="p-16">
										<Button
											classes={{
												root: 'normal-case font-600 w-full rounded-none h-64',
												label: 'justify-start'
											}}
											onClick={ev => {
												ev.preventDefault()
												dispatch(openForecastDialog())
											}}
										>
											<Icon className="text-32 text-red">add_circle</Icon>
											<span className="mx-8">Add project</span>
										</Button>
									</div>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ShortcutDialog />
			<ForecastDialog />
		</div>
	)
}

export default withReducer('dashboardApp', reducer)(Dashboard)
