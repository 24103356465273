import { createSlice } from '@reduxjs/toolkit'

const breadcrumbSlice = createSlice({
	name: 'breadcrumbApp',
	initialState: {
		path: []
	},
	reducers: {
		createPath: (state, action) => {
			state.path = action.payload
		}
	}
})

export const { createPath } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
