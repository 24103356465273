import globalConfig from 'app/fuse-configs/globalConfig'
import Prototypes from './prototype/Prototypes'
import Prototype from './prototype/Prototype'
import Settings from './Settings'

const SettingsConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/settings/prototypes/prototype/:id',
			component: Prototype
		},
		{
			path: '/settings/prototypes',
			component: Prototypes
		},
		{
			path: '/settings',
			component: Settings
		}
	]
}

export default SettingsConfig
