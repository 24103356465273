import FuseScrollbars from '@fuse/core/FuseScrollbars'
import { useDeepCompareEffect } from '@fuse/hooks'
import { red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertTitle } from '@material-ui/lab'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSwipeable } from 'react-swipeable'
import { toggleNotifications } from '../../store/settingsSlice'
import withReducer from '../../store/withReducer'
import reducer from './store'
import { getNotifications, removeNotification, selectNotifications } from './store/notificationsSlice'
// import Button from '@material-ui/core/Button'

const Transition = React.forwardRef(function Transition(props, ref) {
	const theme = useTheme()
	return <Slide direction={theme.direction === 'ltr' ? 'left' : 'right'} ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2)
		}
	},
	buttonWrapper: {
		position: 'absolute',
		right: 0,
		top: 160,
		display: 'flex',
		flexDirection: 'column',
		items: 'center',
		justify: 'center',
		overflow: 'hidden',
		opacity: 0.9,
		padding: 0,
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		zIndex: 999,
		color: theme.palette.getContrastText(red[500]),
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[500],
			opacity: 1
		}
	},
	button: {
		minWidth: 40,
		width: 40,
		height: 40,
		margin: 0
	},
	settingsButton: {
		'& $buttonIcon': {
			animation: '$rotating 3s linear infinite'
		}
	},
	schemesButton: {},
	'@keyframes rotating': {
		from: {
			transform: 'rotate(0deg)'
		},
		to: {
			transform: 'rotate(360deg)'
		}
	},
	buttonIcon: {
		fontSize: 20
	},
	dialogPaper: {
		position: 'fixed',
		width: 380,
		maxWidth: '90vw',
		backgroundColor: theme.palette.background.paper,
		top: 0,
		height: '100%',
		minHeight: '100%',
		bottom: 0,
		right: 0,
		margin: 0,
		zIndex: 1000,
		borderRadius: 0
	},
	item: {
		'&.toBeRemoved': {
			opacity: 0.2
		}
	}
}))

const NotificationsPanel = () => {
	const classes = useStyles()

	const theme = useTheme()
	const dispatch = useDispatch()

	/**
	 * Use Selectors.
	 */
	// eslint-disable-next-line no-shadow
	const notificationsEnable = useSelector(({ settings }) => settings.notifications)
	// eslint-disable-next-line no-shadow
	const notifications = useSelector(selectNotifications)

	/*
	 * @fetch notifications
	 */
	useDeepCompareEffect(() => {
		dispatch(getNotifications())
	}, [dispatch])

	const open = false
	const handlerOptions = {
		onSwipedLeft: () => {
			return open && theme.direction === 'rtl' && handleClose()
		},
		onSwipedRight: () => {
			return open && theme.direction === 'ltr' && handleClose()
		}
	}
	const settingsHandlers = useSwipeable(handlerOptions)

	const handleClose = () => {
		dispatch(toggleNotifications())
	}

	return (
		<>
			<Dialog
				TransitionComponent={Transition}
				aria-labelledby="settings-panel"
				aria-describedby="settings"
				open={notificationsEnable}
				keepMounted
				onClose={handleClose}
				BackdropProps={{ invisible: true }}
				classes={{
					paper: clsx(classes.dialogPaper, 'shadow-lg')
				}}
				{...settingsHandlers}
			>
				<FuseScrollbars className="p-16 sm:p-32">
					<IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose}>
						<Icon>close</Icon>
					</IconButton>

					<Typography className="mb-32" variant="h6">
						Notifications
					</Typography>

					<div className={classes.root}>
						{notifications.map((message, key) => {
							let type = 'info'
							// eslint-default-case
							switch (message.message_type) {
								case 'alert':
									type = 'warning'
									break
								default:
									type = 'info'
									break
							}

							return (
								<Alert
									action={
										<IconButton
											aria-label="close"
											color="inherit"
											size="small"
											onClick={e => {
												dispatch(removeNotification({ id: message.id }))
											}}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									}
									className={clsx(classes.item, { toBeRemoved: message.toBeRemoved })}
									key={key}
									severity={type}
								>
									{!message.data.Email && <AlertTitle>{message.type}</AlertTitle>}
									{message.data.Email && message.data.Email.Subject && (
										<AlertTitle>{message.data.Email.Subject}</AlertTitle>
									)}
									{message.data.Email && message.data.Email.Body && (
										<>
											{/*<p
											dangerouslySetInnerHTML={{
												__html: message.data.Email.Body
											}}
											/>*/}
										</>
									)}
									— <strong>{message.created_at_formatted}</strong>
								</Alert>
							)
						})}
						{/*
						<Button disabled={open} variant="outlined" onClick={() => {}}>
							Clear
						</Button>
						*/}
					</div>
				</FuseScrollbars>
			</Dialog>
		</>
	)
}

export default withReducer('notificationsApp', reducer)(NotificationsPanel)
