import { useDeepCompareEffect } from '@fuse/hooks'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import CloseIcon from '@material-ui/icons/Close'
import HomeIcon from '@material-ui/icons/Home'
import { unwrapResult } from '@reduxjs/toolkit'
import { deleteTab, getTabs, selectTab, setRootTabLink, startEditTab, updateTab } from 'app/store/tabsSlice'
import withReducer from 'app/store/withReducer'
import clsx from 'clsx'
import { createBrowserHistory } from 'history'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router'
import reducer from './store'

const tabHeight = '40px' // default: '48px'

const useStyles = makeStyles({
	tabsRoot: {
		minHeight: tabHeight,
		height: tabHeight,
		'&.deleting': {
			opacity: 0.2
		}
	},
	iconLabelWrapper: {
		flexDirection: 'row-reverse'
	},
	iconContainer: {
		'&:hover': {
			color: 'black'
		},
		marginLeft: 10
	},
	box: {
		backgroundColor: '#f2f2f2',
		borderBottom: 1,
		borderColor: 'black',
		borderBottomWidth: 1
	},
	tabName: {
		color: 'gray'
	}
})

const TabPanel = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const bowserHistory = createBrowserHistory()
	const history = useHistory()

	/**
	 * Local instance
	 */
	const [isDeleting, setIsDeleting] = useState(false)

	const [loaded, setLoaded] = useState(false)

	/**
	 * Use Selectors.
	 */
	const tabs = useSelector(({ tabs }) => tabs)
	// eslint-disable-next-line no-shadow
	const { items, selected, rootTabLink } = tabs

	useDeepCompareEffect(() => {
		if (!loaded) {
			/**
			 * Get tabs data
			 */
			dispatch(getTabs())
				.then(unwrapResult)
				.then(() => {
					setLoaded(true)
				})
		}
		return () => {
			// optionally return a cleanup function if necessary
		}
		// Run when the variables object actually has changes.
	}, [dispatch])

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 */
	useEffect(() => {
		// Trigger change tab based on URL
		if (loaded && selected === -1) {
			const explodeURL = history.location.pathname.split('/')
			const tabIndex = explodeURL.findIndex(param => param === 'tab')
			if (tabIndex !== -1) {
				const tabID = explodeURL[tabIndex + 1]
				if (tabID && tabID !== selected) {
					// eslint-disable-next-line radix
					const selectedIndex = items.findIndex(tab => tab.id === parseInt(tabID))
					if (selectedIndex !== -1) {
						dispatch(selectTab(selectedIndex))
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, loaded])

	/**
	 * Similar to componentDidMount and componentDidUpdate:
	 */
	useEffect(() => {
		if (selected !== -1) {
			history.push(`${items[selected].link}/tab/${items[selected].id}`, { tab: selected })
		} else {
			if (rootTabLink) {
				let rootParts = rootTabLink.split('/')

				if (history.location.state && history.location.state.nav) {
					if (history.location.state.nav !== `/${rootParts[1]}/${rootParts[2]}`) {
						// Sets current as root
						dispatch(setRootTabLink(history.location.pathname))

						return
					}
				}

				// Go to last opened
				if (rootTabLink !== history.location.pathname) {
					if (
						!history.location.state ||
						(history.location.state &&
							typeof history.location.state.tab !== 'number' &&
							history.location.state &&
							!history.location.state.tab)
					) {
						return
					}

					history.push(rootTabLink, { tab: -1 })
				}
			} else {
				//<-- rootTabLink is null
				const explodeURL = history.location.pathname.split('/')

				// Sets current as root
				dispatch(setRootTabLink(`/${explodeURL[1]}/${explodeURL[2]}`))
			}
		}

		/**
		 * navigate between pages
		 */
		return history.listen(location => {
			// Follow navigation to store last opened
			if (location.state && location.state.tab === -1) {
				dispatch(selectTab(-1))
				dispatch(setRootTabLink(location.pathname))
			}

			// Detect change nav item
			if (location.state && location.state.nav && location.pathname === location.state.nav) {
				dispatch(selectTab(-1))
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selected])

	/**
	 * Handle click change tab
	 */
	const handleTabChange = (event, index) => {
		let state = { ...bowserHistory.location.state, ...{ tabButton: index } }

		bowserHistory.replace({ ...bowserHistory.location, state })

		dispatch(selectTab(index))
	}

	/**
	 * Handle double click edit
	 */
	const handleEditTab = event => {
		dispatch(startEditTab())

		setTimeout(() => {
			event.target.nextElementSibling.focus()
		}, 100)
	}

	/**
	 * Update tab title
	 */
	const handleBlurUpdateTabTitle = event => {
		const item = items[selected]
		const newItem = { ...item }

		newItem.title = event.target.value

		const payload = { item: newItem, selected }
		dispatch(updateTab(payload))
	}

	/**
	 * Handle
	 */
	const handleKeyPressUpdateTabTitle = event => {
		if (event.key === 'Enter') {
			event.target.blur()
		}
	}

	// Return JSX code
	return (
		<Box className={clsx(classes.box)}>
			<Tabs
				classes={{
					root: classes.tabsRoot
				}}
				value={selected}
				onChange={handleTabChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				aria-label="scrollable auto tabs"
			>
				<Tab
					value={-1}
					style={{
						minWidth: 'auto',
						padding: '0 25px',
						color: 'darkslategray'
					}}
					classes={{
						wrapper: classes.iconLabelWrapper,
						root: classes.tabsRoot
					}}
					icon={<HomeIcon />}
				/>
				{items.map((item, index) => (
					<Tab
						value={index}
						classes={{
							wrapper: classes.iconLabelWrapper,
							root: classes.tabsRoot
						}}
						style={{ opacity: item.deleting ? 0.2 : 1 }}
						key={item.id}
						icon={
							item.id !== 'Root' && (
								<>
									<CloseIcon
										classes={{
											root: classes.iconContainer
										}}
										style={{ fontSize: 15 }}
										id={item.id}
										onClick={event => {
											if (!isDeleting) {
												event.stopPropagation()
												setIsDeleting(true)
												dispatch(deleteTab({ index, id: item.id })).then(() => {
													setIsDeleting(false)
												})
											}
										}}
									/>
								</>
							)
						}
						label={
							<>
								<span
									className={clsx(classes.tabName)}
									style={{ display: item.edit === true ? 'none' : 'block' }}
									onDoubleClick={event => handleEditTab(event, index)}
								>
									{item.title}
								</span>
								<input
									onBlur={handleBlurUpdateTabTitle}
									onKeyPress={handleKeyPressUpdateTabTitle}
									style={{
										textAlign: 'center',
										fontSize: '1.4rem',
										color: 'black',
										display: item.edit === true ? 'block' : 'none'
									}}
									defaultValue={item.title}
								/>
							</>
						}
					/>
				))}
			</Tabs>
		</Box>
	)
}

export default withReducer(withRouter('tabs'), reducer)(React.memo(TabPanel))
