//
// FormAppConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'

const ReportAppConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/reports/generic/report/:reportID',
			component: React.lazy(() => import('./ReportApp'))
		},
		{
			path: '/reports/project-specific/provider/:provider/report/:reportID',
			component: React.lazy(() => import('./ReportApp.js'))
		}
	]
}

export default ReportAppConfig
