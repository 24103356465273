import { combineReducers } from '@reduxjs/toolkit'
import login from './loginSlice'
import user from './userSlice'
import access from './accessSlice'

const authReducers = combineReducers({
	user,
	login,
	access
})

export default authReducers
