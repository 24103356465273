import globalConfig from 'app/fuse-configs/globalConfig'
import Auth from './Auth'
import Profile from './Profile'

const AuthConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				},
				...globalConfig
			}
		}
	},
	auth: [],
	routes: [
		{
			path: '/auth/:code',
			component: Auth
		},
		{
			path: '/auth/profile',
			component: Profile
		}
	]
}

export default AuthConfig
