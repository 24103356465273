//
// Prototypes.js
//
import FuseAnimate from '@fuse/core/FuseAnimate'
import FusePageSimple from '@fuse/core/FusePageSimple'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useDeepCompareEffect } from '../../../../@fuse/hooks'
import { hideTabs } from '../../../store/settingsSlice'
import { createPath } from '../../../store/breadcrumbSlice'
import { getPrototypes, selectPrototypes } from '../../forms/store/prototypes/prototypesSlice'

const useStyles = makeStyles({
	typeIcon: {
		'&.account_tree:before': {
			content: "'account_tree'",
			color: '#FFB300'
		}
	}
})

const Prototype = props => {
	const dispatch = useDispatch()
	const classes = useStyles()

	/**
	 * Use Selectors.
	 */
	const prototypes = useSelector(selectPrototypes)

	useDeepCompareEffect(() => {
		dispatch(hideTabs())

		if (!prototypes.length) {
			dispatch(getPrototypes())
		}
	}, [dispatch])

	/**
	 * Build Breadcrumb
	 */
	useEffect(() => {
		/**
		 * Building Breadcrumbs.
		 *
		 * @return array
		 */
		dispatch(
			createPath({
				id: 'root',
				title: 'Root',
				type: 'group',
				icon: 'star',
				children: [
					{
						id: 'settings',
						title: 'Settings',
						type: 'item',
						url: '/settings',
						children: [
							{
								id: 'prototypes',
								title: 'Prototypes',
								type: 'item',
								url: '/settings/prototypes'
							}
						]
					}
				]
			})
		)
	})

	return (
		<FusePageSimple
			classes={{
				root: 'bg-red',
				header: 'h-72 min-h-72 sm:h-96 sm:min-h-96',
				sidebarHeader: 'h-96 min-h-96 sm:h-160 sm:min-h-160',
				rightSidebar: 'w-320'
			}}
			content={
				<FuseAnimate animation="transition.slideUpIn" delay={300}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className="max-w-64 w-64 p-0 text-center"> </TableCell>
								<TableCell>Prototype</TableCell>
								<TableCell className="hidden sm:table-cell" />
							</TableRow>
						</TableHead>
						<TableBody>
							{prototypes.map((item, index) => {
								return (
									<TableRow
										key={index}
										hover
										className="cursor-pointer"
										onClick={() => {
											props.history.push(`/settings/prototypes/prototype/${item.prototype_id}`)
										}}
									>
										<TableCell className="max-w-64 w-64 p-0 text-center">
											<Icon className={clsx(classes.typeIcon, 'account_tree')} />
										</TableCell>
										<TableCell>{item.prototype_name}</TableCell>
										<TableCell />
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</FuseAnimate>
			}
		/>
	)
}

export default withRouter(Prototype)
