//
// FormModel.js
//
class FormModel {
	constructor(state, type) {
		this.form_id = state.form_id
		this.form_name = state.title || 'Untitled'
		this.provider_id = state.provider_id || null
		this.prototype_id = state.prototype_id
		this.department_id = state.department_id || null
		this.form_settings = JSON.stringify({
			fields: state.selectedFields || [],
			filters: state.filters || null,
			groups: state.fieldGroups || [],
			sortOrder: state.sortOrderField || [],
			allow_comments: state.allow_comments,
			allow_files: state.allow_files,
			allow_bulk: state.allow_bulk,
			allow_delete: state.allow_delete
		})
		this.generic_route = state.route || null
		if (type) {
			this.form_type = type === 'project-specific' ? 'specific' : type
		}
		this.relations = state.relations
		this.users = state.users
		this.groups = state.groups
		this.responsibles = state.responsibles
	}
}

export default FormModel
