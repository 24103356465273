import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Icon from '@material-ui/core/Icon'
import clsx from 'clsx'
import { DialogActions, DialogTitle, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { deletePersonalForm } from '../../store/forms/formSlice'
import { addTab } from 'app/store/tabsSlice'
import TableRow from '@material-ui/core/TableRow'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateFormInDepartment } from '../../store/departments/departmentSlice'
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice'
import Button from '@material-ui/core/Button'
import { addPersonalFormShortcut } from '../../store/forms/personalSlice'
import { showMessage } from 'app/store/fuse/messageSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { updateGenericForm } from '../../store/forms/genericSlice'

const useStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	},
	typeIcon: {
		'&.groups:before': {
			content: "'groups'",
			color: '#FFB300'
		},
		'&.document:before': {
			content: "'insert_drive_file'",
			color: '#1565C0'
		},
		'&.spreadsheet:before': {
			content: "'insert_chart'",
			color: '#4CAF50'
		}
	},
	item: {
		'&.toBeRemoved': {
			opacity: 0.2
		}
	}
})

const PersonalFormRow = props => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [anchorEl, setAnchorEl] = useState(null)
	const [disabled, setDisabled] = useState(false)

	const { parent, item, index, folder } = props

	const handleMenuClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<TableRow hover className={clsx(classes.item, { toBeRemoved: item.removing })}>
			<TableCell
				onClick={() => {
					props.history.push(`/forms/${folder}/form/custom/view/${item.personal_form_id}`, { tab: -1 })
				}}
				className="max-w-64 w-64 p-0 text-center"
			>
				<Icon className={clsx(classes.typeIcon, 'spreadsheet')} />
			</TableCell>
			<TableCell
				className="whitespace-nowrap"
				onClick={() => {
					props.history.push(`/forms/${folder}/form/custom/view/${item.personal_form_id}`, { tab: -1 })
				}}
			>
				{item.personal_form_name}
			</TableCell>
			<TableCell
				onClick={() => {
					props.history.push(`/forms/${folder}/form/custom/view/${item.personal_form_id}`, { tab: -1 })
				}}
			>
				{item.created_at_formatted}
			</TableCell>
			<TableCell
				onClick={() => {
					props.history.push(`/forms/${folder}/form/custom/view/${item.personal_form_id}`, { tab: -1 })
				}}
			>
				{item.creators_user_name}
			</TableCell>

			<TableCell>
				<IconButton
					disabled={disabled}
					size="small"
					variant="outlined"
					onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()

						// Make a shallow copy of the array
						let temp_data = [...parent.personalized]

						// Make a shallow copy of the element you want to mutate
						let temp_element = { ...temp_data[index] }

						setDisabled(true)

						dispatch(addPersonalFormShortcut({ id: item.personal_form_id })).then(response => {
							setDisabled(false)
							if (response.payload.shortcut) {
								// Update the property you're interested in
								temp_element.shortcut = response.payload.shortcut

								// Put it back into our array. N.B. we *are* mutating the array here,
								// but that's why we made a copy first
								temp_data[index] = temp_element

								dispatch(
									updateFormInDepartment({
										id: parent.form_id,
										changes: { personalized: temp_data }
									})
								)

								dispatch(
									updateGenericForm({
										id: parent.form_id,
										changes: { personalized: temp_data }
									})
								)

								dispatch(
									showMessage({
										message: `Custom Form has successfully added to your dashboard`,
										variant: 'success',
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right'
										}
									})
								)
							} else {
								// Update the property you're interested in
								temp_element.shortcut = null
								// Put it back into our array. N.B. we *are* mutating the array here,
								// but that's why we made a copy first
								temp_data[index] = temp_element

								dispatch(
									updateFormInDepartment({
										id: parent.form_id,
										changes: { personalized: temp_data }
									})
								)

								dispatch(
									updateGenericForm({
										id: parent.form_id,
										changes: { personalized: temp_data }
									})
								)

								dispatch(
									showMessage({
										message: `Custom Form has successfully been removed to your dashboard`,
										variant: 'success',
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right'
										}
									})
								)
							}
						})
					}}
				>
					{item.shortcut ? <Icon>star</Icon> : <Icon>star_outline</Icon>}
				</IconButton>
			</TableCell>

			<TableCell className="p-0" align="right">
				<IconButton
					aria-owns={anchorEl ? 'actions-menu' : null}
					aria-haspopup="true"
					onClick={handleMenuClick}
					variant="outlined"
					size="small"
				>
					<Icon className="text-20">more_vert</Icon>
				</IconButton>
				<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
					<MenuItem
						onClick={ev => {
							ev.preventDefault()
							setAnchorEl(null)
							props.history.push(`/forms/${folder}/form/custom/edit/${item.personal_form_id}`)
						}}
					>
						<ListItemIcon className="min-w-40">
							<Icon>edit</Icon>
						</ListItemIcon>
						<ListItemText primary="Edit" />
					</MenuItem>
					<MenuItem
						onClick={() => {
							setAnchorEl(null)
							dispatch(
								openDialog({
									children: (
										<>
											<DialogTitle>Are you sure you want to delete this form?</DialogTitle>
											<DialogActions>
												<Button
													onClick={() => {
														setAnchorEl(null)
														dispatch(closeDialog())
													}}
													color="primary"
												>
													No
												</Button>
												<Button
													onClick={() => {
														dispatch(closeDialog())
														// make a shallow copy of the array
														let temp_data = [...parent.personalized]
														temp_data[index] = { ...temp_data[index], removing: true }
														dispatch(
															updateFormInDepartment({
																id: parent.form_id,
																changes: { personalized: temp_data }
															})
														)
														dispatch(
															updateGenericForm({
																id: parent.form_id,
																changes: { personalized: temp_data }
															})
														)
														dispatch(
															deletePersonalForm({
																id: item.personal_form_id
															})
														)
															.then(unwrapResult)
															.then(() => {
																// remove item from state
																let temp_data = [...parent.personalized]
																temp_data.splice(index, 1)
																dispatch(
																	updateFormInDepartment({
																		id: parent.form_id,
																		changes: { personalized: temp_data }
																	})
																)
																dispatch(
																	updateGenericForm({
																		id: parent.form_id,
																		changes: { personalized: temp_data }
																	})
																)
																dispatch(
																	showMessage({
																		message: 'Personal form successfully deleted',
																		variant: 'success'
																	})
																)
															})
															.catch(actualError => {
																dispatch(
																	showMessage({
																		message: actualError.message,
																		variant: 'error'
																	})
																)
															})
													}}
													color="primary"
													autoFocus
												>
													Yes
												</Button>
											</DialogActions>
										</>
									)
								})
							)
						}}
					>
						<ListItemIcon className="min-w-40">
							<Icon>delete</Icon>
						</ListItemIcon>
						<ListItemText primary="Remove" />
					</MenuItem>
				</Menu>
				<IconButton
					title="Open in New Tab"
					onClick={() => {
						dispatch(
							addTab({
								name: item.personal_form_name,
								link: `/forms/${folder}/form/custom/view/${item.personal_form_id}`
							})
						)
					}}
				>
					<Icon>tab</Icon>
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default withRouter(PersonalFormRow)
