import React from 'react'
import globalConfig from 'app/fuse-configs/globalConfig'

// AdministrationConfig,
// SourceConfig

const DataConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/data/administration/source/:source?',
			component: React.lazy(() => import('./administration/source/Source'))
		},
		{
			path: '/data/administration',
			component: React.lazy(() => import('./administration/Administration'))
		}
	]
}

export default DataConfig
