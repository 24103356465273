import { combineReducers } from '@reduxjs/toolkit'
import auth from 'app/auth/store'
import fuse from './fuse'
import i18n from './i18nSlice'
import tabs from './tabsSlice'
import breadcrumbs from './breadcrumbSlice'
import settings from './settingsSlice'

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		fuse,
		i18n,
		tabs,
		breadcrumbs,
		settings,
		...asyncReducers
	})

export default createReducer
