import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'
import { Redirect } from 'react-router'

const ProvidersConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/forms/project-specific',
			component: React.lazy(() => import('./ProvidersApp'))
		},
		{
			path: '/forms',
			exact: true,
			component: () => <Redirect to="/forms/project-specific" />
		}
	]
}

export default ProvidersConfig
