import { compare } from 'app/@artemis/helper'

export const humanize = str => {
	if (typeof str !== 'string') return str
	return (
		// eslint-disable-next-line no-undef
		str
			.replace(/^[\s_]+|[\s_]+$/g, '')
			.replace(/[_\s]+/g, ' ')
			.replace(/-/g, ' ')
			// eslint-disable-next-line func-names
			.replace(/^[a-z]/, function (m) {
				return m.toUpperCase()
			})
	)
}

export const humanizeField = str => {
	return humanize(str.replace('_id', ''))
}

export const getPathTree = (departmentTree, url) => {
	const findPath = (node, _url) => {
		// If current node matches search node, return tail of path result
		if (node.url === _url) {
			return []
		}
		// If current node not search node match, examine children. For first
		// child that returns an array (path), prepend current node to that
		// path result
		if (node.children) {
			// eslint-disable-next-line no-restricted-syntax
			for (const child of node.children) {
				const childPath = findPath(child, _url)
				if (Array.isArray(childPath)) {
					childPath.unshift(child)
					return childPath
				}
			}
		}
		return false
	}
	const response = findPath(departmentTree, url)

	return response || []
}

export const preg_match_all = (regex, str) => {
	return [...str.matchAll(new RegExp(regex, 'g'))].reduce((acc, group) => {
		group
			.filter(element => typeof element === 'string')
			.forEach((element, i) => {
				if (!acc[i]) acc[i] = []
				acc[i].push(element)
			})

		return acc
	}, [])
}

/**
 * Apply change field color
 */
export const applyCondition = (event, dataCondition, dataColor) => {
	if (dataCondition === 'and') {
		return
	}

	if (dataCondition[0].includes('.compare')) {
		const dataColumn = dataCondition[0].replace('.compare', '')

		let column = event.data[dataColumn]
		let operator = dataCondition[1]
		let value = event.data[dataCondition[2]]

		// for Lookup values
		/**
		 * column = event.data[column_name]
		 */
		if (event.data[dataCondition[0].replace('_id', '') + '_displayname']) {
			column = event.data[dataColumn.replace('_id', '') + '_displayname']
		}

		/**
		 * value = event.data[column_name]
		 */
		if (event.data[dataCondition[2].replace('_id', '') + '_displayname']) {
			value = event.data[dataCondition[2]]
		}

		/**
		 * column >= value
		 */
		if (compare(column, operator, value)) {
			// condition where the column requires the coloring
			if (dataColor) {
				// set the background color based on the data
				event.cellElement.style.backgroundColor = dataColor
				event.cellElement.style.color = '#DDD'
			} else {
				event.cellElement.style.backgroundColor = '#ededed'
			}
		}
	} else {
		let dataColumn = event.data[dataCondition[0]]
		let operator = dataCondition[1]
		let value = dataCondition[2]

		// lookup values
		if (event.data[dataCondition[0].replace('_id', '') + '_displayname']) {
			dataColumn = event.data[dataCondition[0].replace('_id', '') + '_displayname']
		}

		if (event.data[dataCondition[2] + '_displayname']) {
			value = event.data[dataCondition[2] + '_displayname']
		}

		if (dataColumn && compare(dataColumn.toString(), operator, value)) {
			// condition where the column requires the coloring
			if (dataColor) {
				// set the background color based on the data
				event.cellElement.style.backgroundColor = dataColor
				event.cellElement.style.color = '#DDD'
			} else {
				event.cellElement.style.backgroundColor = '#ededed'
			}
		}
	}
}
