//
// personalSlice.js
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import PersonalFormModel from '../../form/model/PersonalFormModel'
import { reloadDepartment } from '../departments/departmentSlice'
import { reloadPrototype } from '../prototypes/prototypeSlice'
import { reloadProvider } from '../providers/providerSlice'
import { clear } from './genericSlice'

/**
 * Insert
 * @async Thunk
 */
export const getPersonalForm = createAsyncThunk(
	// action type string
	'formsApp/form/getPersonalForm',
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/personal`, params)
			return response.data
		} catch (err) {
			// Use `err.response.data` as `action.payload` for a `rejected` action,
			// by explicitly returning it using the `rejectWithValue()` utility
			return rejectWithValue(err.response)
		}
	}
)

/**
 * Insert
 * @async Thunk
 */
export const createPersonalForm = createAsyncThunk(
	// action type string
	'formsApp/form/createPersonalForm',
	// callback function
	async (params, { dispatch, getState }) => {
		const state = getState() // <-- Invoke and access state object

		const userData = state.auth.user
		const parentData = state.formsApp.form
		const formData = state.formsApp.personal

		// ==== Post insert data to API =====
		const response = await axios.post(
			`${process.env.REACT_APP_API_PULSE_URL}/Form/personal/add`,
			new PersonalFormModel(parentData, formData, userData)
		)

		dispatch(reloadPrototype())
		dispatch(reloadProvider())
		dispatch(reloadDepartment())
		dispatch(clear())

		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Update
 * @async Thunk
 */
export const updatePersonalForm = createAsyncThunk(
	// action type string
	'formsApp/form/updatePersonalForm',
	// callback function
	async (params, { dispatch, getState }) => {
		const state = getState() // <-- invoke and access state object

		// ===== Create the record ======
		const record = {
			personal_form_id: state.formsApp.personal.id,
			personal_form_name: state.formsApp.personal.title,
			personal_form_settings: JSON.stringify({
				fields: state.formsApp.form.selectedFields,
				filters: state.formsApp.form.calculatedExtraFilters,
				editFilters: state.formsApp.form.extraFilters,
				groups: state.formsApp.form.fieldGroups,
				sortOrder: state.formsApp.form.sortOrderField,
				sortOrderValue: state.formsApp.form.sortOrderValue
			})
		}

		// ==== Post update data to API ===
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/personal/update`, record)

		dispatch(reloadPrototype())
		dispatch(reloadDepartment())
		dispatch(clear())

		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Add Shortcut
 * @async Thunk
 */
export const addPersonalFormShortcut = createAsyncThunk(
	'formsApp/personalForm/addShortcut',
	async (params, { dispatch, getState }) => {
		// eslint-disable-next-line camelcase
		const { id } = params
		// post-form-to-delete
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/personal/add/shortcut`, { id })
		return await response.data
	}
)

const initialState = {
	id: null,
	title: 'Untitled'
}

const personalSlice = createSlice({
	name: 'formsApp/personalForm',
	initialState: initialState,
	reducers: {
		newPersonalForm: {
			reducer: (state, action) => action.payload,
			prepare: event => ({ payload: initialState })
		},
		setPersonalFormTitle: (state, action) => {
			state.title = action.payload
		},
		addPersonalFormFilter: (state, action) => {
			const { filter } = action.payload
			state.filters = filter
		}
	},
	extraReducers: {
		[getPersonalForm.pending]: (state, action) => {
			if (action.meta.arg.formID !== state.id) {
				state.id = null
				state.title = null
			}
		},
		[getPersonalForm.fulfilled]: (state, action) => {
			const { personalForm } = action.payload
			const { personal_form_id, personal_form_name } = personalForm
			return {
				...state,
				...action.payload,
				id: personal_form_id,
				title: personal_form_name
			}
		}
		// [addPersonalFormShortcut.fulfilled]: (state, action) => {},
		// [updatePersonalForm.fulfilled]: (state, action) => {}
	}
})

export const { newPersonalForm, setPersonalFormTitle, addPersonalFormFilter } = personalSlice.actions

export default personalSlice.reducer
