import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
// import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from 'react-redux'
// import { ListItemSecondaryAction } from '@material-ui/core'
// import IconButton from '@material-ui/core/IconButton'

function AboutTab() {
	const user = useSelector(({ auth }) => auth.user)

	return (
		<div className="md:flex max-w-2xl">
			<div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
				<FuseAnimateGroup
					enter={{
						animation: 'transition.slideUpBigIn'
					}}
				>
					<Card className="w-full mb-16 rounded-8 shadow">
						<AppBar position="static" elevation={0}>
							<Toolbar className="px-8">
								<Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
									Information
								</Typography>
							</Toolbar>
						</AppBar>

						<CardContent>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Username/Login</Typography>
								<Typography>{user.data.userName}</Typography>
							</div>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">First Name</Typography>
								<Typography>{user.data.firstName}</Typography>
							</div>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Last Name</Typography>
								<Typography>{user.data.lastName}</Typography>
							</div>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Father's Name</Typography>
								<Typography>{user.data.fathersName}</Typography>
							</div>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Gender</Typography>
								<Typography>Male</Typography>
							</div>

							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Birthday</Typography>
								<Typography>{user.data.birthDate}</Typography>
							</div>
						</CardContent>
					</Card>

					<Card className="w-full mb-16 rounded-8 shadow">
						<AppBar position="static" elevation={0}>
							<Toolbar className="px-8">
								<Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
									Employee
								</Typography>
							</Toolbar>
						</AppBar>

						<CardContent>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Start Date</Typography>
								<Typography>{user.data.startDate}</Typography>
							</div>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Job Title</Typography>
								<Typography>{user.data.jobTitle}</Typography>
							</div>
							<div className="mb-24">
								<Typography className="font-bold mb-4 text-15">Reports to</Typography>
								<Typography>{user.data.report_to_full_name}</Typography>
							</div>
						</CardContent>
					</Card>
				</FuseAnimateGroup>
			</div>

			<div className="flex flex-col md:w-320">
				<div className="flex flex-col md:w-320">
					<FuseAnimateGroup
						enter={{
							animation: 'transition.slideUpBigIn'
						}}
					/>
				</div>

				<Card className="w-full mb-16 rounded-8 shadow">
					<AppBar position="static" elevation={0}>
						<Toolbar className="px-8">
							<Typography variant="subtitle1" color="inherit" className="flex-1 px-12">
								My Groups
							</Typography>
						</Toolbar>
					</AppBar>
					<CardContent className="p-0">
						<List className="p-0">
							{user.data.groups.map(group => (
								<ListItem key={group.user_group_id} className="px-8">
									<Avatar className="mx-8" alt={group.user_group_name}>
										{group.user_group_name[0]}
									</Avatar>
									<ListItemText
										primary={
											<div className="flex">
												<Typography className="font-medium" color="secondary" paragraph={false}>
													{group.user_group_name}
												</Typography>
											</div>
										}
									/>
								</ListItem>
							))}
						</List>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default AboutTab
