//
// App.js
//
import MomentUtils from '@date-io/moment'
import FuseAuthorization from '@fuse/core/FuseAuthorization'
import FuseLayout from '@fuse/core/FuseLayout'
import FuseTheme from '@fuse/core/FuseTheme'
import history from '@history'
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import { create } from 'jss'
import jssExtend from 'jss-plugin-extend'
import rtl from 'jss-rtl'
import React from 'react'
import Provider from 'react-redux/es/components/Provider'
import { Router } from 'react-router-dom'
import AppContext from './AppContext'
import { Auth } from './auth'
import routes from './fuse-configs/routesConfig'
import { SocketContext, socketGet } from './SocketContext'
import store from './store'

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
})

const generateClassName = createGenerateClassName()

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_SENTRY_ENV,
	release: process.env.REACT_APP_SENTRY_RELEASE,
	autoSessionTracking: false
})

const App = () => {
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<SocketContext.Provider value={socketGet()}>
					<Provider store={store}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<Auth>
								<Router history={history}>
									<FuseAuthorization>
										<FuseTheme>
											<FuseLayout />
										</FuseTheme>
									</FuseAuthorization>
								</Router>
							</Auth>
						</MuiPickersUtilsProvider>
					</Provider>
				</SocketContext.Provider>
			</StylesProvider>
		</AppContext.Provider>
	)
}

export default App
