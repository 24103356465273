import Typography from '@material-ui/core/Typography'
import React from 'react'

function Maintenance() {
	return (
		<div id="fuse-splash-screen">
			<div className="center">
				<div className="logo">
					<img width="128" src="assets/images/logos/fuse.svg" alt="logo" />
				</div>
				<Typography align="center" style={{ color: '#fff' }} variant="h3" className="mb-16">
					Upgrading, Please Wait..
				</Typography>
				<div className="spinner-wrapper">
					<div className="spinner">
						<div className="inner">
							<div className="gap" />
							<div className="left">
								<div className="half-circle" />
							</div>
							<div className="right">
								<div className="half-circle" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(Maintenance)
