import FuseNavigation from '@fuse/core/FuseNavigation'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectNavigationData } from 'app/store/fuse/navigationSlice'
import ToggleNavItem from '../../@artemis/Navigation/ToggleNavItem'
import FuseNavVerticalItem from '../../@artemis/Navigation/FuseNavVerticalItem'
import { registerComponent } from '@fuse/core/FuseNavigation/FuseNavItem'

/*
 * Register Fuse Navigation Components
 */
registerComponent('vertical-toggle', ToggleNavItem)
registerComponent('vertical-item', FuseNavVerticalItem)

const Navigation = props => {
	const navigation = useSelector(selectNavigationData)

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={navigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
		/>
	)
}

Navigation.defaultProps = {
	layout: 'vertical'
}

export default React.memo(Navigation)
