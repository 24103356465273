import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getPrototypesByProvider = createAsyncThunk(
	'formsApp/provider/getPrototypesByProvider',
	async (params, { dispatch, getState }) => {
		dispatch(loading(params))
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Provider/prototypes`, params)
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Fetch Departments By Provider Key from API.
 */
export const getDepartmentsByProvider = createAsyncThunk(
	'formsApp/provider/departments/getDepartmentsByProvider',
	async (params, { dispatch, getState }) => {
		dispatch(loading(params))
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Provider/departments`, params)
		// eslint-disable-next-line no-return-await
		return await response.data.data
	}
)

/**
 * Fetch Forms By Provider Key From API.
 */
export const getUncategorizedFormsByProvider = createAsyncThunk(
	'formsApp/provider/forms/getUncategorizedFormsByProvider',
	async (params, { dispatch, getState }) => {
		dispatch(loading(params))

		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Provider/forms/uncategorized`, params)

		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Fetch Forms By Provider Key From API.
 */
export const getFormsByProvider = createAsyncThunk(
	'formsApp/provider/forms/getFormsByProvider',
	async (params, { dispatch, getState }) => {
		dispatch(loading(params))
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Provider/forms`, params)
		// eslint-disable-next-line no-return-await
		return await response.data.data
	}
)

/**
 * Prototypes Adapter
 */
const providerAdapter = createEntityAdapter({
	// ..assume IDs are stored in a field other than `provider.provider_id`
	selectId: prototype => prototype.prototype_id
})

export const {
	selectAll: selectPrototypesByProvider,
	selectEntities: selectPrototypesEntities,
	selectById: selectPrototypeById
} = providerAdapter.getSelectors(state => state.formsApp.provider)

/**
 * Departments Adapter.
 */
const providerDepartmentAdapter = createEntityAdapter({
	// ..assume IDs are stored in a field other than `provider.provider_id`
	selectId: department => department.department_id
})

export const {
	selectAll: selectDepartmentsByProvider,
	selectEntities: selectDepartmentsEntities,
	selectById: selectDepartmentById
} = providerDepartmentAdapter.getSelectors(state => state.formsApp.provider)

/**
 * Forms Adapter.
 */
const providerFormsAdapter = createEntityAdapter({
	// .assume IDs are stored in a field other than `form.form_id`
	selectId: form => form.form_id
})

export const {
	selectAll: selectFormsByProvider,
	selectEntities: selectFormsByProviderEntities,
	selectById: selectFormsByProviderById
} = providerFormsAdapter.getSelectors(state => state.formsApp.provider)

/**
 * Slice Configuration.
 */
const providerSlice = createSlice({
	name: 'formsApp/provider',
	initialState: providerAdapter.getInitialState({
		loading: true,
		id: null
	}),
	reducers: {
		loading: (state, action) => {
			state.loading = true
			state.id = action.payload.provider
		},
		reloadProvider: (state, action) => {
			providerAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getPrototypesByProvider.fulfilled]: (state, action) => {
			providerAdapter.setAll(state, action.payload)
			state.loading = false
		},
		[getDepartmentsByProvider.fulfilled]: (state, action) => {
			providerDepartmentAdapter.setAll(state, action.payload)
			state.loading = false
		},
		[getFormsByProvider.fulfilled]: (state, action) => {
			providerFormsAdapter.setAll(state, action.payload)
		},
		[getUncategorizedFormsByProvider.fulfilled]: (state, action) => {
			providerFormsAdapter.addMany(state, action.payload)
			state.loading = false
		}
	}
})

export const { loading, reloadProvider } = providerSlice.actions

export default providerSlice.reducer
