import React from 'react'
import { Redirect } from 'react-router-dom'
import FuseUtils from '@fuse/utils'
import DashboardConfig from 'app/main/dashboard/DashboardConfig'
import AuthConfig from 'app/main/auth/AuthConfig'
import FormConfigs from 'app/main/forms/FormConfigs'
import DataConfigs from 'app/main/data/DataConfig'
import Error404PageConfig from 'app/main/404/Error404PageConfig'
import Error403PageConfig from 'app/main/403/Error403PageConfig'
import UserConfigs from 'app/main/user/UserConfigs'
import SettingsConfig from 'app/main/settings/SettingsConfig'
import CommentsConfig from '../main/forms/form/comments/CommentsConfig'
import ReportConfigs from '../main/reports/ReportAppConfigs.js'

const routeConfigs = [
	DashboardConfig,
	CommentsConfig,
	AuthConfig,
	Error404PageConfig,
	Error403PageConfig,
	...FormConfigs,
	DataConfigs,
	...UserConfigs,
	SettingsConfig,
	...ReportConfigs
]

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/dashboard" />
	},
	{
		component: () => <Redirect to="/error-404" />
	}
]

export default routes
