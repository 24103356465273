//
// ...pulseApiService.js
//
import FuseUtils from '@fuse/utils'
import axios from 'axios'
import config from 'devextreme/core/config'
// ...devextreme
import CustomStore from 'devextreme/data/custom_store'
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider'
import { socketConnect } from '../../SocketContext'

config({
	forceIsoDateParsing: false
	// ...
})

// Save the cancel token for the current request

class PulseApiService extends FuseUtils.EventEmitter {
	apiUrl = process.env.REACT_APP_API_PULSE_URL
	dataLoaded = false
	totalCount = 0
	response = null
	cancelTokenSource = null

	dataLoadedCallback = () => {
		return this.dataLoaded
	}

	totalCountValue = () => {
		return this.totalCount
	}

	dataLoadedRollback = () => {
		this.dataLoaded = false
	}

	signInWithToken = accessToken => {
		this.setSession(accessToken)
		socketConnect(accessToken)

		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/auth/authenticate`)
				.then(response => {
					resolve()
				})
				.catch(error => {
					reject(new Error('Failed to login'))
				})
		})
	}

	// ======= get menu permissions ========
	setPermissions = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/Menu/access`)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					// this.logout(error)
					reject(new Error('Failed to login'))
				})
		})
	}

	// ======== get simple data ========
	getData = async url => {
		const res = await axios(`${this.apiUrl}${url}`)
		// eslint-disable-next-line no-return-await
		return await res.data.data
	}

	// eslint-disable-next-line camelcase
	setSession = access_token => {
		// eslint-disable-next-line camelcase
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token)
			// eslint-disable-next-line camelcase
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
		} else {
			localStorage.removeItem('jwt_access_token')
			delete axios.defaults.headers.common.Authorization
		}
	}

	getSession = () => {
		return localStorage.getItem('jwt_access_token')
	}

	navGroups = async navItem => {
		const res = await axios.get(`${this.apiUrl}/Menu/groups/`, { params: { path: navItem } })
		// eslint-disable-next-line no-return-await
		return await res.data.groups
	}

	columns = async model => {
		const res = await axios(`${this.apiUrl}/${model}/all`)
		// eslint-disable-next-line no-return-await
		return await res.data
	}

	// ===== or inside the DataSource =====
	createDataCustomStore = (keyField, model, url = '', prototype_id = null) => {
		const api = this

		return new CustomStore({
			dateSerializationFormat: 'yyyy-MM-dd',
			key: keyField,
			onBeforeSend: function (operation, ajaxSettings) {
				if (ajaxSettings.data.sort) {
					let sort = JSON.parse(ajaxSettings.data.sort)
					sort.push({
						selector: keyField,
						desc: false
					})
					ajaxSettings.data.sort = JSON.stringify(sort)
				}
			},
			cacheRawData: true,
			paginate: false,
			load: loadOptions => {
				return api.loadData(model, loadOptions, 'all', url)
			},
			insert: values => {
				return api.insertData(model, values, url, prototype_id)
			},
			update: (key, values) => {
				// eslint-disable-next-line camelcase
				if (prototype_id) {
					// eslint-disable-next-line camelcase
					values.prototype = prototype_id
				}
				return api.updateData(model, key, values, url, prototype_id)
			},
			remove: key => {
				return api.deleteData(model, key, prototype_id)
			}
			// byKey: key => {
			// 	// Retrieving a data object by key
			// 	return new Promise((resolve, reject) => {
			// 		axios
			// 			.get(`${this.apiUrl}/${model}/bykey/${key}`)
			// 			.then(response => response.data)
			// 			.then(response => {
			// 				resolve(response.data)
			// 			})
			// 	})
			// }
		})
	}

	// ===== or inside the DataSource =====
	createRowCustomStore = (keyField, model, url = '') => {
		const endpoint = !url ? `${model}/all` : url
		return new CustomStore({
			key: keyField,
			loadMode: 'raw',
			cacheRawData: true,
			load: () => {
				return new Promise((resolve, reject) => {
					axios
						.get(`${this.apiUrl}/${endpoint}`)
						.then(response => response.data)
						.then(data => {
							resolve(data.data)
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		})
	}

	createRequestDataCustomStore = (keyField, model) => {
		const api = this

		return new CustomStore({
			load: () => {
				return api.loadData(model, { id: keyField }, 'byKey')
			}
		})
	}

	// ==== parse query string =======
	parseQueryString = paramStr => {
		let paramArr = paramStr.split(/&/)
		let paramObj = []

		paramArr.forEach((e, index) => {
			let param = e.split(/[.\-=<>/]/)

			// find operator
			let arrNonWord = []
			let arrStr = e.split('')
			arrStr.forEach(function (str) {
				const result = str.match(/\W+/g)
				if (result) arrNonWord.push(result[0])
			})

			// if (param[1].includes('[')) {
			// 	const values = param[1].replace(/\[/g, ' ').replace(/\]/g, '')
			// 	values.split(',').map((value, index) => {
			// 		paramObj.push([[param[0], '=', decodeURIComponent(Number(value))]])
			// 		if (index + 1 !== values.split(',').length) {
			// 			paramObj.push('or')
			// 		}
			// 	})
			// } else {
			paramObj.push([param[0], arrNonWord.join(''), decodeURIComponent(param[1])])
			// }
		})
		return paramObj
	}

	// ======= custom load ==========
	lookupData = (dataField, key, callback) => {
		axios
			.get(`${this.apiUrl}/${dataField}/bykey/${key}`, {
				cancelToken: this.cancelTokenSource.token
			})
			.then(response => response.data.data)
			.then(response => {
				callback(response)
			})
	}

	// ===== or inside the infiniteList =====
	infiniteList = url => {
		return new CustomStore({
			paginate: true,
			pageSize: 10,
			load: loadOptions => {
				// Loading data objects
				return this.loadData(null, loadOptions, null, url)
			},
			byKey: key => {
				// Retrieving a data object by key
				// return new Promise((resolve, reject) => {
				// 	axios
				// 		.get(`${this.apiUrl}/${model}/bykey/${key}`)
				// 		.then(response => response.data)
				// 		.then(response => {
				// 			resolve(response.data)
				// 		})
				// })
			}
		})
	}

	// ===== or inside the lookupDataSource =====
	lookupDataSource = (keyField, model, method = 'all', query) => {
		// Allowed separators
		const separators = ['\\&&', '\\|\\|']

		return {
			paginate: true,
			pageSize: 5,
			key: keyField,
			cacheRawData: true,
			// loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
			load: loadOptions => {
				// Loading data objects
				let params = '?'

				//
				const isNotEmpty = value => {
					return value !== undefined && value !== null && value !== ''
				}
				;[
					'filter',
					'group',
					'groupSummary',
					'parentIds',
					'requireGroupCount',
					'requireTotalCount',
					'searchExpr',
					'searchOperation',
					'searchValue',
					'select',
					'sort',
					'skip',
					'take',
					'totalSummary',
					'userData'
				].forEach(i => {
					if (i in loadOptions && isNotEmpty(loadOptions[i])) {
						params += `${i}=${encodeURIComponent(JSON.stringify(loadOptions[i]))}&`
					}
				})
				//

				if (query) {
					// split to operator (&&,||)
					const tokens = query.split(new RegExp('(' + separators.join('|') + ')', 'g'))

					// build the query string
					if (tokens.length >= 3) {
						let format = []

						for (let i in tokens) {
							let operator = ''

							if (i % 2 !== 0) {
								// operator
								switch (tokens[i]) {
									case '&&':
										operator = 'AND'
										break
									case '||':
										operator = 'OR'
										break
									case 'AND':
										operator = 'AND'
										break
									case 'OR':
										operator = 'OR'
										break
									default:
										break
								}

								format.push(operator)
							} else {
								// condition
								let isArray = false

								tokens[i].split(/&/).forEach(e => {
									let param = e.split(/[.\-=<>/]/)

									if (param[1].includes('[')) {
										isArray = true

										const values = param[1].replace(/\[/g, ' ').replace(/\]/g, '')

										values.split(',').map((value, index) => {
											format.push(
												this.parseQueryString(
													param[0] + '=' + decodeURIComponent(Number(value))
												)
											)

											if (index + 1 !== values.split(',').length) {
												format.push('OR')
											}

											return null
										})
									}
								})

								if (!isArray) {
									format.push(this.parseQueryString(tokens[i]))
								}
							}
						}
						params += `query=${JSON.stringify(format)}&`
					} else {
						let isArray = false
						let obj = []

						query.split(/&/).forEach(e => {
							let param = e.split(/[.\-=<>/]/)

							if (param[1].includes('[')) {
								isArray = true
								const values = param[1].replace(/\[/g, ' ').replace(/\]/g, '')
								values.split(',').map((value, index) => {
									obj.push(this.parseQueryString(param[0] + '=' + decodeURIComponent(Number(value))))
									if (index + 1 !== values.split(',').length) {
										obj.push('OR')
									}
									return null
								})
							}
						})

						if (!isArray) {
							obj = this.parseQueryString(query)
						}
						params += `query=${JSON.stringify(obj)}&`
					}
				}

				params = params.slice(0, -1)

				return new Promise((resolve, reject) => {
					axios
						.get(`${this.apiUrl}/${model}/${method}${params}`, {
							cancelToken: this.cancelTokenSource.token
						})
						.then(response => response.data)
						.then(response => {
							resolve(response.data)
						})
						.catch(error => {
							reject(error)
						})
				})
			},
			byKey: key => {
				// Retrieving a data object by key
				return new Promise((resolve, reject) => {
					axios
						.get(`${this.apiUrl}/${model}/bykey/${key}`)
						.then(response => response.data)
						.then(response => {
							resolve(response.data)
						})
				})
			}
		}
	}

	// ===== Loading data objects =====
	loadData = (model, options, method = 'all', url = '') => {
		this.dataLoaded = false

		const isNotEmpty = value => {
			return value !== undefined && value !== null && value !== ''
		}

		let params = '?'
		;[
			'filter',
			'group',
			'groupSummary',
			'parentIds',
			'requireGroupCount',
			'requireTotalCount',
			'searchExpr',
			'searchOperation',
			'searchValue',
			'select',
			'sort',
			'skip',
			'take',
			'totalSummary',
			'userData'
		].forEach(i => {
			if (i in options && isNotEmpty(options[i])) {
				params += `${i}=${encodeURIComponent(JSON.stringify(options[i]))}&`
			}
		})
		params = params.slice(0, -1)
		const api = !url ? `${this.apiUrl}/${model}/${method}${params}` : `${this.apiUrl}/${url}${params}`

		// creates a new token for upcoming ajax (overwrite the previous one)
		const cancelToken = axios.CancelToken

		// cancel previous request if exists
		this.cancelTokenSource && options.group === null && this.cancelTokenSource.cancel('Request canceled')

		// save the new request for cancellation
		this.cancelTokenSource = cancelToken.source()

		// Now you can use this axios instance like this
		return new Promise((resolve, reject) => {
			axios
				.get(
					api,
					options.group === null || options.requireTotalCount === true
						? {
								cancelToken: this.cancelTokenSource.token
						  }
						: {}
				)
				.then(response => response.data)
				.then(response => {
					this.dataLoaded = true
					this.totalCount = response.totalCount
					resolve({
						data: response.data,
						totalCount: response.totalCount,
						summary: response.summary,
						groupCount: response.groupCount
					})
				})
				.catch(error => {
					if (error.response) {
						reject(error.response.data.message)
					}
				})
		})
	}

	// ===== Tried this with and without async =====
	async cancel() {
		this.cancelTokenSource.cancel()
	}

	// ===== Loading prototype =====
	getInfo = (model, route) => {
		const api = !route ? `${this.apiUrl}/${model}/info` : `${this.apiUrl}/${route}/${model}/info`
		return new Promise((resolve, reject, route) => {
			axios
				.get(api)
				.then(response => response.data)
				.then(data => {
					resolve(data)
				})
				.catch(error => {
					reject(error.message)
				})
		})
	}

	// ===== Loading custom store =====
	getCustomStore = (url, data) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/${url}`, data)
				.then(response => response.data)
				.then(data => {
					resolve(data)
				})
				.catch(error => {
					reject(error.message)
				})
		})
	}

	// ===== insert data object =====
	insertData = (model, values, url = '') => {
		const api = !url ? `${this.apiUrl}/${model}/insert` : `${this.apiUrl}/${url}/insert`
		return new Promise((resolve, reject) => {
			axios
				.post(api, values)
				.then(response => response.data)
				.then(data => {
					this.response = data.data
					resolve(data.data)
				})
				.catch(error => {
					if (typeof error.response.data.message === 'object') {
						let response = ``
						Object.values(error.response.data.message).forEach(val => {
							response += `${val}
							`
						})
						reject(response)
					}
					reject(error.response.data.message)
				})
		})
	}

	// ===== import data object =====
	importData = (model, file) => {
		const data = new FormData()
		data.append('file', file)
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/${model}/import`, data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => response.data)
				.then(data => {
					resolve(data)
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== update data object =====
	updateData = (model, key, values, url = '') => {
		const api = !url ? `${this.apiUrl}/${model}/update/${key}` : `${this.apiUrl}/${url}/update/${key}`

		return new Promise((resolve, reject) => {
			axios
				.put(api, values)
				.then(response => response.data)
				.then(data => {
					this.response = data.data
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== delete row by key =====
	deleteData = (model, key, prototype) => {
		const url = prototype
			? `${this.apiUrl}/Prototypes/${prototype}/data/delete/${key}`
			: `${this.apiUrl}/${model}/delete/${key}`

		return new Promise((resolve, reject) => {
			axios
				.delete(url)
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== loading file system by key =====
	getFileSystem = (accessToken, rowguid) => {
		return new RemoteFileSystemProvider({
			endpointUrl: process.env.REACT_APP_API_PULSE_URL + '/Filemanager',
			beforeAjaxSend: function ({ headers, formData, xhrFields }) {
				headers.Authorization = `Bearer ${accessToken}`
				formData.rel = rowguid
			},
			beforeSubmit: function ({ formData }) {
				formData.authorization = `Bearer ${accessToken}`
				formData.rel = rowguid
			}
		})
	}

	// ======== state persistence =======
	sendStorageRequest = async (storageKey, method, data) => {
		const url = `${this.apiUrl}/Tabs/storage/${storageKey}`

		return new Promise((resolve, reject) => {
			axios
				.put(url, {
					options: data ? data : null
				})
				.then(response => response.data)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					if (error.response) {
						reject(error.response.data.message)
					}
					reject('Data Loading Error')
				})
		})
	}

	// ===== request to set column =====
	setTabStorage = (tab, data) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/Form/storage/${tab}/set`, { options: data })
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== request to hide column =====
	hideColumn = (model, source) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/${model}/column/hide`, { source })
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== request to cache order =====
	reorderColumn = (model, source, dest) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/${model}/column/reorder`, { source, dest })
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== request to cache filter =====
	saveFilter = (model, filter) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/${model}/filter`, filter, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== request to cache hide =====
	formHideColumn = (form_id, source) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/Form/column/hide/${form_id}`, { source })
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject()
				})
		})
	}

	formReorderColumn = (form_id, source, dest) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/Form/column/reorder/${form_id}`, { source, dest })
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject()
				})
		})
	}

	// =========== cache filter ==========
	formSaveFilter = (form_id, filter) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/Form/filter/${form_id}`, filter, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
				.then(response => response.data)
				.then(data => {
					resolve()
				})
				.catch(error => {
					reject()
				})
		})
	}

	// ===== async validation request =====
	sendRequest = (key, prototype, id) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${this.apiUrl}/Prototypes/${prototype}/indentify/${key}`, {
					id: id
				})
				.then(response => response.data)
				.then(() => {
					resolve()
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	// ===== async validation request =====
	bulkUpdateRequest = (row, ids, protoype) => {
		return new Promise((resolve, reject) => {
			axios
				.put(`${this.apiUrl}/Prototypes/${protoype}/data/bulkUpdate`, {
					row: row,
					ids: ids
				})
				.then(response => response.data)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					reject(error.response.data.message)
				})
		})
	}

	getCellHistory = (prototype, form, id, field) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/Prototypes/${prototype}/${form}/data/history/${id}/${field}`)
				.then(response => response.data)
				.then(data => {
					resolve(data)
				})
				.catch(error => {
					reject()
				})
		})
	}
}

const instance = new PulseApiService()

export default instance
