//
// Prototype.js
//
import { useDeepCompareEffect } from '@fuse/hooks'
import FusePageCarded from '@fuse/core/FusePageCarded'
import { createPath } from 'app/store/breadcrumbSlice'
import { hideTabs } from 'app/store/settingsSlice'

// ===== devextreme =====
import DataGrid, { Column, Editing, GroupPanel, Lookup, Paging, SearchPanel } from 'devextreme-react/data-grid'

// ===== or when using themes =====
import themes from 'devextreme/ui/themes'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getPrototypes, selectPrototypeById, selectPrototypes } from '../../forms/store/prototypes/prototypesSlice'
import pulseApiService from 'app/services/pulseApiService'

// Apply default Theme
themes.current('generic.compact')

// ..lookup models @keyByIndex
const models = pulseApiService.createRowCustomStore('index', '', 'Prototype/models')

const Prototype = props => {
	const dispatch = useDispatch()

	const { id } = useParams()

	/**
	 * Local instance.
	 */
	const [dataStore, setDataStore] = useState(null)

	/**
	 * Use Selectors.
	 */
	// eslint-disable-next-line no-shadow
	const prototypes = useSelector(selectPrototypes)

	// eslint-disable-next-line no-shadow
	const prototype = useSelector(state => selectPrototypeById(state, id))

	useDeepCompareEffect(() => {
		setDataStore(pulseApiService.createDataCustomStore('index', '', `Prototype/settings/${id}`, id))

		if (!prototypes.length) {
			dispatch(getPrototypes())
		}

		dispatch(hideTabs())
	}, [dispatch, id])

	/**
	 * Build Breadcrumb
	 */
	useEffect(() => {
		/**
		 * Building Breadcrumbs.
		 *
		 * @return array
		 */
		dispatch(
			createPath({
				id: 'root',
				title: 'Root',
				type: 'group',
				icon: 'star',
				children: [
					{
						id: 'settings',
						title: 'Settings',
						type: 'item',
						url: '/settings',
						children: [
							{
								id: 'prototypes',
								title: 'Prototypes',
								type: 'item',
								url: '/settings/prototypes',
								children: [
									{
										id: 'prototype',
										title: prototype ? prototype.prototype_name : null,
										type: 'item',
										url: `/settings/prototypes/prototype/${id}`
									}
								]
							}
						]
					}
				]
			})
		)
	}, [dispatch, id, prototype])

	function setDisplayValue(rowData, value, current) {
		const modelData = models.__rawData
		const keyIndex = modelData.findIndex(item => item.modelID === value)

		if (keyIndex !== -1) {
			rowData.lookupModel = modelData[keyIndex].namespace
		}

		rowData.lookupModelID = value
		rowData.dsplayName = null
	}

	function getFilteredColumns(options) {
		if (!options.data) {
			return []
		}

		return {
			store: pulseApiService.infiniteList('Model/columns'),
			filter: options.data ? ['model', '=', options.data.lookupModel] : null
		}
	}

	return (
		<FusePageCarded
			classes={{
				root: 'w-full',
				contentWrapper: 'p-0 h-auto',
				contentCard: 'rounded-none',
				header: 'h-0 max-h-0 min-h-0',
				sidebarHeader: 'min-h-96 h-96',
				rightSidebar: 'w-512'
			}}
			content={
				<DataGrid
					dataSource={dataStore}
					style={{
						width: '100%',
						height: '100%'
					}}
					id="gridContainer"
					allowColumnReordering
					keyBy="key"
					showBorders
					wordWrapEnabled
					focusedRowEnabled
					hoverStateEnabled
					allowColumnResizing
					columnAutoWidth
				>
					<GroupPanel visible />
					<Editing useIcons mode="form" allowUpdating allowAdding={false} allowDeleting={false} />
					<Paging defaultPageSize={100} />
					<SearchPanel visible />

					<Column dataField="key" caption="Column" dataType="string" allowEditing={false} />
					<Column dataField="modelID" caption="Persist Model">
						<Lookup dataSource={models} valueExpr="modelID" displayExpr="model" />
					</Column>
					<Column dataField="attribute" caption="Persist Column" dataType="string" />
					<Column dataField="lookupModelID" caption="Lookup Model" setCellValue={setDisplayValue}>
						<Lookup allowClearing dataSource={models} valueExpr="modelID" displayExpr="model" />
					</Column>
					<Column dataField="displayName" caption="Lookup Display Name" placeholder="none..." visible={false}>
						<Lookup allowClearing dataSource={getFilteredColumns} displayExpr="name" valueExpr="name" />
					</Column>
					<Column dataField="lookupFilter" caption="Lookup Context" dataType="string" />
					<Column dataField="Multiple" caption="Is Multiple" dataType="boolean" />
					<Column dataField="Editable" caption="Editable" dataType="boolean" />
				</DataGrid>
			}
			innerScroll
		/>
	)
}

export default Prototype
