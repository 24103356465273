import React from 'react'
import { withRouter } from 'react-router-dom'
import FormRow from './FormRow'
import MultiFormRow from './MultiFormRow'

const LayoutFormRow = props => {
	// eslint-disable-next-line no-shadow
	const { folder, key, item } = props

	if (item.layout) {
		return <MultiFormRow folder={folder} hasChildren={props.hasChildren} key={key} item={item} />
	}

	return <FormRow folder={folder} key={key} hasChildren={props.hasChildren} item={item} />
}

export default withRouter(LayoutFormRow)
