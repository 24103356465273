//
// MultiFormAppConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'

const MultiFormAppConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: [
				'/forms/:folder/multi-form/view/:formID/row/:uuid',
				'/forms/:folder/multi-form/view/:formID/tab/:tab',
				'/forms/:folder/multi-form/view/:formID/:params/tab/:tab',
				'/forms/:folder/multi-form/view/:formID/:params?'
			],
			component: React.lazy(() => import('./MultiFormViewApp'))
		}
	]
}

export default MultiFormAppConfig
