//
// genericSlice.js
//
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getGenericData = createAsyncThunk(
	'formsApp/generic/getGenericData',
	async (path, { dispatch, getState }) => {
		const cached = getState().formsApp.generic.cache
		const temp = []
		const url = path || 'root'
		for (const i in cached) {
			if (cached[i].path === url) {
				temp.push(cached[i])
			}
		}
		// if (temp.length) {
		// 	return temp
		// }
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Generic/data`, {
			route: path
		})
		return await response.data.data
	}
)

const genericAdapter = createEntityAdapter({
	selectId: item => item.item_id
})

export const {
	selectAll: selectGenericData,
	selectEntities: selectGenericDataEntities,
	selectById: selectGenericDataById
} = genericAdapter.getSelectors(state => state.formsApp.generic)

// initialize
const initialState = genericAdapter.getInitialState({
	loading: true,
	cache: [],
	requestId: null
})

/**
 * Slice Configuration.
 */
const genericSlice = createSlice({
	name: 'formsApp/generic',
	initialState: initialState,
	reducers: {
		updateGenericForm: (state, action) => {
			genericAdapter.updateOne(state, action.payload)
		},
		deleteGenericForm: (state, action) => {
			genericAdapter.removeOne(state, action.payload.id)
		},
		clear: (state, action) => {
			state.cache = []
		},
		resetData: (state, action) => {
			genericAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getGenericData.pending]: (state, action) => {
			const { requestId } = action.meta
			state.loading = true
			if (state !== 'pending') {
				state.requestId = requestId
			}
		},
		[getGenericData.fulfilled]: (state, action) => {
			const { requestId } = action.meta
			if (requestId !== state.requestId) {
				return
			}
			state.loading = false
			// create cache
			state.cache = Object.values(
				[...state.cache, ...action.payload].reduce((acc, x) => {
					acc[x.item_id] = acc[x.item_id] || { ...x }
					return acc
				}, {})
			)
			genericAdapter.setAll(state, action.payload)
		}
	}
})

export const { updateGenericForm, deleteGenericForm, clear, resetData } = genericSlice.actions

export default genericSlice.reducer
