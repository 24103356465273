//
// FormsAppConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'
import GenericConfigs from './generic/GenericConfigs'
import ReportAppConfig from './report/ReportAppConfig'
import SpecificConfigs from './specific/SpecificConfigs'

const ReportsAdminSettingsConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/reports/admin/data',
			component: React.lazy(() => import('./ReportsAdminSettings'))
		}
	]
}

const ReportAppConfigs = [ReportsAdminSettingsConfig, ReportAppConfig, ...SpecificConfigs, ...GenericConfigs]

export default ReportAppConfigs
