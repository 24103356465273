import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getUsers = createAsyncThunk('formsApp/users/getUsers', async () => {
	const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + '/Users/users')
	return await response.data.data
})

const usersAdapter = createEntityAdapter({
	// ..assume IDs are stored in a field other than `provider.provider_id`
	selectId: user => user.UserID
})

export const {
	selectAll: selectUsers,
	selectEntities: selectUserEntities,
	selectById: selectProviderById
} = usersAdapter.getSelectors(state => state.formsApp.users)

const usersSlice = createSlice({
	name: 'formsApp/users',
	initialState: usersAdapter.getInitialState({
		dialog: {
			data: null,
			open: false
		}
	}),
	reducers: {
		openUsersDialog: (state, action) => {
			state.dialog.open = true
		},
		closeUserDialog: (state, action) => {
			state.dialog.open = false
		}
	},
	extraReducers: {
		[getUsers.fulfilled]: usersAdapter.setAll
	}
})

export const { openUsersDialog, closeUserDialog } = usersSlice.actions

export default usersSlice.reducer
