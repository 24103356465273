//
// ProviderConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'

const ProviderConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/reports/:folder/provider/:provider',
			component: React.lazy(() => import('./ProviderApp'))
		}
	]
}

export default ProviderConfig
