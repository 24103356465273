import React, { useState, useEffect } from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getPathTree } from './Functions'
import withReducer from './store/withReducer'
import reducer from './store'

import HomeIcon from '@material-ui/icons/Home'
import Chip from '@material-ui/core/Chip'

import { emphasize, withStyles } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton'
import { BreadcrumbLoader } from './@artemis/Loader'

const useStyles = makeStyles(theme => ({
	locked: {
		pointerEvents: 'none',
		opacity: 0.5
	},
	default: {}
}))

const StyledBreadcrumb = withStyles(theme => ({
	root: {
		backgroundColor: theme.palette.grey[100],
		height: theme.spacing(3),
		color: theme.palette.grey[800],
		fontWeight: theme.typography.fontWeightRegular,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.grey[300]
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(theme.palette.grey[300], 0.12)
		}
	}
}))(Chip) // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const Breadcrumb = (props, { className }) => {
	const classes = useStyles(props)
	const { pathname } = useLocation()

	/**
	 * Use Selectors.
	 */
	// eslint-disable-next-line no-shadow
	const path = useSelector(({ breadcrumbs }) => breadcrumbs.path)
	// eslint-disable-next-line no-shadow
	const selectedTab = useSelector(({ tabs }) => tabs.selected)
	// eslint-disable-next-line no-shadow
	const items = useSelector(({ tabs }) => tabs.items)
	// eslint-disable-next-line no-shadow
	const pathArr = getPathTree(path, pathname)

	/**
	 * Local instance.
	 */
	const [locked, setLocked] = useState(false)

	useEffect(() => {
		if (items) {
			if (selectedTab > -1) {
				setLocked(true)
			} else {
				setLocked(false)
			}
		}
	}, [selectedTab, items])

	/**
	 * Use selectors.
	 */
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)

	let hasNull = false
	if (pathArr.length) {
		hasNull = pathArr.findIndex(p => p.title === null) !== -1
	}

	return (
		<div className={clsx('p-10 flex flex-row', className)}>
			{config.navbar.display && config.navbar.position === 'left' && (
				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
				</Hidden>
			)}
			<BreadcrumbLoader length={pathArr.length} loading={hasNull ? 1 : 0}>
				<Breadcrumbs separator="›" aria-label="breadcrumb" className="flex items-center">
					<StyledBreadcrumb
						component="a"
						label="Pulse"
						icon={<HomeIcon fontSize="small" />}
						onClick={() => props.history.push('/dashboard')}
					/>
					{pathArr.map((item, key) => (
						<div key={key}>
							{key === 0 && pathArr.length === 1 && (
								<Typography className={clsx(classes.default, 'cursor-default')}>
									{item.title ? item.title : 'loading..'}
								</Typography>
							)}
							{key === 0 && pathArr.length > 1 && (
								<Link
									className={
										locked
											? clsx(classes.locked, className)
											: clsx(classes.default, 'hover:underline')
									}
									color="initial"
									onClick={() => {
										if (typeof pathArr[0].tab !== 'undefined') {
											props.history.push(item.url, { tab: pathArr[0].tab })
										} else {
											props.history.push(item.url)
										}
									}}
									role="button"
									disabled={locked}
									component="button"
								>
									{item.title ? item.title : 'loading..'}
								</Link>
							)}
							{key > 0 && pathArr.length - 1 !== key && (
								<Link
									className={
										locked
											? clsx(classes.locked, className)
											: clsx(classes.default, 'hover:underline')
									}
									color="initial"
									onClick={() => {
										if (typeof pathArr[0].tab !== 'undefined') {
											props.history.push(item.url, { tab: pathArr[0].tab })
										} else {
											props.history.push(item.url)
										}
									}}
									role="button"
									disabled={locked}
									component="button"
								>
									{item.title ? item.title : `wait..`}
								</Link>
							)}
							{key > 0 && pathArr.length - 1 === key && (
								<Typography
									component="button"
									className={clsx(classes.default, 'cursor-default font-bold')}
								>
									{item.title ? item.title : 'loading..'}
								</Typography>
							)}
						</div>
					))}
				</Breadcrumbs>
			</BreadcrumbLoader>
		</div>
	)
}

export default withRouter(withReducer('breadcrumbApp', reducer)(Breadcrumb))
