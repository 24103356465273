//
// LayoutSelection.js
//
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFormLayout } from '../store/forms/formSlice'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import LayoutIcon from './LayoutIcon'

const LayoutSelection = props => {
	const dispatch = useDispatch()
	// eslint-disable-next-line no-shadow
	const formData = useSelector(({ formsApp }) => formsApp.form)
	const buttons = ['1', '1/1', '1-1', '1/1-1']
	return (
		<ToggleButtonGroup
			size="large"
			exclusive
			value={formData.layout}
			onChange={e => {
				dispatch(setFormLayout(e.target.closest('button').value))
			}}
		>
			{buttons.map(button => (
				<ToggleButton key={button} value={button} disabled={props.disabled ?? false}>
					<LayoutIcon template={button} />
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	)
}

export default LayoutSelection
