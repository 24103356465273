import React from 'react'
import globalConfig from 'app/fuse-configs/globalConfig'

const Error404PageConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['guest'],
	routes: [
		{
			path: '/error-404',
			component: React.lazy(() => import('./Error404Page'))
		}
	]
}

export default Error404PageConfig
