//
// formSlice.js
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { formatDate } from 'app/@artemis/helper'
import axios from 'axios'
import FormModel from '../../form/model/FormModel'
import { reloadDepartment } from '../departments/departmentSlice'
import { reloadDepartments } from '../departments/departmentsSlice'
import { reloadPrototype } from '../prototypes/prototypeSlice'
import { reloadProvider } from '../providers/providerSlice'
import { reloadProviders } from '../providers/providersSlice'
import { resetForms } from './formsSlice'
import { clear } from './genericSlice'
import { getPrototypeData } from './infoSlice'
import { getPersonalForm } from './personalSlice'

/**
 * Get the Form
 * @async Thunk
 */
export const getForm = createAsyncThunk(
	// action type string
	'formsApp/form/getForm',
	// callback function
	async (params, { rejectWithValue }) => {
		// ====== Post to API ==========
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/form`, params)

			return response.data
		} catch (err) {
			// Use `err.response.data` as `action.payload` for a `rejected` action,
			// by explicitly returning it using the `rejectWithValue()` utility
			return rejectWithValue(err.response)
		}
	}
)

/**
 * Insert
 * @async Thunk
 */
export const createForm = createAsyncThunk(
	// action type string
	'formsApp/form/addForm',
	// callback function
	async (params, { dispatch, getState }) => {
		const { type, formData } = params

		// ====== Post to API ==========
		const response = await axios.post(
			`${process.env.REACT_APP_API_PULSE_URL}/Form/add`,
			new FormModel(formData, type)
		)

		// After creation
		dispatch(clear())
		dispatch(reloadPrototype())
		dispatch(reloadProvider())
		dispatch(reloadDepartment())
		dispatch(reloadProviders())
		dispatch(resetForms())

		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Update
 * @async Thunk
 */
export const updateForm = createAsyncThunk(
	// action type string
	'formsApp/form/editForm',
	// callback function
	async (params, { dispatch, getState }) => {
		const { formData } = params

		// ====== Post to API ==========
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/update`, new FormModel(formData))

		// After update
		dispatch(reloadDepartments())
		dispatch(reloadDepartment())
		dispatch(clear())

		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Delete
 * @async Thunk
 */
export const deleteForm = createAsyncThunk(
	// action type string
	'formsApp/form/deleteForm',
	// callback function
	async (params, { dispatch }) => {
		// eslint-disable-next-line camelcase
		const { id } = params

		// ====== Post to API ==========
		const response = await axios.post(
			`${process.env.REACT_APP_API_PULSE_URL}/Form/delete`,
			{ id },
			{ validateStatus: () => true }
		)

		// After delete
		dispatch(reloadPrototype())
		dispatch(reloadProvider())
		dispatch(reloadProviders())
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Delete custom Form
 * @async Thunk
 */
export const deletePersonalForm = createAsyncThunk(
	// action type string
	'formsApp/form/deleteChildForm',
	// callback function
	async (params, { rejectWithValue }) => {
		const { id } = params
		return await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/deleteChild`, { id })
	}
)

/**
 * Add Shortcut to dashboard
 * @async Thunk
 */
export const addFormShortcut = createAsyncThunk('formsApp/form/addShortcut', async params => {
	// eslint-disable-next-line camelcase
	const { id } = params

	const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/add/shortcut`, { id })
	const { data } = response

	// eslint-disable-next-line no-return-await
	return await data
})

const initialState = {
	type: 'new',
	isPersonal: false,
	form_id: null,
	title: '',
	provider_id: 0,
	prototype_id: 0,
	department_id: 0,
	layout: '1',
	layout_forms: {},
	dialog: false,
	step: 'select_provider',
	fieldsDialog: false,
	linkDialog: {
		open: false
	},
	selectedRows: 0,
	rowDetails: null,
	totalRows: 0,
	selectedField: null,
	settingsDialog: false,
	shareDialog: false,
	selectedFields: [],
	filters: null,
	extraFilters: null,
	calculatedExtraFilters: null,
	fieldGroups: [],
	sortOrderField: null,
	sortOrderValue: 'asc',
	users: [],
	groups: [],
	responsibles: [],
	route: '',
	connectedForm: {
		settings_formatted: {},
		master: false,
		criteria: []
	},
	allow_save: false,
	allow_comments: true,
	allow_files: true,
	allow_bulk: true,
	allow_delete: true,
	selectedTab: 0,
	relations: [],
	commentEntity: null,
	commentSet: null,
	summaryGroups: {},
	owner: null
}

const fieldModel = value => {
	return {
		dataField: value,
		dataReadOnly: false,
		visible: true,
		dataSensitive: false,
		dataRequired: false,
		dataFixed: false,
		dataHidden: false,
		dataAlignment: 'left',
		dataCondition: [],
		dataColor: null,
		dataLookupFilter: null
	}
}

const formSlice = createSlice({
	// A name, used in action types
	name: 'formsApp/form',
	// The initial state for the reducer
	initialState: initialState,
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {
		// New form dialog case reducers
		openNewFormDialog: {
			reducer: (state, action) => action.payload,
			prepare: event => ({
				payload: { ...initialState, dialog: true }
			})
		},
		nextFormDialog: (state, action) => {
			const { step } = action.payload
			// Sets the step
			state.step = step
		},
		closeFormDialog: (state, action) => {
			state.dialog = false
		},
		// Form builder case reducers
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		},
		setRowDetails: (state, action) => {
			state.rowDetails = action.payload
		},
		setTotalCount: (state, action) => {
			state.totalRows = action.payload
		},
		setUsers: (state, action) => {
			state.users = action.payload
		},
		setResponsibles: (state, action) => {
			state.responsibles = action.payload
		},
		setGroups: (state, action) => {
			state.groups = action.payload
		},
		setSelectedFields: (state, action) => {
			// eslint-disable-next-line
			const { fields } = action.payload
			// eslint-disable-next-line
			const newFields = []

			fields.forEach((field, index) => {
				const { value } = field

				const fieldIndex = state.selectedFields.findIndex(s => s.dataField === value)

				const cached = state.selectedFields[fieldIndex]

				if (cached) {
					newFields.push(cached)
				} else {
					newFields.push(fieldModel(value))
				}
			})

			// Update fields
			state.selectedFields = newFields
		},
		setField: (state, action) => {
			state.selectedField = action.payload

			// close settings dialog
			state.settingsDialog = false
		},
		unsetField: (state, action) => {
			state.selectedField = null
		},
		updateField: (state, action) => {
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			const { dataField, property, value } = action.payload
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			const fieldIndex = state.selectedFields.findIndex(field => field.dataField === dataField)

			// Updates field property by index
			state.selectedFields[fieldIndex][property] = value
		},
		setFieldsOrder: (state, action) => {
			const { from, to } = action.payload

			// Updates fields order
			state.selectedFields.splice(from, 0, state.selectedFields.splice(to, 1)[0])
		},
		// OnOptionUpdate
		setFieldGroups: (state, action) => {
			state.fieldGroups = action.payload
		},
		setSortOrder: (state, action) => {
			state.sortOrderField = action.payload.field
			state.sortOrderValue = action.payload.order
		},
		setFilters: (state, action) => {
			const { filter } = action.payload

			state.filters = filter
		},
		setExtraFilters: (state, action) => {
			const { extraFilter, calculatedExtraFilters } = action.payload

			// Sets the filter builder
			state.extraFilters = extraFilter
			state.calculatedExtraFilters = calculatedExtraFilters
		},
		setBuilderFilters: (state, action) => {
			const { filter } = action.payload

			// Sets the filter builder
			state.builderFilters = filter
		},
		setFormTitle: (state, action) => {
			state.title = action.payload
		},
		// fields dialog
		openFieldsDialog: (state, action) => {
			state.fieldsDialog = true
		},
		closeFieldsDialog: (state, action) => {
			state.fieldsDialog = false
		},
		// settings dialog
		openSettingsDialog: (state, action) => {
			state.settingsDialog = true
			state.shareDialog = false
			state.selectedField = null
		},
		closeSettingsDialog: (state, action) => {
			state.settingsDialog = false
		},
		// share dialog
		openShareDialog: (state, action) => {
			state.shareDialog = true
			state.selectedField = null
			state.settingsDialog = false
		},
		closeShareDialog: (state, action) => {
			state.shareDialog = false
		},
		setFormDepartment: (state, action) => {
			state.department_id = parseInt(action.payload)
		},
		setFormProvider: (state, action) => {
			state.provider_id = parseInt(action.payload)
			state.layout_forms = {}
			state.prototype_id = 0
			state.layout = '1'
		},
		setFormPrototype: (state, action) => {
			state.prototype_id = parseInt(action.payload)
		},
		setFormLayout: (state, action) => {
			state.layout = action.payload
		},
		setLayoutFormSelection: (state, action) => {
			const { id, val } = action.payload
			if (val !== '0') {
				state.layout_forms[id] = { id: val, filters: null }
			} else {
				delete state.layout_forms[id]
			}
		},
		setLayoutFormsRelations: (state, action) => {
			const { id, val } = action.payload
			state.layout_forms[id].filters = val
		},
		setFormRoute: (state, action) => {
			state.route = action.payload
		},
		// form settings
		enableSaveButton: (state, action) => {
			state.allow_save = action.payload
		},
		enableComments: (state, action) => {
			state.allow_comments = action.payload
		},
		enableFiles: (state, action) => {
			state.allow_files = action.payload
		},
		enableBulkUpdate: (state, action) => {
			state.allow_bulk = action.payload
		},
		enableForecasts: (state, action) => {
			state.forecasts = action.payload
		},
		enableDelete: (state, action) => {
			state.allow_delete = action.payload
		},
		// form relations
		setConnectedForm: (state, action) => {
			state.connectedForm = action.payload
		},
		addRelation: (state, action) => {
			state.relations.push(action.payload)
		},
		updateRelation: (state, action) => {
			state.relations[state.linkDialog.key] = action.payload
		},
		// replace all relations
		addRelations: (state, action) => {
			state.relations = action.payload
		},
		removeRelation: (state, action) => {
			state.relations.splice(action.payload, 1)
		},
		updateConnected: (state, action) => {
			const { checked } = action.payload
			state.connectedForm.master = checked
		},
		closeLinkDialog: (state, action) => {
			state.linkDialog = {
				open: false,
				key: 0
			}
		},
		handleTabChange: (state, action) => {
			state.selectedTab = action.payload
		},
		openLinkDialog: (state, action) => {
			// extract-from-payload
			const { edit, index } = action.payload

			// Reset connection
			state.connectedForm = initialState.connectedForm

			// Open relations dialog
			state.linkDialog = {
				open: true,
				key: typeof index !== 'undefined' ? index : -1,
				edit
			}
		},
		insertSummaryGroup: (state, action) => {
			const { field, type } = action.payload

			// Add to summaries
			state.summaryGroups[field] = type
		},
		removeSummaryGroup: (state, action) => {
			const { field } = action.payload

			// Remove from summaries
			delete state.summaryGroups[field]
		}
	},
	/**
	 * A "builder callback" function used to add more reducers,
	 * or an additional object of "case reducers", where the keys should be other action types
	 */
	extraReducers: {
		[getPrototypeData.pending]: (state, action) => {
			const { prototype_id, user } = action.meta.arg

			let initialValues = {}

			// Sets a default form's user
			if (user) {
				initialValues.users = [
					{
						label: user.data.displayName,
						value: user.data.id
					}
				]
			}

			// Sets the prototype's ID if argument exists.
			if (prototype_id) {
				initialValues.prototype_id = prototype_id
			}

			return {
				...state, //<-- To be overridden from initial values.
				...initialValues
			}
		},
		// Reset form state
		[getForm.pending]: (state, action) => {
			if (action.meta.arg.formID !== state.form_id) {
				return { ...initialState, type: action.meta.arg.parent ? 'new' : 'edit' }
			} else {
				return { ...state, selectedRows: 0, type: action.meta.arg.parent ? 'new' : 'edit' }
			}
		},
		// Reset customized form state
		[getPersonalForm.pending]: (state, action) => {
			if (action.meta.arg.formID !== state.form_id) {
				return { ...initialState, type: 'edit', isPersonal: true }
			} else {
				return { ...state, selectedRows: 0, type: 'edit', isPersonal: true }
			}
		},
		// Add customized form to state object
		[getPersonalForm.fulfilled]: (state, action) => {
			const { personalForm, cached } = action.payload

			const parentForm = personalForm.form

			let fieldGroups = [...personalForm.settings_formatted.groups]

			let newFields = []
			if (Object.keys(cached).length > 0) {
				newFields = cached.columns
				fieldGroups = cached.groups
			} else {
				for (const i in personalForm.settings_formatted.fields) {
					newFields.push(personalForm.settings_formatted.fields[i])
				}
			}

			return {
				...state,
				form: parentForm,
				form_id: parentForm.form_id,
				title: parentForm.form_name,
				prototype_id: parentForm.prototype_id,
				provider_id: parentForm.provider_id,
				department_id: parentForm.department_id,
				selectedFields: newFields,
				layout: personalForm.form.layout,
				layout_forms: personalForm.form.layout_forms,
				relations: formatRelations(parentForm.relations),
				filters: formatDate({ mode: 'view' }, parentForm.settings_formatted.filters),
				extraFilters: formatDate(
					action.meta.arg,
					personalForm.settings_formatted.editFilters ?? personalForm.settings_formatted.filters
				),
				calculatedExtraFilters: formatDate(action.meta.arg, personalForm.settings_formatted.filters),
				builderFilters: formatDate(
					{ mode: 'cached' },
					cached.filters || personalForm.settings_formatted.builderFilters
				),
				fieldGroups: fieldGroups,
				sortOrderField: personalForm.settings_formatted.sortOrder,
				sortOrderValue: personalForm.settings_formatted.sortOrderValue || state.sortOrderValue,
				commentEntity: parentForm.prototype.comment_entity
					? parentForm.prototype.comment_entity.comment_entity_id
					: null,
				commentSet:
					parentForm.prototype.comment_entity && parentForm.prototype.comment_entity.comment_set
						? parentForm.prototype.comment_entity.comment_set_id
						: null,
				route: parentForm.generic_route,
				allow_comments: parentForm.settings_formatted.allow_comments,
				allow_files: parentForm.settings_formatted.allow_files,
				allow_forecasts: parentForm.settings_formatted.allow_forecasts,
				allow_bulk: parentForm.settings_formatted.allow_bulk
			}
		},
		// Add form to state object
		[getForm.fulfilled]: (state, action) => {
			const { form, cached } = action.payload

			// eslint-disable-next-line no-shadow,camelcase
			const settings = form.settings_formatted

			// Collect fields into newFields array
			let newFields = []
			let fieldGroups = [...settings.groups]

			// Extract from cache
			if (Object.keys(cached).length > 0) {
				newFields = cached.columns
				fieldGroups = cached.groups
			} else {
				for (const i in settings.fields) {
					if (
						!settings.fields[i].dataField.includes('_displayname') &&
						!settings.fields[i].dataField.includes('rowguid')
					) {
						newFields.push(settings.fields[i])
					}
				}
			}

			return {
				...state, //<-- To be overridden
				...action.payload, //<-- Merge response data with initial state
				title: form.form_name,
				prototype_id: form.prototype_id,
				provider_id: form.provider_id,
				department_id: form.department_id,
				selectedFields: newFields,
				filters: formatDate(action.meta.arg, settings.filters),
				extraFilters: null, // <!-- Only used for Personal form
				calculatedExtraFilters: null,
				builderFilters: formatDate({ mode: 'cached' }, cached.filters) || null,
				fieldGroups: fieldGroups,
				sortOrderField: settings.sortOrder,
				sortOrderValue: settings.sortOrderValue || state.sortOrderValue,
				relations: formatRelations(form.relations),
				commentEntity: form.prototype.comment_entity ? form.prototype.comment_entity.comment_entity_id : null,
				commentSet:
					form.prototype.comment_entity && form.prototype.comment_entity.comment_set
						? form.prototype.comment_entity.comment_set_id
						: null,
				users: dropdownObj(form.users, 'full_name', 'UserID'),
				groups: dropdownObj(form.groups, 'user_group_name', 'user_group_id'),
				responsibles: dropdownObj(form.responsibles, 'full_name', 'UserID'),
				form_id: form.form_id,
				route: form.generic_route,
				allow_comments: form.has_comments ? settings.allow_comments : false,
				allow_files: form.has_files ? settings.allow_files : false,
				allow_bulk: settings.allow_bulk ? form.bulk_update : false,
				allow_delete: settings.allow_delete ? settings.allow_delete : false,
				allow_forecasts: settings.allow_forecasts ? settings.allow_forecasts : false,
				isPersonal: action.meta.arg.parent ?? action.meta.arg.parent,
				owner: form.created_by
			}
		}
		// [addFormShortcut.fulfilled]: (state, action) => {},
		// [deleteForm.fulfilled]: (state, action) => {},
		// [deletePersonalForm.fulfilled]: (state, action) => {},
		// [createForm.fulfilled]: (state, action) => {}
	}
})

const dropdownObj = (data, label, value) => {
	let results = []

	// Collect responsible into responsibleData array
	for (const i in data) {
		const item = data[i]
		results.push({
			label: item[label],
			value: item[value]
		})
	}
	return results
}

/**
 * Return relations array
 */
const formatRelations = relations => {
	let results = []

	// Collect Relations
	if (relations) {
		for (const i in relations) {
			const relation = relations[i]
			// extract-from-from-relation
			const { form, relation_id, master, filters } = relation

			results.push({
				form_id: relation_id,
				available: form !== null,
				settings: form !== null ? form.settings_formatted : [],
				prototype_id: form !== null ? form.prototype_id : null,
				prototype_configuration: form !== null ? { settings_formatted: form.configuration } : null,
				relations: form !== null ? form.relations : [],
				form_name: form !== null ? form.form_name : 'No longer available :(',
				master: master === 1 ?? true,
				criteria: JSON.parse(filters),
				edit: true
			})
		}
	}
	return results
}

export const {
	openNewFormDialog,
	nextFormDialog,
	closeFormDialog,
	setSelectedRows,
	setRowDetails,
	setTotalCount,
	setSelectedFields,
	openFieldsDialog,
	closeFieldsDialog,
	setField,
	setUsers,
	setGroups,
	setResponsibles,
	unsetField,
	updateField,
	setFieldsOrder,
	setFieldGroups,
	setSortOrder,
	setFilters,
	setExtraFilters,
	setBuilderFilters,
	setFormTitle,
	setFormLayout,
	setLayoutFormSelection,
	setLayoutFormsRelations,
	openSettingsDialog,
	closeSettingsDialog,
	openShareDialog,
	closeShareDialog,
	setFormDepartment,
	setFormProvider,
	setFormPrototype,
	setFormRoute,
	setConnectedForm,
	addRelation,
	updateRelation,
	addRelations,
	enableSaveButton,
	removeRelation,
	updateConnected,
	closeLinkDialog,
	handleTabChange,
	openLinkDialog,
	enableComments,
	enableFiles,
	enableBulkUpdate,
	enableForecasts,
	insertSummaryGroup,
	removeSummaryGroup,
	enableDelete
} = formSlice.actions

export default formSlice.reducer
