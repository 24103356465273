//
// RelationsFilterBuilder.js
//

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FilterBuilder from 'devextreme-react/filter-builder'
import React from 'react'

const RelationsFilterBuilder = props => {
	const [value, setValue] = React.useState(props.value)

	const onValueChanged = event => {
		setValue(event.value)
		props.onChange(event)
	}

	return (
		<FormControl className="mt-8 mb-16 w-full" variant="outlined" fullWidth>
			<FormLabel component="legend" className="text-14">
				{props.title}
			</FormLabel>
			<FilterBuilder
				value={value}
				fields={props.fields}
				onValueChanged={onValueChanged}
				groupOperations={['and']}
				maxGroupLevel={0}
				style={{
					padding: 25,
					borderColor: 'rgba(0, 0, 0, 0.6)',
					borderStyle: 'dashed',
					borderWidth: 2,
					borderRadius: 4,
					marginTop: 25
				}}
			/>
		</FormControl>
	)
}

export default RelationsFilterBuilder
