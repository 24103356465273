//
// StorageModel.js
//
class StorageModel {
	constructor(state, type) {
		this.options = {
			columns: state.columns || {},
			fields: state.fields || [],
			filters: state.filters || null,
			groups: state.groups || [],
			sortOrder: state.sortOrder || []
		}
	}
}

export default StorageModel
