import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getNotifiers = createAsyncThunk('formsApp/notifiers/getNotifiers', async text => {
	const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + `/Notifiers/search/${text}`)

	return await response.data.data
})

const notifiersAdapter = createEntityAdapter({
	// ..assume IDs are stored in a field other than `provider.provider_id`
	selectId: notifier => notifier.UserID
})

export const {
	selectAll: selectNotifiers,
	selectEntities: selectNotifierEntities,
	selectById: selectNotifierById
} = notifiersAdapter.getSelectors(state => state.formsApp.notifiers)

const notifiersSlice = createSlice({
	name: 'formsApp/notifiers',
	initialState: notifiersAdapter.getInitialState({}),
	reducers: {},
	extraReducers: {
		[getNotifiers.fulfilled]: notifiersAdapter.setAll
	}
})

export default notifiersSlice.reducer
