import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getFilledByRowUIDs = createAsyncThunk(
	'formsApp/folders/getFilledByRowUIDs',
	async (params, { dispatch, getState }) => {
		const { rowguids } = params

		dispatch(setCounting())

		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Folders/filled`, {
			uuid: rowguids.join(',')
		})

		dispatch(setFilled(response.data.filled))

		return await response.data.filled
	}
)

const filesAdapter = createEntityAdapter({
	// Assume IDs are stored in a field other than `record.uuid`
	selectId: record => record.uuid
})

const filesSlice = createSlice({
	// A name, used in action types
	name: 'formsApp/files',
	// The initial state for the reducer
	initialState: filesAdapter.getInitialState({
		loading: true,
		expanded: false,
		counting: false,
		filled: {}
	}),
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {
		setFilesExpanded: (state, action) => {
			state.expanded = true
		},
		setFilesCollapsed: (state, action) => {
			state.expanded = false
		},
		setCounting: (state, action) => {
			state.counting = true
		},
		setFilled: (state, action) => {
			state.filled = { ...state.filled, ...action.payload }
			state.counting = false
		}
	},
	/**
	 * A "builder callback" function used to add more reducers,
	 * or an additional object of "case reducers", where the keys should be other action types
	 */
	extraReducers: {
		[getFilledByRowUIDs.fulfilled]: (state, action) => {
			filesAdapter.setAll(state, action.payload)
			state.loading = false
		}
	}
})

// eslint-disable-next-line no-empty-pattern
export const { setFilesExpanded, setFilesCollapsed, setCounting, setFilled } = filesSlice.actions

export default filesSlice.reducer
