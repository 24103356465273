//
// EmployeesConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'

const DefaultConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/forms/generic/:path+',
			component: React.lazy(() => import('./GenericApp'))
		},
		{
			path: '/forms/generic',
			component: React.lazy(() => import('./GenericApp'))
		}
	]
}

const GenericConfigs = [DefaultConfig]

export default GenericConfigs
