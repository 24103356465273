//
// providersSlice.js
//
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

// eslint-disable-next-line no-empty-pattern
export const getProviders = createAsyncThunk(
	'formsApp/providers/getProviders',
	async (params, { dispatch, getState }) => {
		const cached = getState().formsApp.providers
		if (Object.keys(cached.entities).length > 0) {
			return cached.entities
		}
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Providers/providers`)
		return await response.data.data
	}
)

const providersAdapter = createEntityAdapter({
	// .assume IDs are stored in a field other than `provider.provider_id`
	selectId: provider => provider.provider_id
})

export const {
	selectAll: selectProviders,
	selectEntities: selectProvidersEntities,
	selectById: selectProviderById
} = providersAdapter.getSelectors(state => state.formsApp.providers)

const providersSlice = createSlice({
	name: 'formsApp/providers',
	initialState: providersAdapter.getInitialState({
		loading: true,
		all: {
			entities: {},
			ids: []
		}
	}),
	reducers: {
		loading: (state, action) => {
			state.loading = true
		},
		reloadProviders: (state, action) => {
			providersAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getProviders.fulfilled]: (state, action) => {
			providersAdapter.setAll(state, action.payload)
			state.loading = false
		}
	}
})

// eslint-disable-next-line no-empty-pattern
export const { loading, reloadProviders } = providersSlice.actions

export default providersSlice.reducer
