//
// notificationsSlice.js
//
import axios from 'axios'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const getNotifications = createAsyncThunk('notificationsApp/notifications/getNotifications', async () => {
	const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + '/User/notifications')
	return await response.data.data
})

export const removeNotification = createAsyncThunk('formsApp/forms/deleteForm', async (params, { dispatch }) => {
	// eslint-disable-next-line camelcase
	const { id } = params

	// post-form-to-delete
	const response = await axios.post(
		`${process.env.REACT_APP_API_PULSE_URL}/User/notifications/delete`,
		{ id },
		{ validateStatus: () => true }
	)
	// eslint-disable-next-line no-return-await
	return await response.data
})

const notificationsAdapter = createEntityAdapter({
	// assume IDs are stored in a field other than `notification.notifiable_id`
	selectId: notification => notification.id
})

export const {
	selectAll: selectNotifications,
	selectEntities: selectNotificationsEntities,
	selectById: selectNotificationById
} = notificationsAdapter.getSelectors(state => state.notificationsApp.notifications)

const notificationsSlice = createSlice({
	name: 'notificationsApp/notifications',
	initialState: notificationsAdapter.getInitialState({}),
	reducers: {
		addNotification: (state, action) => {
			notificationsAdapter.addOne(state, action.payload)
		},
		removeAllNotifications: (state, action) => {}
	},
	extraReducers: {
		[getNotifications.fulfilled]: (state, action) => {
			notificationsAdapter.setAll(state, action.payload)
		},
		[removeNotification.pending]: (state, action) => {
			notificationsAdapter.updateOne(state, { id: action.meta.arg.id, changes: { toBeRemoved: true } })
		},
		[removeNotification.fulfilled]: (state, action) => {
			notificationsAdapter.removeOne(state, action.payload.id)
		}
	}
})

export const { removeAllNotifications } = notificationsSlice.actions

export default notificationsSlice.reducer
