import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getForms = createAsyncThunk(
	'formsApp/forms/getPrototypesByProvider',
	async (params, { dispatch, getState }) => {
		const cached = getState().formsApp.forms
		if (Object.keys(cached.entities).length > 0) {
			return cached.entities
		}
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Forms/forms`, params)
		// eslint-disable-next-line no-return-await
		return await response.data.forms
	}
)

/**
 * Forms Adapter
 */
const formsAdapter = createEntityAdapter({
	// assume IDs are stored in a field other than `provider.provider_id`
	selectId: form => form.form_id
})

export const {
	selectAll: selectForms,
	selectEntities: selectFormsEntities,
	selectById: selectFormsById
} = formsAdapter.getSelectors(state => state.formsApp.forms)

/**
 * Slice Configuration.
 */
const formsSlice = createSlice({
	name: 'formsApp/forms',
	initialState: formsAdapter.getInitialState(),
	reducers: {
		resetForms: (state, action) => {
			formsAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getForms.fulfilled]: (state, action) => {
			formsAdapter.setAll(state, action.payload)
		}
	}
})

// eslint-disable-next-line no-empty-pattern
export const { resetForms } = formsSlice.actions

export default formsSlice.reducer
