import FuseUtils from '@fuse/utils/FuseUtils'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
// eslint-disable-next-line import/no-cycle
import apiService from './index'

// eslint-disable camelcase
class ApiService extends FuseUtils.EventEmitter {
	apiUrl = process.env.REACT_APP_API_ID_URL
	userID = null

	init() {
		this.setInterceptors()
		this.handleAuthentication()
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response && err.response.status === 401) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token')
						this.setSession(null)
					}
					Sentry.captureException(err)
					throw err
				})
			}
		)
	}

	handleAuthentication = () => {
		// eslint-disable-next-line camelcase
		const access_token = this.getAccessToken()

		// eslint-disable-next-line camelcase
		if (!access_token) {
			this.emit('onNoAccessToken')
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token)
			this.emit('onAutoLogin', true)
		} else {
			this.logout({ error: 'Unauthorized' })
		}
	}

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/auth/me?full=1`)
				.then(response => {
					resolve(response.data.user)
				})
				.catch(error => {
					this.logout(error)
					reject(new Error('Failed to login'))
				})
		})
	}

	loadData = (model, options) => {
		const isNotEmpty = value => {
			return value !== undefined && value !== null && value !== ''
		}

		let params = '?'
		;['skip', 'take', 'requireTotalCount', 'sort', 'filter'].forEach(i => {
			if (i in options && isNotEmpty(options[i])) {
				params += `${i}=${JSON.stringify(options[i])}&`
			}
		})

		params = params.slice(0, -1)

		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/${model}/all${params}`)
				.then(response => response.data)
				.then(data => {
					resolve({
						data: data.data,
						totalCount: data.totalCount
					})
				})
				.catch(error => {
					this.logout(error)
					reject()
				})
		})
	}

	getValidToken = code => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${this.apiUrl}/auth/token/${code}`)
				.then(response => {
					apiService.emit('onWelcome')

					resolve(response.data.auth.access_token)
				})
				.catch(error => {
					window.location.href = `${process.env.REACT_APP_ID_URL}/identifier/pulse`
					reject()
				})
		})
	}

	updateUserData = user => {
		/*
		return axios.post('/api/auth/user/update', {
			user
		})
		*/
	}

	// eslint-disable-next-line camelcase
	setSession = access_token => {
		// eslint-disable-next-line camelcase
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token)
			// eslint-disable-next-line camelcase
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
		} else {
			localStorage.removeItem('jwt_access_token')
			delete axios.defaults.headers.common.Authorization
		}
	}

	logout = payload => {
		if (payload.error === 'Unauthorized') {
			this.setSession(null)
			this.emit('onAutoLogout', 'Unauthorized')
		}
	}

	// eslint-disable-next-line camelcase
	isAuthTokenValid = access_token => {
		// eslint-disable-next-line camelcase
		if (!access_token) {
			return false
		}
		const decoded = jwtDecode(access_token)
		const currentTime = Date.now() / 1000
		if (decoded.exp < currentTime) {
			console.warn('access token expired')
			return false
		}

		return true
	}

	getAccessToken = () => {
		return localStorage.getItem('jwt_access_token')
	}

	setUserID = id => {
		this.userID = parseInt(id)
	}
}

const instance = new ApiService()

export default instance
