import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getCommentCategories = createAsyncThunk(
	'formsApp/commentCategories/getCommentCategories',
	async (params, { dispatch, getState }) => {
		const { comment_set_id, comment_entity_id } = params
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Comments/categories`, {
			comment_set_id: comment_set_id,
			comment_entity_id: comment_entity_id
		})
		// eslint-disable-next-line no-return-await
		return await response.data.data
	}
)

// Since we don't provide `comment_category_id`, it defaults to assuming `entity.id` is the right field
const categoriesAdapter = createEntityAdapter({
	selectId: category => category.comment_category_id
})

export const {
	selectAll: selectCommentCategories,
	selectEntities: selectCommentCategoryEntities,
	selectById: selectCommentCategoryByRowId
} = categoriesAdapter.getSelectors(state => state.formsApp.commentCategories)

const commentCategoriesSlice = createSlice({
	name: 'formsApp/commentCategories',
	initialState: categoriesAdapter.getInitialState({}),
	reducers: {},
	extraReducers: {
		[getCommentCategories.fulfilled]: (state, action) => {
			categoriesAdapter.setAll(state, action.payload)
		}
	}
})

export default commentCategoriesSlice.reducer
