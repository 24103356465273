//
// Reducer
//
import { combineReducers } from '@reduxjs/toolkit'
import cellHistory from './cellHistory/cellHistorySlice'
import commentCategories from './comments/commentCategoriesSlice'
import commentDepartments from './comments/commentDepartmentsSlice'
import comments from './comments/commentsSlice'
import notifiers from './comments/notifiersSlice'
import department from './departments/departmentSlice'
import departments from './departments/departmentsSlice'
import files from './files/filesSlice'
import form from './forms/formSlice'
import forms from './forms/formsSlice'
import generic from './forms/genericSlice'
import info from './forms/infoSlice'
import multiForm from './forms/multiFormSlice'
import personal from './forms/personalSlice'
import prototype from './prototypes/prototypeSlice'
import prototypes from './prototypes/prototypesSlice'
import allProviders from './providers/allProvidersSlice'
import provider from './providers/providerSlice'
import providers from './providers/providersSlice'
import storage from './storage/storageSlice'
import groups from './userGroups/groupsSlice'
import users from './userGroups/usersSlice'

// CombineReducers will be handled internally by configureStore
const reducer = combineReducers({
	form,
	forms,
	multiForm,
	departments,
	department,
	providers,
	allProviders,
	provider,
	prototypes,
	prototype,
	personal,
	info,
	users,
	groups,
	comments,
	commentCategories,
	commentDepartments,
	cellHistory,
	notifiers,
	files,
	generic,
	storage
})

export default reducer
