import { combineReducers } from '@reduxjs/toolkit'
import shortcuts from './dashboardSlice'
import projectsForecasts from './forecastsSlice.js'
import form_shortcuts from './formsSlice'
import projects from './projectsSlice'

const reducer = combineReducers({
	shortcuts,
	form_shortcuts,
	projects,
	projectsForecasts
})

export default reducer
