import { useForm } from '@fuse/hooks'
import { DialogTitle, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControlSelection from '../forms/form/components/FormControlSelection'
import { addProjectForecast, closeForecastDialog } from './store/forecastsSlice'
import { selectProjects } from './store/projectsSlice'

const defaultFormState = {
	project_id: 0
}

const ForecastDialog = () => {
	const dispatch = useDispatch()

	// eslint-disable-next-line no-shadow
	const projects = useSelector(selectProjects)
	// eslint-disable-next-line no-shadow
	// const projectsForecasts = useSelector(selectProjectsForecasts)

	const forecastDialog = useSelector(({ dashboardApp }) => dashboardApp.projectsForecasts.dialog)

	const { form, handleChange, setForm } = useForm({ ...defaultFormState })

	const closeDialog = () => {
		return dispatch(closeForecastDialog())
	}

	const canBeSubmitted = () => {
		return form.project_id > 0
	}

	const initDialog = useCallback(() => {
		setForm({
			...defaultFormState
		})
	}, [setForm]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		/**
		 * After Dialog Open
		 */
		if (forecastDialog.open) {
			initDialog()
		}
	}, [forecastDialog.open, initDialog])

	return (
		<Dialog onClose={closeDialog} fullWidth maxWidth="sm" open={forecastDialog.open}>
			<DialogTitle id="simple-dialog-title">Add Project Forecast Item</DialogTitle>
			<DialogContent classes={{ root: 'p-0' }}>
				<div className="flex flex-wrap w-full px-12 sm:px-20 mb-16" />
				<div className="px-16 sm:px-24">
					<FormControlSelection
						title="Project"
						name="project_id"
						value={form.project_id}
						onChange={handleChange}
						placeholder="-- Select Project --"
						items={projects.map(project => {
							return (
								<MenuItem key={project.project_id} value={project.project_id}>
									{project.project_name_int}
								</MenuItem>
							)
						})}
					/>
				</div>
			</DialogContent>
			<DialogActions className="justify-between p-8">
				<div className="px-16">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							dispatch(addProjectForecast(form)).then(() => {
								closeDialog()
							})
						}}
						disabled={!canBeSubmitted()}
					>
						Add
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	)
}

export default ForecastDialog
