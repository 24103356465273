import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectProjectById } from './store/projectsSlice'

const avgTemp = forecast => {
	return parseFloat((forecast.min_temp + forecast.max_temp) / 2).toFixed(1)
}

const Forecasts = props => {
	const { project_id, forecasts } = props
	let icon = forecasts[0].icon.padStart(2, '0')
	const project = useSelector(state => selectProjectById(state, project_id))
	return (
		<Paper className="w-full rounded-8 shadow">
			<div className="flex items-center justify-between px-16 pt-16">
				<Icon color="action">location_on</Icon>
				<Typography className="text-16 mx-8">{project.project_name_int}</Typography>

				{/* <Select
					native
					value={0}
					inputProps={{
						name: 'currentRange'
					}}
					disableUnderline
				>
					<option value={0}>Today</option>
				</Select> */}
			</div>
			<div className="flex items-center justify-center p-16 pb-32">
				<img
					alt="Weather"
					src={`https://apidev.accuweather.com/developers/Media/Default/WeatherIcons/${icon}-s.png`}
				/>
				<Typography className="text-44 mx-8" color="textSecondary">
					{avgTemp(forecasts[0])}
				</Typography>
				<Typography className="text-48 font-300" color="textSecondary">
					°
				</Typography>
				<Typography className="text-44 font-300" color="textSecondary">
					C
				</Typography>
			</div>
			<Divider />
			<div className="flex justify-between items-center p-16">
				<div className="flex items-center">
					<Typography className="text-14 mx-4" color="textSecondary">
						{forecasts[0].min_temp}
					</Typography>
					<Typography className="text-16 font-300" color="textSecondary">
						°
					</Typography>
					<Typography className="text-14 font-300" color="textSecondary">
						C
					</Typography>
				</div>

				{forecasts[0].has_precipitation && (
					<div className="flex items-center">
						<Icon className="meteocons text-14" color="action">
							rainy
						</Icon>
						<Typography className="text-14 mx-4">{forecasts[0].precipitation_probability} %</Typography>
					</div>
				)}

				<div className="flex items-center">
					<Typography className="text-14 mx-4" color="textSecondary">
						{forecasts[0].max_temp}
					</Typography>
					<Typography className="text-16 font-300" color="textSecondary">
						°
					</Typography>
					<Typography className="text-14 font-300" color="textSecondary">
						C
					</Typography>
				</div>
			</div>

			<Divider />

			<div className="w-full py-16">
				{forecasts.slice(1).map(forecast => {
					const dayText = new Date(forecast.from_datetime).toLocaleString('en-us', { weekday: 'long' })
					icon = forecast.icon.padStart(2, '0')

					return (
						<div className="flex items-center justify-between w-full py-16 px-24">
							<Typography className="text-10">{dayText}</Typography>

							<div className="flex items-center">
								<img
									width="50"
									alt="Weather"
									src={`https://apidev.accuweather.com/developers/Media/Default/WeatherIcons/${icon}-s.png`}
								/>
								<Typography className="text-14">{avgTemp(forecast)}</Typography>
								<Typography className="text-14" color="textSecondary">
									&deg;
								</Typography>
								<Typography className="text-14" color="textSecondary">
									C
								</Typography>
							</div>

							<div className="flex items-center">
								{forecast.has_precipitation && (
									<>
										<Typography className="mx-4">{forecast.precipitation_probability}</Typography>
										<Typography className="text-14" color="textSecondary">
											%
										</Typography>
									</>
								)}
								{!forecast.has_precipitation && <>&nbsp;</>}
							</div>
						</div>
					)
				})}
			</div>
		</Paper>
	)
}

export default Forecasts
