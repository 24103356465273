//
// helper.js
//
export const downloadBlob = (blob, filename, ext) => {
	// Create an object URL for the blob object
	console.info(GetMineType(ext))
	const url = URL.createObjectURL(new Blob([blob], { type: `${GetMineType(ext)}` }))

	// Create a new anchor element
	const a = document.createElement('a')

	// Set the href and download attributes for the anchor element
	// You can optionally set other attributes like `title`, etc
	// Especially, if the anchor element will be attached to the DOM
	a.href = url
	a.download = filename || 'download'

	// Click handler that releases the object URL after the element has been clicked
	// This is required for one-off downloads of the blob content
	a.click()
}

export function formatISO8601(date, skipZeroTime, skipTimezone) {
	var ret = []

	var pad = function (n) {
		if (n < 10) return '0'.concat(n)
		return String(n)
	}

	var isZeroTime = function () {
		return date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() < 1
	}

	ret.push(date.getFullYear())
	ret.push('-')
	ret.push(pad(date.getMonth() + 1))
	ret.push('-')
	ret.push(pad(date.getDate()))

	if (!(skipZeroTime && isZeroTime())) {
		ret.push('T')
		ret.push(pad(date.getHours()))
		ret.push(':')
		ret.push(pad(date.getMinutes()))
		ret.push(':')
		ret.push(pad(date.getSeconds()))

		if (date.getMilliseconds()) {
			ret.push('.')
			ret.push(date.getMilliseconds())
		}

		if (!skipTimezone) ret.push('Z')
	}

	return ret.join('')
}

// for mime type you can also use any package
const GetMineType = extension => {
	switch (extension.toLowerCase()) {
		case 'xls':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		case 'csv':
			return 'text/csv'
		case 'cur':
			return 'application/octet-stream'
		case 'cxx':
			return 'text/plain'
		case 'dat':
			return 'application/octet-stream'
		case 'datasource':
			return 'application/xml'
		case 'dbproj':
			return 'text/plain'
		case 'dcr':
			return 'application/x-director'
		case 'docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		case 'dot':
			return 'application/msword'
		case 'jpb':
			return 'application/octet-stream'
		case 'jpe':
			return 'image/jpeg'
		case 'jpeg':
			return 'image/jpeg'
		case 'jpg':
			return 'image/jpeg'
		default:
			return null
		//etc ......
	}
}

const descendingComparator = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

export const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

export const getComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

// ====== dynamic if ==========
export const compare = (post, operator, value) => {
	switch (operator) {
		case '>':
			return post > value
		case '<':
			return post < value
		case '>=':
			return post >= value
		case '<=':
			return post <= value
		case '=':
			return post === value
		case '!=':
			return post !== value
		case '===':
			return post === value
		case '!==':
			return post !== value
		case '<>':
			return post !== value
		default:
			return
	}
}

/**
 * Check if value is date
 */
const isDateFormat = value => {
	return !isNaN(Date.parse(value)) && new Date(Date.parse(value)).toISOString() === value
}

/**
 * Add day to date
 */
const addOneDay = (date = new Date()) => {
	date.setDate(date.getDate() + 1)

	return date
}

// ===== Check if value has date format =====
export const makeFilter = (arg, filter) => {
	if (filter[1] === 'between') {
		if (arg.mode && (arg.mode === 'view' || arg.mode === 'custom')) {
			filter = [
				[filter[0], '>=', formatISO8601(new Date(filter[2][0]))],
				[filter[0], '<', formatISO8601(new Date(filter[2][1]))]
			]
		} else {
			filter[2] = [new Date(filter[2][0]), new Date(filter[2][1])]
		}
	}

	if (isDateFormat(filter[2])) {
		if (arg.mode && arg.mode === 'view') {
			if (filter[1] === '=') {
				// Rebuild filter in between
				filter = [
					[filter[0], '>=', formatISO8601(new Date(filter[2]))],
					[filter[0], '<', formatISO8601(addOneDay(new Date(filter[2])))]
				]
			}
		} else {
			filter[2] = new Date(filter[2])
		}
	}

	return filter
}

// ===== Override filter values =====
export const formatDate = (arg, filters) => {
	filters = filters && filters.length ? [...filters] : null

	if (filters) {
		if (typeof filters[0] === 'object') {
			const newFilters = []
			filters.forEach(filter => {
				newFilters.push(makeFilter(arg, filter))
			})
			filters = newFilters
		} else {
			filters = makeFilter(arg, filters)
		}
	}

	return filters
}
