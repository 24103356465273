import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getFormsByDepartment = createAsyncThunk(
	'formsApp/department/getForms',
	async (params, { dispatch, getState }) => {
		const { providerID, departmentID } = params

		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Department/forms`, {
			provider: providerID,
			department: departmentID
		})

		return await response.data.data
	}
)

const departmentAdapter = createEntityAdapter({
	// Assume IDs are stored in a field other than `form.form_id`
	selectId: form => form.form_id ?? form.multi_form_id
})

export const {
	selectAll: selectFormsByDepartment,
	selectEntities: selectFormsEntities,
	selectById: selectFormById
} = departmentAdapter.getSelectors(state => state.formsApp.department)

/**
 * Slice Configuration.
 */
const departmentSlice = createSlice({
	// A name, used in action types
	name: 'formsApp/department',
	// The initial state for the reducer
	initialState: departmentAdapter.getInitialState({
		loading: true,
		departmentID: null,
		providerID: null
	}),
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {
		updateFormInDepartment: (state, action) => {
			departmentAdapter.updateOne(state, action.payload)
		},
		deleteFormInDepartment: (state, action) => {
			departmentAdapter.removeOne(state, action.payload.id)
		},
		reloadDepartment: state => {
			departmentAdapter.setAll(state, [])
		}
	},
	// A "builder callback" function used to add more reducers, or
	// an additional object of "case reducers", where the keys should be other
	// action types
	extraReducers: {
		[getFormsByDepartment.pending]: (state, action) => {
			const { departmentID, providerID } = action.meta.arg
			state.departmentID = departmentID
			state.providerID = providerID
			state.loading = true
		},
		[getFormsByDepartment.fulfilled]: (state, action) => {
			departmentAdapter.setAll(state, action.payload)
			state.loading = false
		}
	}
})

export const { loading, reloadDepartment, updateFormInDepartment, deleteFormInDepartment } = departmentSlice.actions

export default departmentSlice.reducer
