//
// departmentsSlice.js
//
import axios from 'axios'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

// eslint-disable-next-line no-empty-pattern
export const getDepartments = createAsyncThunk(
	'formsApp/departments/getDepartments',
	async (params, { dispatch, getState }) => {
		const cached = getState().formsApp.departments
		if (Object.keys(cached.entities).length > 0) {
			return cached.entities
		}
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Departments/departments`)
		return await response.data.data
	}
)

const departmentsAdapter = createEntityAdapter({
	// assume IDs are stored in a field other than `department.department_id`
	selectId: department => department.department_id
})

export const {
	selectAll: selectDepartments,
	selectEntities: selectPrototypesEntities,
	selectById: selectDepartmentById
} = departmentsAdapter.getSelectors(state => state.formsApp.departments)

const departmentsSlice = createSlice({
	name: 'formsApp/departments',
	initialState: departmentsAdapter.getInitialState({}),
	reducers: {
		reloadDepartments: (state, action) => {
			departmentsAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getDepartments.fulfilled]: departmentsAdapter.setAll
	}
})

// eslint-disable-next-line no-empty-pattern
export const { reloadDepartments } = departmentsSlice.actions

export default departmentsSlice.reducer
