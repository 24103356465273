//
// Authorization.js
//

import FuseUtils from '@fuse/utils'
import AppContext from 'app/AppContext'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { matchRoutes } from 'react-router-config'
import { withRouter } from 'react-router-dom'
import navigationConfig from 'app/fuse-configs/navigationConfig'

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props)
		const { routes } = context
		this.state = {
			accessGranted: true,
			routes
		}
	}

	async componentDidMount() {
		if (!this.state.accessGranted) {
			this.redirectRoute()
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted
	}

	async componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute()
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole, access, nav } = props
		const { pathname } = location

		const matched = matchRoutes(state.routes, pathname)[0]
		const navRoles = matched && matched.route.auth ? matched.route.auth : []

		const parts = pathname.split('/')
		const baseParts = []

		// eslint-disable-next-line no-restricted-syntax
		for (const i in parts) {
			if (i <= 2) {
				baseParts.push(parts[i])
			}
		}

		/*
		 * Rebuild Path
		 */
		const basePath = baseParts.join('/')

		/*
		 * Search Permissions by Route like
		 * navRoles[/dashboard]
		 */
		const setAccess = data => {
			for (const i in data) {
				const item = data[i]
				if (item.url && item.url.indexOf(basePath) !== -1) {
					const index = access.nav.findIndex(nav => nav.menu_item_id === item.id)
					if (index !== -1) {
						navRoles.push(access.nav[index].user_group_id)
					}
				}
				if (item.children) {
					setAccess(item.children)
				}
			}
		}
		setAccess(nav)

		return {
			accessGranted: matched ? FuseUtils.hasPermission(navRoles, userRole) : true
		}
	}

	redirectRoute() {
		const { location, userRole, history } = this.props
		const { state } = location
		const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/dashboard'

		/**
		 * User is guest
		 * Redirect to Login Page
		 */
		if (!userRole || userRole.length === 0) {
			/**
			 *	history.push({
			 *	  pathname: '/login',
			 *	  state: { redirectUrl: pathname }
			 *  });
			 */
			window.location.href = `${process.env.REACT_APP_ID_URL}/identifier/pulse`
		} else {
			/**
			 * User is member
			 * User must be on unAuthorized page or just logged in
			 * Redirect to dashboard or redirectUrl
			 */
			history.push({
				pathname: redirectUrl
			})
		}
	}

	render() {
		console.info('Fuse Authorization rendered', this.state.accessGranted)
		return this.state.accessGranted ? <>{this.props.children}</> : null
	}
}

const mapStateToProps = ({ auth }) => {
	return {
		userRole: auth.user.role,
		access: auth.access,
		nav: navigationConfig
	}
}

FuseAuthorization.contextType = AppContext

export default withRouter(connect(mapStateToProps)(FuseAuthorization))
