import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable } from 'react-beautiful-dnd'
import { Card, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import { openEditShortcutDialog, removeShortcut } from './store/dashboardSlice'
import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup'

const useStyles = makeStyles(theme => ({
	board: {
		transitionProperty: 'box-shadow border-color',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		background: theme.palette.primary.dark,
		color: theme.palette.getContrastText(theme.palette.primary.dark)
	}
}))

const Shortcut = props => {
	const dispatch = useDispatch()
	const classes = useStyles(props)

	const [anchorEl, setAnchorEl] = useState(null)

	const handleMenuClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<Draggable draggableId={`id-${props.shortcut.id}`} index={props.index} type="list">
			{(provided, snapshot) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<FuseAnimateGroup
						enter={{
							animation: 'transition.slideUpBigIn'
						}}
					>
						<div className="w-192 h-192 p-16">
							<Card
								className={clsx(
									classes.board,
									snapshot.isDragging ? 'shadow-lg' : 'shadow',
									'flex flex-col items-center justify-center w-full h-full rounded-8 py-24 shadow hover:shadow-lg relative'
								)}
								{...provided.dragHandleProps}
								square
							>
								<div className="absolute top-0 left-0 w-full">
									<div className="flex items-center justify-between h-52 px-8">
										<div className="text-16 font-600 cursor-pointer" />
										<div>
											<IconButton
												aria-owns={anchorEl ? 'actions-menu' : null}
												aria-haspopup="true"
												onClick={handleMenuClick}
												variant="outlined"
												size="small"
											>
												<Icon className="text-20">more_vert</Icon>
											</IconButton>
											<Menu
												id="actions-menu"
												anchorEl={anchorEl}
												open={Boolean(anchorEl)}
												onClose={handleMenuClose}
											>
												<MenuItem
													onClick={ev => {
														ev.preventDefault()
														dispatch(openEditShortcutDialog(props.shortcut))
														setAnchorEl(null)
													}}
												>
													<ListItemIcon className="min-w-40">
														<Icon>edit</Icon>
													</ListItemIcon>
													<ListItemText primary="Edit" />
												</MenuItem>
												<MenuItem
													onClick={() => {
														dispatch(removeShortcut({ id: props.shortcut.id })).then(() => {
															setAnchorEl(null)
														})
													}}
												>
													<ListItemIcon className="min-w-40">
														<Icon>delete</Icon>
													</ListItemIcon>
													<ListItemText primary="Remove" />
												</MenuItem>
											</Menu>
										</div>
									</div>
								</div>

								<CardContent className={clsx('flex flex-col justify-center w-full h-full')}>
									<Link
										to={props.shortcut.link}
										className={clsx('flex flex-col items-center justify-center w-full h-full')}
										role="button"
									>
										<Icon className="text-56">article</Icon>
										<Typography
											className="text-16 font-300 text-center pt-16 px-32"
											color="inherit"
										>
											{props.shortcut.title}
										</Typography>
									</Link>
								</CardContent>
							</Card>
						</div>
					</FuseAnimateGroup>
				</div>
			)}
		</Draggable>
	)
}

export default Shortcut
