//
// FormsAppConfig.js
//
import FormAppConfig from './form/FormAppConfig'
import GenericConfigs from './generic/GenericConfigs'
import MultiFormAppConfig from './multi/MultiFormAppConfig'
import SpecificConfigs from './specific/SpecificConfigs'

const FormConfigs = [FormAppConfig, MultiFormAppConfig, ...SpecificConfigs, ...GenericConfigs]

export default FormConfigs
