//
// LayoutFormSelection.js
//
import { MenuItem } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControlSelection from '../form/components/FormControlSelection'
import { setLayoutFormSelection } from '../store/forms/formSlice'

const LayoutFormSelection = props => {
	const dispatch = useDispatch()
	// eslint-disable-next-line no-shadow
	const formData = useSelector(({ formsApp }) => formsApp.form)

	return (
		<FormControlSelection
			title={props.title}
			name={props.id}
			value={formData.layout_forms[props.id]?.id ?? 0}
			onChange={e => {
				dispatch(setLayoutFormSelection({ id: props.id, val: e.target.value }))
			}}
			placeholder="-- Select Form --"
			items={props.forms.map(form => {
				return (
					<MenuItem key={form.form_id} value={form.form_id}>
						{form.form_name}
					</MenuItem>
				)
			})}
		/>
	)
}

export default LayoutFormSelection
