//
// DepartmentApp.js
//
import withReducer from 'app/store/withReducer'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FusePageSimple from '@fuse/core/FusePageSimple'
import { useParams } from 'react-router-dom'
import { createPath } from 'app/store/breadcrumbSlice'
import { showTabs } from 'app/store/settingsSlice'
import { useDeepCompareEffect } from '@fuse/hooks'
import { humanize } from 'app/Functions'
import Forms from './Forms'
import reducer from '../../store'
import { getFormsByDepartment, selectFormsByDepartment } from '../../store/departments/departmentSlice'
import { getProviders, selectProviderById } from '../../store/providers/providersSlice'
import { getDepartments, selectDepartmentById } from '../../store/departments/departmentsSlice'

const DepartmentApp = () => {
	const dispatch = useDispatch()
	const routeParams = useParams()
	const { folder } = useParams()
	const pageLayout = useRef(null)

	const { providerID, departmentID } = routeParams

	// eslint-disable-next-line no-shadow
	const formsByDepartment = useSelector(selectFormsByDepartment)
	// eslint-disable-next-line no-shadow
	const selectedDepartmentID = useSelector(({ formsApp }) => formsApp.department.departmentID)
	// eslint-disable-next-line no-shadow
	const selectedProviderID = useSelector(({ formsApp }) => formsApp.department.providerID)
	// eslint-disable-next-line no-shadow
	const tabsEnabled = useSelector(({ settings }) => settings.showTabs)
	// eslint-disable-next-line no-shadow
	const provider = useSelector(state => selectProviderById(state, providerID))
	// eslint-disable-next-line no-shadow
	const department = useSelector(state => selectDepartmentById(state, departmentID))

	useDeepCompareEffect(() => {
		dispatch(getProviders())
		dispatch(getDepartments())
		if (!formsByDepartment.length || selectedDepartmentID !== departmentID || selectedProviderID !== providerID) {
			dispatch(getFormsByDepartment(routeParams))
		}
	}, [dispatch])

	useEffect(() => {
		/**
		 * Building Breadcrumb.
		 */
		dispatch(
			createPath({
				id: 'root',
				title: 'Pulse',
				type: 'group',
				icon: 'star',
				children: [
					{
						id: `forms`,
						title: 'Forms',
						type: 'item',
						url: `/forms/${folder}`,
						tab: -1, // apply to children
						children: [
							{
								id: `forms`,
								title: humanize(folder),
								type: 'item',
								url: `/forms/${folder}`,
								children: [
									{
										id: `provider`,
										title: provider ? provider.provider_name : null,
										type: 'item',
										url: `/forms/${folder}/provider/${providerID}`,
										children: [
											{
												id: `provider`,
												title: department ? department.department_name : null,
												type: 'item',
												url: `/forms/${folder}/provider/${providerID}/department/${departmentID}`
											}
										]
									}
								]
							}
						]
					}
				]
			})
		)
		if (!tabsEnabled) {
			dispatch(showTabs())
		}
	}, [dispatch, folder, provider, department, tabsEnabled, departmentID, providerID])

	return (
		<FusePageSimple
			classes={{
				contentWrapper: 'p-0 min-h-0',
				content: 'flex flex-col min-h-0 h-full',
				wrapper: 'min-h-0'
			}}
			content={<Forms pageLayout={pageLayout} />}
			leftSidebarVariant="temporary"
			ref={pageLayout}
			innerScroll
		/>
	)
}

export default withReducer('formsApp', reducer)(DepartmentApp)
