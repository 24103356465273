//
// infoSlice.js
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { getGenericData } from './genericSlice'

export const getPrototypeData = createAsyncThunk(
	'formsApp/form/getPrototypeData',
	async (params, { dispatch, getState }) => {
		const { prototype_id } = params
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Prototypes/${prototype_id}/info`)
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

const infoSlice = createSlice({
	name: 'formsApp/info',
	initialState: {
		loading: false,
		config: {}
	},
	reducers: {
		loaded: (state, action) => {
			state.loading = true
		},
		setConfiguration: (state, action) => {
			state.config.config = action.payload
		},
		setAttributes: (state, action) => {
			state.config.attributes = action.payload
		}
	},
	extraReducers: {
		[getGenericData.pending]: (state, action) => {
			state.loading = false
		},
		[getPrototypeData.pending]: (state, action) => {
			return { ...state }
		},
		[getPrototypeData.fulfilled]: (state, action) => {
			const { config, attributes } = action.payload

			const columns = []

			if (action.meta.arg.attributes) {
				for (const i in action.meta.arg.attributes) {
					const attribute = action.meta.arg.attributes[i]
					if (!attribute.dataField.includes('_displayname') && attribute.dataField !== 'rowguid') {
						columns.push(attribute)
					}
				}
			} else {
				for (const i in attributes) {
					const attribute = attributes[i]
					// ===== ignore for display names ======
					if (!attribute.dataField.includes('_displayname') && attribute.dataField !== 'rowguid') {
						columns.push(attribute)
					}
				}
			}

			state.config = {
				config: config,
				attributes: columns
			}

			state.loading = true
		}
	}
})

export const { loaded, setConfiguration, setAttributes } = infoSlice.actions

export default infoSlice.reducer
