//
// SpecificConfig.js
//
import ProvidersConfig from './providers/ProvidersConfig'
import ProviderConfig from './provider/ProviderConfig'
import DepartmentConfig from './department/DepartmentConfig'

const SpecificConfigs = [DepartmentConfig, ProviderConfig, ProvidersConfig]

export default SpecificConfigs
