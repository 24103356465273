//
// Auth.js
//
import FuseSplashScreen from '@fuse/core/FuseSplashScreen'
import Maintenance from 'app/maintenance'
// import history from '@history'
import { bindActionCreators } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { socketGet } from 'app/SocketContext'
import { getNotifications } from 'app/main/notifications/store/notificationsSlice'
import apiService from 'app/services/apiService'
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice'
import axios from 'axios'
import Promise from 'lodash/_Promise'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import pulseApiService from '../services/pulseApiService/pulseApiService'
import { setAccessData, setNavAccess } from './store/accessSlice'
import { logoutUser, setUserData } from './store/userSlice'

let socket = socketGet()
class Auth extends Component {
	state = {
		waitAuthCheck: true,
		maintenance: false,
		// eslint-disable-next-line react/no-unused-state
		redirect: false
	}

	componentDidMount() {
		this.socketAuthCheck()
		return Promise.all([this.apiCheck()]).then(() => {
			this.setState({ waitAuthCheck: false })
		})
	}

	/**
	 * Socket IO
	 */
	socketAuthCheck = () => {
		socket.on('connect', () => {
			console.info('Socket connected', socket.id)
			axios.defaults.headers.common['X-Socket-Id'] = socket.id
		})
		socket.on('disconnect', () => {
			console.info('Socket disconnected')
		})
		socket.on('connect_error', error => {
			console.error('Socket error', error)
		})
		socket.on('sentry_error', payload => {
			const { event_id } = payload
			Sentry.setTag('backend.event', event_id)
		})
		socket.on('maintenance', payload => {
			this.setState({ maintenance: true })
		})
		socket.on('event', payload => {
			const { content } = payload
			const { message, type, data } = JSON.parse(content)
			if (data) {
				const { datatype, url, user_id } = data
				// specify
				if (user_id) {
					if (parseInt(user_id) === apiService.userID) {
						this.notify(type, message, datatype, url)
					}
					return
				}
				// all users
				this.notify(type, message, datatype, url)
			}
		})
	}

	/**
	 * System notification
	 */
	notify = (type, message, datatype, url) => {
		const { showMessage, getNotifications } = this.props
		// download file
		if (datatype && datatype === 'download') {
			const element = document.createElement('a')
			element.setAttribute('href', url)
			// element.setAttribute('target', '_blank')
			element.style.display = 'none'
			document.body.appendChild(element)
			socket.disconnect()
			element.click()
			document.body.removeChild(element)
			socket.connect()
		}
		// reload notifications
		getNotifications()
		// fuse default message
		const deploy = datatype && datatype === 'deploy'
		const autoHideDuration = deploy ? null : 6000 // ms
		if (deploy) {
			this.setState({ maintenance: false })
		}
		showMessage({
			variant: type,
			message: message,
			autoHideDuration: autoHideDuration,
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'right'
			}
		})
	}

	apiCheck = () =>
		new Promise(resolve => {
			const { pathname } = window.location

			apiService.on('onAutoLogin', () => {
				/**
				 * Sign in and retrieve user data from Api
				 */
				apiService
					.signInWithToken()
					.then(user => {
						const role = []

						if (user.IsActive === false) {
							apiService.logout({ error: 'Unauthorized' })
						}

						const curSocket = socketGet()
						if (socket.id !== curSocket.id) {
							socket = curSocket
							this.socketAuthCheck()
						}

						apiService.setUserID(user.UserID)

						Sentry.setUser({
							id: user.UserID,
							username: user.Username
						})

						/**
						 * Set User Roles
						 */
						for (const i in user.group_members) {
							const group = user.group_members[i]

							role.push(group.user_group_id)
						}

						/**
						 * Set Default Access
						 * [dashboard, profile]
						 */
						role.push('guest')

						/**
						 * User Authorization
						 */
						const userData = {
							role,
							data: {
								displayName: `${user.FirstName} ${user.LastName}`,
								firstName: user.FirstName,
								lastName: user.LastName,
								fathersName: user.FathersName,
								userName: user.Username,
								jobTitle: user.JobTitle,
								startDate: user.EmploymentStartDate,
								birthDate: user.BirthDate,
								report_to_full_name: user.report_to_full_name,
								photoURL: '',
								email: user.contact_details !== null ?? user.contact_details.Email,
								phone: true ?? user.contact_details.Phone,
								type: true ?? user.contact_details.Type,
								country: true ?? user.contact_details.Country,
								groups: user.group_members,
								apps: user.applications,
								settings: {},
								id: user.UserID,
								shortcuts: []
							}
						}

						/**
						 * Set Access Token
						 */
						this.props.setUserData(userData)

						/**
						 * Get Nav Permissions Data
						 */
						pulseApiService
							.setPermissions()
							.then(data => {
								/**
								 * Nav Permissions
								 */
								this.props.setNavAccess(data.groups)

								resolve()
							})
							.catch(error => {
								// ..do something when nav permissions is not running
								// this.props.showMessage({ message: 'Menu Permissions Error'})

								resolve()
							})
					})
					.catch(error => {
						this.props.showMessage({ message: error.message })
						resolve()
					})
			})

			apiService.on('onWelcome', () => {
				this.props.showMessage({ message: 'Welcome To Pulse' })
			})

			apiService.on('onAutoLogout', message => {
				if (!pathname.includes('auth/')) {
					if (message) {
						this.props.showMessage({ message })
					}
				}
				resolve()
			})

			apiService.on('onNoAccessToken', () => {
				resolve()
			})

			apiService.init()

			return Promise.resolve()
		})

	render() {
		if (this.state.maintenance) {
			return <Maintenance />
		}
		if (this.state.waitAuthCheck) {
			return <FuseSplashScreen />
		}
		return this.props.children
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			showMessage,
			getNotifications,
			hideMessage,
			setAccessData,
			setNavAccess
		},
		dispatch
	)
}

export default connect(null, mapDispatchToProps)(Auth)
