import { createSlice } from '@reduxjs/toolkit'

const settingsSlice = createSlice({
	name: 'settingsApp',
	initialState: {
		showTabs: false,
		notifications: false
	},
	reducers: {
		showTabs: (state, action) => {
			state.showTabs = true
		},
		hideTabs: (state, action) => {
			state.showTabs = false
		},
		toggleNotifications: (state, action) => {
			state.notifications = !state.notifications
		}
	}
})

export const { showTabs, hideTabs, toggleNotifications } = settingsSlice.actions

export default settingsSlice.reducer
