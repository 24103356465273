import React from 'react'
import ContentLoader from 'react-content-loader'

// eslint-disable-next-line import/prefer-default-export
export const Loader = props => {
	const random = Math.random() * (1 - 0.7) + 0.7
	return (
		<ContentLoader height={55} width={1060} speed={2} {...props} backgroundColor="#E8E8E8">
			<rect x="64" y="13" rx="6" ry="6" width={200 * random} height="12" />
			<rect x="643" y="13" rx="6" ry="6" width={23 * random} height="12" />
			<rect x="683" y="13" rx="6" ry="6" width={78 * random} height="12" />
			<rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
		</ContentLoader>
	)
}

export const BreadcrumbLoader = props => {
	const random = Math.random() * (1 - 0.7) + 0.7
	if (props.loading) {
		return (
			<ContentLoader speed={4} width={400} height={24} viewBox="0 0 400 24" {...props} backgroundColor="#E8E8E8">
				<rect x="9" y="5" rx="5" ry="5" width={86 * random} height="15" />
				<rect x="100" y="5" rx="5" ry="5" width={10 * random} height="15" />
				<rect x="112" y="5" rx="5" ry="5" width={60 * random} height="15" />
				<rect x="176" y="5" rx="5" ry="5" width={10 * random} height="15" />
				<rect x="188" y="5" rx="5" ry="5" width={38 * random} height="15" />
			</ContentLoader>
		)
	}
	return <>{props.children}</>
}

// eslint-disable-next-line no-shadow
export const TableLoader = props => {
	const length = props.length ? props.length : 3
	// eslint-disable-next-line no-undef
	if (props.loading) {
		return (
			<div style={{ marginTop: 40 }}>
				{Array(length)
					.fill('')
					.map((e, i) => (
						<Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
					))}
			</div>
		)
	}
	return <>{props.children}</>
}

export const Tab = () => {
	return (
		<ContentLoader>
			<rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
		</ContentLoader>
	)
}

export const TabLoader = props => {
	const length = props.length ? props.length : 3

	// eslint-disable-next-line no-undef
	if (props.loading) {
		return (
			<div>
				{Array(length)
					.fill('')
					.map((e, i) => (
						<Tab key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
					))}
			</div>
		)
	}

	return <>{props.children}</>
}
