//
// FormModel.js
//
class PersonalFormModel {
	constructor(parent, form, user) {
		this.form_id = parent.form_id
		this.user_id = user.data.id // <-- locked to user
		this.personal_form_name = form.title
		this.personal_form_settings = JSON.stringify({
			fields: parent.selectedFields,
			filters: parent.calculatedExtraFilters,
			editFilters: parent.extraFilters,
			builderFilters: parent.builderFilters,
			groups: parent.fieldGroups,
			sortOrder: parent.sortOrderField,
			sortOrderValue: parent.sortOrderValue
		})
	}
}

export default PersonalFormModel
