import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getFormsByPrototype = createAsyncThunk(
	'formsApp/prototype/getForms',
	async (params, { dispatch, getState }) => {
		dispatch(loading(params))

		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Prototype/forms`, params)

		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

const prototypeAdapter = createEntityAdapter({
	// ..assume IDs are stored in a field other than `prototype.prototype_id`
	selectId: form => form.form_id
})

export const getPrototype = createAsyncThunk(
	'formsApp/prototype/getPrototype',
	async (prototype, { dispatch, getState }) => {
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Prototype/get/${prototype}`)

		dispatch(setPrototype({ prototype: response.data, provider: '' }))
		// return await response.data
	}
)

export const {
	selectAll: selectFormsByPrototype,
	selectEntities: selectFormsEntities,
	selectById: selectFormById
} = prototypeAdapter.getSelectors(state => state.formsApp.prototype)

const prototypeSlice = createSlice({
	name: 'formsApp/prototype',
	initialState: prototypeAdapter.getInitialState({
		loading: true,
		id: null,
		prototype: {}
	}),
	reducers: {
		loading: (state, action) => {
			state.loading = true
			state.id = action.payload.prototype
		},
		setPrototype: (state, action) => {
			state.prototype = action.payload.prototype
		},
		reloadPrototype: (state, action) => {
			prototypeAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getFormsByPrototype.fulfilled]: (state, action) => {
			prototypeAdapter.setAll(state, action.payload)
			state.loading = false
		}
	}
})

export const { loading, setPrototype, reloadPrototype } = prototypeSlice.actions

export default prototypeSlice.reducer
