import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useForm } from '@fuse/hooks'
import { addShortcut, closeShortcutDialog, updateShortcut } from './store/dashboardSlice'
import { DialogTitle } from '@material-ui/core'

const defaultFormState = {
	title: '',
	link: '/'
}

const ShortcutDialog = () => {
	const dispatch = useDispatch()

	const shortcutDialog = useSelector(({ dashboardApp }) => dashboardApp.shortcuts.dialog)
	const isGroupAdding = useSelector(({ dashboardApp }) => dashboardApp.shortcuts.isGroupAdding)

	const { form, handleChange, setForm } = useForm({ ...defaultFormState })

	const closeDialog = () => {
		return dispatch(closeShortcutDialog())
	}

	const canBeSubmitted = () => {
		return form.title.length > 0
	}

	const initDialog = useCallback(() => {
		/**
		 * Dialog type: 'edit'
		 */
		if (shortcutDialog.type === 'edit' && shortcutDialog.data) {
			setForm({ ...shortcutDialog.data })
		}

		/**
		 * Dialog type: 'new'
		 */
		if (shortcutDialog.type === 'new') {
			setForm({
				...defaultFormState
			})
		}
	}, [shortcutDialog.data, setForm]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		/**
		 * After Dialog Open
		 */
		if (shortcutDialog.open) {
			initDialog()
		}
	}, [shortcutDialog.open, initDialog])

	return (
		<Dialog onClose={closeDialog} fullWidth maxWidth="sm" open={shortcutDialog.open}>
			<DialogTitle id="simple-dialog-title">
				{shortcutDialog.type === 'new' ? 'New' : 'Edit'} Shortcut Item
			</DialogTitle>
			<DialogContent classes={{ root: 'p-0' }}>
				<div className="flex flex-wrap w-full px-12 sm:px-20 mb-16" />
				<div className="px-16 sm:px-24">
					<FormControl className="mt-8 mb-16" required fullWidth>
						<TextField
							label="Title"
							autoFocus
							name="title"
							value={form.title}
							onChange={handleChange}
							required
							variant="outlined"
						/>
					</FormControl>
				</div>
				<div className="px-16 sm:px-24">
					<FormControl className="mt-8 mb-16" required fullWidth>
						<TextField
							label="Link"
							autoFocus
							name="link"
							value={form.link}
							onChange={handleChange}
							required
							variant="outlined"
						/>
					</FormControl>
				</div>
			</DialogContent>

			{shortcutDialog.type === 'new' ? (
				<DialogActions className="justify-between p-8">
					<div className="px-16">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								dispatch(addShortcut(form)).then(() => {
									closeDialog()
								})
							}}
							disabled={!canBeSubmitted() || isGroupAdding}
						>
							Add
						</Button>
					</div>
				</DialogActions>
			) : (
				<DialogActions className="justify-between p-8">
					<div className="px-16">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								dispatch(updateShortcut(form)).then(() => {
									closeDialog()
								})
							}}
							disabled={!canBeSubmitted() || isGroupAdding}
						>
							Save
						</Button>
					</div>
				</DialogActions>
			)}
		</Dialog>
	)
}

export default ShortcutDialog
