//
// prototypesSlice.js
//
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getPrototypes = createAsyncThunk(
	'formsApp/prototypes/getPrototypes',
	async (params, { dispatch, getState }) => {
		const cached = getState().formsApp.prototypes
		if (Object.keys(cached.entities).length) {
			return cached.entities
		}
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Prototypes/prototypes`)
		return await response.data.data
	}
)

const prototypesAdapter = createEntityAdapter({
	selectId: prototype => prototype.prototype_id
})

export const {
	selectAll: selectPrototypes,
	selectEntities: selectPrototypesEntities,
	selectById: selectPrototypeById
} = prototypesAdapter.getSelectors(state => state.formsApp.prototypes)

const prototypesSlice = createSlice({
	name: 'formsApp/prototypes',
	initialState: prototypesAdapter.getInitialState({}),
	reducers: {},
	extraReducers: {
		[getPrototypes.fulfilled]: prototypesAdapter.setAll
	}
})

export default prototypesSlice.reducer
