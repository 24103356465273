import { createSlice } from '@reduxjs/toolkit'

export const setAccessData = roles => async dispatch => {
	dispatch(setAccess(roles))
}

export const setNavAccessData = groups => async dispatch => {
	dispatch(setNavAccess(groups))
}

const initialState = {
	roles: [],
	nav: []
}

const accessSlice = createSlice({
	name: 'auth/access',
	initialState,
	reducers: {
		// setAccess: (state, action) => action.payload,
		setAccess: (state, action) => {
			state.roles = action.payload
		},
		setNavAccess: (state, action) => {
			state.nav = action.payload
		}
	},
	extraReducers: {}
})

export const { setAccess, setNavAccess } = accessSlice.actions

export default accessSlice.reducer
