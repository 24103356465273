//
// MultiFormModel.js
//
class MultiFormModel {
	constructor(state, type) {
		this.multi_form_id = state.multi_form_id
		this.form_name = state.title || 'Untitled'
		this.provider_id = state.provider_id || null
		this.department_id = state.department_id || null
		this.layout = state.layout || '1/1'
		this.main_form_id = state.layout_forms['main-form'].id || null
		this.second_form_id = state.layout_forms['second-form'].id || null
		this.third_form_id = state.layout_forms['third-form']?.id || null
		this.main_relations = JSON.stringify(state.layout_forms['main-form'].filters) || null
		this.second_relations = JSON.stringify(state.layout_forms['second-form']?.filters) || null
		this.generic_route = state.route || null
		this.form_type = type
		this.users = state.users
		this.groups = state.groups
	}
}

export default MultiFormModel
