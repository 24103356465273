import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import reorder from './reorder'
import _ from '@lodash'

export const getShortcuts = createAsyncThunk('dashboardApp/shortcuts/getShortcuts', async () => {
	const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/shortcuts')
	return await response.data.data
})

export const addShortcut = createAsyncThunk('dashboardApp/shortcuts/addShortcut', async shortcut => {
	const response = await axios.post(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/shortcut/add', shortcut)
	return await response.data.item
})

export const updateShortcut = createAsyncThunk('dashboardApp/shortcuts/updateShortcut', async shortcut => {
	const response = await axios.post(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/shortcut/update', shortcut)
	return await response.data.item
})

export const removeShortcut = createAsyncThunk('dashboardApp/shortcuts/removeShortcut', async shortcut => {
	const response = await axios.post(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/shortcut/delete', {
		id: shortcut.id
	})
	return await response.data.item
})

export const reorderShortcuts = createAsyncThunk(
	'dashboardApp/shortcuts/reorderShortcuts',
	async (result, { dispatch, getState }) => {
		const { shortcuts } = getState().dashboardApp
		const { byKey } = shortcuts

		const ordered = reorder(_.merge([], byKey), result.source.index, result.destination.index)

		dispatch(tempOrder(ordered))

		const response = await axios.post(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/shortcut/order', {
			ordered: ordered
		})

		dispatch(
			showMessage({
				message: 'List Order Saved',
				autoHideDuration: 2000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right'
				}
			})
		)

		return response.data.items
	}
)

const dashboardAdapter = createEntityAdapter({
	// ..Assume IDs are stored in a field other than `shortcut.id`
	selectId: shortcut => shortcut.id
})

export const { selectAll: selectShortcuts, selectById: selectFilterById } = dashboardAdapter.getSelectors(
	state => state.dashboardApp.shortcuts // ..select the entity adapter data from the root state
)

const dashboardSlice = createSlice({
	// A name, used in action types
	name: 'dashboardApp/shortcuts',
	// The initial state for the reducer
	initialState: dashboardAdapter.getInitialState({
		dialog: {
			type: 'new',
			data: null,
			open: false
		},
		byKey: {},
		isLoading: true,
		isUpdating: false
	}),
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {
		tempOrder: (state, action) => {
			dashboardAdapter.setAll(state, action.payload)
		},
		openNewShortcutDialog: (state, action) => {
			state.dialog = {
				type: 'new',
				data: {
					id: null,
					title: ''
				},
				open: true
			}
		},
		openEditShortcutDialog: (state, action) => {
			state.dialog = {
				type: 'edit',
				data: {
					id: action.payload.id,
					title: action.payload.title,
					link: action.payload.link
				},
				open: true
			}
		},
		closeShortcutDialog: (state, action) => {
			state.dialog = {
				type: state.dialog.type,
				data: null,
				open: false
			}
		}
	},
	// A "builder callback" function used to add more reducers, or
	// an additional object of "case reducers", where the keys should be other
	// action types
	extraReducers: {
		[getShortcuts.fulfilled]: (state, action) => {
			dashboardAdapter.setAll(state, action.payload)
			state.byKey = action.payload
		},
		[addShortcut.fulfilled]: (state, action) => {
			dashboardAdapter.addOne(state, action.payload)
			const item = action.payload
			state.byKey = _.assign({}, state.byKey, { [Object.keys(state.byKey).length]: item })
		},
		[updateShortcut.fulfilled]: (state, action) => {
			const { id, link, title } = action.payload
			state.isUpdating = false
			dashboardAdapter.updateOne(state, { id: id, changes: { link, title } })
		},
		[removeShortcut.fulfilled]: (state, action) => {
			dashboardAdapter.removeOne(state, action.payload.id)
			state.byKey = _.reject(state.byKey, { id: action.payload.id })
		},
		[reorderShortcuts.fulfilled]: (state, action) => {
			dashboardAdapter.setAll(state, action.payload)
			state.byKey = action.payload
		}
	}
})

export const { openNewShortcutDialog, openEditShortcutDialog, closeShortcutDialog, tempOrder } = dashboardSlice.actions

export default dashboardSlice.reducer
