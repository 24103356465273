import globalConfig from 'app/fuse-configs/globalConfig'
import Profile from './Profile'

const ProfileConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['guest'],
	routes: [
		{
			path: '/user/profile',
			component: Profile
		}
	]
}

export default ProfileConfig
