//
// Reducer
//
import { combineReducers } from '@reduxjs/toolkit'
import notifications from './notificationsSlice'

const reducer = combineReducers({
	notifications
})

export default reducer
