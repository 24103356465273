import { createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import navigationConfig from 'app/fuse-configs/navigationConfig'
import FuseUtils from '@fuse/utils'
import i18next from 'i18next'
import _ from '@lodash'

const navigationAdapter = createEntityAdapter()
const emptyInitialState = navigationAdapter.getInitialState({
	loading: true
})

export const appendNavigationItem = (item, parentId) => (dispatch, getState) => {
	const navigation = selectNavigationAll(getState())

	return dispatch(setNavigation(FuseUtils.appendNavItem(navigation, item, parentId)))
}

export const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
	const navigation = selectNavigationAll(getState())

	return dispatch(setNavigation(FuseUtils.prependNavItem(navigation, item, parentId)))
}

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
	const navigation = selectNavigationAll(getState())

	return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)))
}

export const removeNavigationItem = id => (dispatch, getState) => {
	const navigation = selectNavigationAll(getState())

	return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)))
}

export const {
	selectAll: selectNavigationAll,
	selectIds: selectNavigationIds,
	selectById: selectNavigationItemById
} = navigationAdapter.getSelectors(state => state.fuse.navigation)

export const selectNavigation = createSelector(
	[selectNavigationAll, ({ i18n }) => i18n.language],
	(navigation, language) => {
		const setTranslationValues = data => {
			// loop through every object in the array
			return data.map(item => {
				if (item.translate && item.title) {
					item.title = i18next.t(`navigation:${item.translate}`)
				}

				// see if there is a children node
				if (item.children) {
					// run this function recursively on the children array
					item.children = setTranslationValues(item.children)
				}
				return item
			})
		}

		return setTranslationValues(_.merge([], navigation))
	}
)

export const selectNavigationData = createSelector([selectNavigation, ({ auth }) => auth], (navigation, auth) => {
	const navAccess = auth.access.nav
	let topParent = null
	const setAccessRoles = (data, level = 0, parent = null) => {
		// loop through every object in the array
		return data.map(current => {
			// see if a auth node exists
			if (!current.auth) {
				current.auth = ['']
			}
			if (level === 1) {
				topParent = parent
			}
			// search if item has access
			const position = navAccess.findIndex(nav => nav.menu_item_id === current.id)
			if (position !== -1) {
				const group = navAccess[position].user_group_id
				if (parent && !parent.auth.includes(group)) {
					if (parent.auth[0] === '' && parent.auth.length === 1) {
						parent.auth = [group]
					} else {
						// give access to parent item
						parent.auth.push(group)
					}
				}
				if (!topParent.auth.includes(group)) {
					if (topParent.auth[0] === '' && topParent.auth.length === 1) {
						topParent.auth = [group]
					} else {
						// give access to parent item
						topParent.auth.push(group)
					}
				}
				if (!current.auth.includes(group)) {
					if (current.auth[0] === '' && current.auth.length === 1) {
						current.auth = [group]
					} else {
						// give access to parent item
						current.auth.push(group)
					}
				}
			}
			// see if there is a children node
			if (current.children) {
				// run this function recursively on the children array
				current.children = setAccessRoles(current.children, level + 1, current)
			}
			return current
		})
	}
	return setAccessRoles(_.merge([], navigation))
})

const initialState = navigationAdapter.upsertMany(emptyInitialState, navigationConfig)

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		setNavigation: navigationAdapter.setAll,
		resetNavigation: (state, action) => initialState
	}
})

export const { setNavigation, resetNavigation } = navigationSlice.actions

export default navigationSlice.reducer
