import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import pulseApiService from 'app/services/pulseApiService/pulseApiService'

const cellHistoryAdapter = createEntityAdapter({
	selectId: record => record.id
})

export const getCellHistory = createAsyncThunk('formsApp/cellHistory/get', async (params, { dispatch, getState }) => {
	const { prototype_id, form_id, id, field } = params
	dispatch(showCellHistory(field))
	const response = await pulseApiService.getCellHistory(prototype_id, form_id, id, field)
	return await response.history
})

const cellHistorySlice = createSlice({
	name: 'formsApp/cellHistory',
	initialState: cellHistoryAdapter.getInitialState({
		loading: true,
		display: false,
		field: ''
	}),
	reducers: {
		showCellHistory: (state, action) => {
			state.display = true
			state.loading = true
			state.field = action.payload
		},
		hideCellHistory: (state, action) => {
			state.display = false
		}
	},
	extraReducers: {
		[getCellHistory.fulfilled]: (state, action) => {
			cellHistoryAdapter.setAll(state, action.payload)
			state.loading = false
		}
	}
})

// eslint-disable-next-line no-empty-pattern
export const { showCellHistory, hideCellHistory } = cellHistorySlice.actions

export default cellHistorySlice.reducer
