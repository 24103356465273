//
// LayoutFormsRelations.js
//
import _ from '@lodash'
import { humanizeField } from 'app/Functions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RelationsFilterBuilder from '../form/components/RelationsFilterBuilder'
import { setLayoutFormsRelations } from '../store/forms/formSlice'

const LayoutFormsRelations = props => {
	const dispatch = useDispatch()
	// eslint-disable-next-line no-shadow
	const formData = useSelector(({ formsApp }) => formsApp.form)

	const master = _.find(props.forms, { form_id: formData.layout_forms[props.master].id })
	const slave = _.find(props.forms, { form_id: formData.layout_forms[props.slave].id })
	const title = `${master.form_name} -> ${slave.form_name}`

	const slaveFields = []
	let fields = slave.settings_formatted.fields
	for (const i in fields) {
		slaveFields.push(fields[i].dataField)
	}

	const masterFields = []
	fields = master.settings_formatted.fields
	for (const j in fields) {
		masterFields.push({
			dataField: fields[j].dataField,
			filterOperations: ['='],
			lookup: {
				dataSource: slaveFields,
				displayExpr: function (item) {
					return humanizeField(item)
				}
			}
		})
	}

	return (
		<RelationsFilterBuilder
			title={title}
			id={props.master}
			value={formData.layout_forms[props.master].filters ?? []}
			onChange={e => {
				dispatch(setLayoutFormsRelations({ id: props.master, val: e.value }))
			}}
			fields={masterFields}
		/>
	)
}

export default LayoutFormsRelations
