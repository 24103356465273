import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
// import { preg_match_all } from 'app/Functions'

export const getCommentsByRowUID = createAsyncThunk(
	'formsApp/comments/getComments',
	async (params, { dispatch, getState }) => {
		const { uuid } = params

		dispatch(setRowUid(uuid))

		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Comments/${uuid}`)

		// eslint-disable-next-line no-return-await
		return await response.data.data
	}
)

export const addComment = createAsyncThunk('formsApp/comments/addComment', async (params, { dispatch, getState }) => {
	const state = getState()
	const commentData = state.formsApp.comments.comment
	const notifiers = state.formsApp.comments.notifiers
	const { form_id, form_type, generic_route } = state.formsApp.form.form

	// add to data
	const data = { ...commentData, notifiers: notifiers, form_id, form_type, generic_route }

	// ===== create the record ======
	const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Comments/${commentData.uuid}/add`, data)

	dispatch(setNotifiers([]))

	// eslint-disable-next-line no-return-await
	return await response.data
})

export const getCommentCountsByRowUIDs = createAsyncThunk(
	'formsApp/comments/getCommentCountsByRowUIDs',
	async (params, { dispatch, getState }) => {
		const { rowguids } = params

		dispatch(setCounting())

		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Comments/counts`, {
			uuid: rowguids.join(',')
		})

		dispatch(setCounts(response.data.counts))

		return await response.data.counts
	}
)

const commentsAdapter = createEntityAdapter({
	selectId: comment => comment.comment_id
})

export const {
	selectAll: selectComments,
	selectEntities: selectCommentEntities,
	selectById: selectCommentByRowId
} = commentsAdapter.getSelectors(state => state.formsApp.comments)

const commentsSlice = createSlice({
	name: 'formsApp/comments',
	initialState: commentsAdapter.getInitialState({
		loading: true,
		counting: false,
		sidebar: false,
		comment: {
			comment_content: '',
			comment_content_rendered: '',
			comment_category_id: null,
			comment_department_id: null,
			uuid: null
		},
		notifiers: [],
		counts: {},
		rowuid: '',
		popup: false
	}),
	reducers: {
		enableCommentSidebar: (state, action) => {
			state.sidebar = true
			state.loading = true
		},
		setCounting: (state, action) => {
			state.counting = true
		},
		setNotifiers: (state, action) => {
			state.notifiers = action.payload
		},
		setCounts: (state, action) => {
			state.counts = { ...state.counts, ...action.payload }
			state.counting = false
		},
		closeCommentSidebar: (state, action) => {
			state.sidebar = false
			state.rowuid = ''
			state.comment.uuid = ''
			state.notifiers = []
		},
		enableCommentPopup: (state, action) => {
			state.popup = true
		},
		closeCommentPopup: (state, action) => {
			state.popup = false
		},
		setRowUid: (state, action) => {
			state.rowuid = action.payload
			state.comment.uuid = action.payload
		},
		setComment: (state, action) => {
			const { content, category_id, department_id, uuid, notifier } = action.payload

			// get comment
			const comment = state.comment.comment_content

			if (typeof notifier !== 'undefined') {
				const words = comment.split(' ')

				// find the index of last time word was used
				let v = words[words.length - 1].substring(1)

				// slice the string in 2, one from the start to the lastIndexOf
				// and then replace the word in the rest
				const message = comment.substr(0, comment.length - v.length).replace('@', '')
				// eslint-no-useless-concat
				state.comment.comment_content = `${message} [${notifier.full_name}] `
				state.notifiers.push(notifier.UserID)

				// const regex = /(?<=\[)[a-zA-Z0-9 ]+(?=\])/
				// const result = preg_match_all(regex, state.comment.comment_content)
			}

			if (typeof category_id !== 'undefined') {
				state.comment.comment_category_id = category_id
			}
			if (typeof department_id !== 'undefined') {
				state.comment.comment_department_id = department_id
			}
			if (typeof content !== 'undefined') {
				state.comment.comment_content = content
			}
			if (typeof uuid !== 'undefined') {
				state.comment.uuid = uuid
			}
		}
	},
	extraReducers: {
		[getCommentsByRowUID.fulfilled]: (state, action) => {
			commentsAdapter.setAll(state, action.payload)
			state.loading = false
		}
	}
})

// eslint-disable-next-line no-empty-pattern
export const {
	enableCommentSidebar,
	closeCommentSidebar,
	resetComments,
	setRowUid,
	setComment,
	enableCommentPopup,
	closeCommentPopup,
	setCounting,
	setCounts,
	setNotifiers
} = commentsSlice.actions

export default commentsSlice.reducer
