import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FusePageSimple from '@fuse/core/FusePageSimple'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AboutTab from './tab/AboutTab'
import { createPath } from 'app/store/breadcrumbSlice'
import { hideTabs } from '../../../store/settingsSlice'

const useStyles = makeStyles(theme => ({
	layoutHeader: {
		height: 130,
		minHeight: 130,
		[theme.breakpoints.down('md')]: {
			height: 240,
			minHeight: 240
		}
	}
}))

const Breadcrumb = {
	id: 'root',
	title: 'Root',
	type: 'group',
	icon: 'star',
	children: [
		{
			id: 'profile',
			title: 'Profile',
			type: 'item',
			url: '/user/profile'
		}
	]
}

const Profile = props => {
	const dispatch = useDispatch()

	const classes = useStyles(props)

	const [selectedTab, setSelectedTab] = useState(0)

	// eslint-disable-next-line no-shadow
	const tabsEnabled = useSelector(({ settings }) => settings.showTabs)

	function handleTabChange(event, value) {
		setSelectedTab(value)
	}

	useEffect(() => {
		dispatch(createPath(Breadcrumb))
		if (tabsEnabled) {
			dispatch(hideTabs())
		}
	}, [dispatch, tabsEnabled])

	return (
		<FusePageSimple
			classes={{
				header: classes.layoutHeader,
				toolbar: 'px-16 sm:px-24'
			}}
			contentToolbar={
				<Tabs
					value={selectedTab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="off"
					classes={{
						root: 'h-64 w-full'
					}}
				>
					<Tab
						classes={{
							root: 'h-64'
						}}
						label="About"
					/>
					<Tab
						classes={{
							root: 'h-64'
						}}
						label="Photos & Videos"
					/>
				</Tabs>
			}
			content={<div className="p-16 sm:p-24">{selectedTab === 0 && <AboutTab />}</div>}
		/>
	)
}

export default Profile
