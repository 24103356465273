//
// Prototype.js
//
import React from 'react'
import FusePageSimple from '@fuse/core/FusePageSimple'
import { useDispatch } from 'react-redux'
import { hideTabs } from '../../store/settingsSlice'
import { createPath } from '../../store/breadcrumbSlice'
import { useDeepCompareEffect } from '../../../@fuse/hooks'

const BreadcrumbSettings = {
	id: 'root',
	title: 'Root',
	type: 'group',
	icon: 'star',
	children: [
		{
			id: 'settings',
			title: 'Settings',
			type: 'item',
			url: '/settings'
		}
	]
}

const Settings = () => {
	const dispatch = useDispatch()

	useDeepCompareEffect(() => {
		dispatch(hideTabs())

		dispatch(createPath(BreadcrumbSettings))
	}, [dispatch])

	return (
		<FusePageSimple
			breadcrumb={BreadcrumbSettings}
			classes={{
				root: 'bg-red',
				header: 'h-72 min-h-72 sm:h-96 sm:min-h-96',
				sidebarHeader: 'h-96 min-h-96 sm:h-160 sm:min-h-160',
				rightSidebar: 'w-320'
			}}
		/>
	)
}

export default Settings
