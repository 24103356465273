//
// providersSlice.js
//
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

// eslint-disable-next-line no-empty-pattern
export const getAllProviders = createAsyncThunk(
	'formsApp/providers/getAllProviders',
	async (params, { dispatch, getState }) => {
		const cached = getState().formsApp.allProviders
		if (Object.keys(cached.entities).length > 0) {
			return cached.entities
		}
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Providers/all`)
		return await response.data.data
	}
)

const allProvidersAdapter = createEntityAdapter({
	// .assume IDs are stored in a field other than `provider.provider_id`
	selectId: provider => provider.provider_id
})

export const {
	selectAll: selectAllProviders,
	selectEntities: selectAllProvidersEntities,
	selectById: selectAllProviderById
} = allProvidersAdapter.getSelectors(state => state.formsApp.allProviders)

const allProvidersSlice = createSlice({
	name: 'formsApp/providers',
	initialState: allProvidersAdapter.getInitialState({
		loading: true
	}),
	reducers: {},
	extraReducers: {
		[getAllProviders.fulfilled]: (state, action) => {
			allProvidersAdapter.setAll(state, action.payload)
		}
	}
})

export default allProvidersSlice.reducer
