import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getGroups = createAsyncThunk('formsApp/users/getGroups', async () => {
	const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + '/Users/groups')

	return await response.data.data
})

const groupsAdapter = createEntityAdapter({
	// ..assume IDs are stored in a field other than `provider.provider_id`
	selectId: group => group.user_group_id
})

export const {
	selectAll: selectGroups,
	selectEntities: selectGroupsEntities,
	selectById: selectGroupById
} = groupsAdapter.getSelectors(state => state.formsApp.groups)

const groupsSlice = createSlice({
	name: 'formsApp/groups',
	initialState: groupsAdapter.getInitialState({
		dialog: {
			data: null,
			open: false
		}
	}),
	reducers: {},
	extraReducers: {
		[getGroups.fulfilled]: groupsAdapter.setAll
	}
})

export default groupsSlice.reducer
