import { Badge, DialogActions, DialogTitle, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import UserIcon from '@material-ui/icons/PermIdentity'
import { unwrapResult } from '@reduxjs/toolkit'
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice'
import { showMessage } from 'app/store/fuse/messageSlice'
import { addTab, deleteRelatedFormTab } from 'app/store/tabsSlice'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { LayoutIcon } from '../../layout'
import { deleteFormInDepartment, updateFormInDepartment } from '../../store/departments/departmentSlice'
import { deleteGenericForm, updateGenericForm } from '../../store/forms/genericSlice'
import { addMultiFormShortcut, deleteMultiForm } from '../../store/forms/multiFormSlice'

const useStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	},
	item: {
		'&.toBeRemoved': {
			opacity: 0.2
		}
	}
})

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9'
	}
}))(Tooltip)

const FormRow = props => {
	const dispatch = useDispatch()
	// eslint-disable-next-line no-shadow
	const classes = useStyles()

	/**
	 * Use selectors.
	 */
	// eslint-disable-next-line no-shadow
	const authUser = useSelector(({ auth }) => auth.user)

	/**
	 * Local instance.
	 */
	// eslint-disable-next-line no-shadow
	const [anchorEl, setAnchorEl] = useState(null)

	// eslint-disable-next-line no-shadow
	const { item, folder, hasChildren } = props

	// eslint-disable-next-line no-shadow
	const hasAdminAccess = authUser.data.groups.findIndex(group => group.user_group_id === 1) !== -1

	// eslint-disable-next-line no-shadow
	const hasEditAccess = item.created_by === authUser.data.id

	// eslint-disable-next-line no-shadow
	const canModify = hasAdminAccess || hasEditAccess

	const handleMenuClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const link = `/forms/${folder}/multi-form/view/${item.multi_form_id}`

	return (
		<TableRow
			key={item.multi_form_id}
			hover
			className={clsx('cursor-pointer', classes.item, { toBeRemoved: item.removing })}
		>
			{hasChildren && <TableCell />}

			<TableCell
				onClick={() => {
					props.history.push(link, { tab: -1 })
				}}
				className="max-w-64 w-64 p-0 text-center"
			>
				<LayoutIcon template={item.layout} zoom="50" />
			</TableCell>
			<TableCell
				className="whitespace-nowrap"
				onClick={() => {
					props.history.push(link, { tab: -1 })
				}}
			>
				{item.form_name}
			</TableCell>
			<TableCell
				className="whitespace-nowrap"
				onClick={() => {
					props.history.push(link, { tab: -1 })
				}}
			>
				Multi form
			</TableCell>
			<TableCell
				onClick={() => {
					props.history.push(link, { tab: -1 })
				}}
				className="hidden sm:table-cell"
			>
				<HtmlTooltip
					title={
						<React.Fragment>
							<Typography color="inherit">Users</Typography>
							<List dense={false}>
								{item.users &&
									item.users.map((user, key) => (
										<ListItem key={key}>
											<ListItemIcon>
												<UserIcon />
											</ListItemIcon>
											<ListItemText primary={user.full_name} />
										</ListItem>
									))}
							</List>
						</React.Fragment>
					}
					placement="top"
				>
					<Badge badgeContent={item.users.length} className="mr-24" color="secondary">
						<Icon>supervisor_account</Icon>
					</Badge>
				</HtmlTooltip>
				<HtmlTooltip
					title={
						<React.Fragment>
							<Typography color="inherit">Groups</Typography>
							<List dense={false}>
								{item.groups &&
									item.groups.map((group, key) => (
										<ListItem key={key}>
											<ListItemIcon>
												<UserIcon />
											</ListItemIcon>
											<ListItemText primary={group.user_group_name} />
										</ListItem>
									))}
							</List>
						</React.Fragment>
					}
					placement="top"
				>
					<Badge badgeContent={item.groups.length} className="mr-24" color="secondary">
						<Icon>groups</Icon>
					</Badge>
				</HtmlTooltip>
			</TableCell>
			<TableCell
				onClick={() => {
					props.history.push(link, { tab: -1 })
				}}
			>
				{item.created_at_formatted}
			</TableCell>
			<TableCell
				onClick={() => {
					props.history.push(link, { tab: -1 })
				}}
			>
				{item.creators_user_name}
			</TableCell>

			<TableCell>
				<IconButton
					size="small"
					variant="outlined"
					onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						dispatch(addMultiFormShortcut({ id: item.multi_form_id })).then(response => {
							if (response.payload.shortcut) {
								dispatch(
									updateFormInDepartment({
										id: item.multi_form_id,
										changes: { shortcut: response.payload.shortcut }
									})
								)
								dispatch(
									updateGenericForm({
										id: item.multi_form_id,
										changes: { shortcut: response.payload.shortcut }
									})
								)
								dispatch(
									showMessage({
										message: `Template has successfully added to your dashboard`,
										variant: 'success',
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right'
										}
									})
								)
							} else {
								dispatch(
									updateFormInDepartment({
										id: item.multi_form_id,
										changes: { shortcut: null }
									})
								)
								dispatch(
									updateGenericForm({
										id: item.multi_form_id,
										changes: { shortcut: null }
									})
								)
								dispatch(
									showMessage({
										message: `Template has successfully removed to your dashboard`,
										variant: 'success',
										anchorOrigin: {
											vertical: 'top',
											horizontal: 'right'
										}
									})
								)
							}
						})
					}}
				>
					{item.shortcut ? <Icon>star</Icon> : <Icon>star_outline</Icon>}
				</IconButton>
			</TableCell>

			<TableCell className="p-0">
				<>
					<IconButton
						aria-owns={anchorEl ? 'actions-menu' : null}
						aria-haspopup="true"
						onClick={handleMenuClick}
						variant="outlined"
						size="small"
					>
						<Icon className="text-20">more_vert</Icon>
					</IconButton>
					<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
						{canModify && (
							<MenuItem
								onClick={() => {
									setAnchorEl(null)
									dispatch(
										openDialog({
											children: (
												<>
													<DialogTitle>
														Are you sure you want to delete this template?
													</DialogTitle>
													<DialogActions>
														<Button
															onClick={() => {
																setAnchorEl(null)
																dispatch(closeDialog())
															}}
															color="primary"
														>
															No
														</Button>
														<Button
															onClick={() => {
																dispatch(closeDialog())
																setAnchorEl(null)
																// prepare items
																dispatch(
																	updateFormInDepartment({
																		id: item.multi_form_id,
																		changes: {
																			removing: true
																		}
																	})
																)
																dispatch(
																	updateGenericForm({
																		id: item.multi_form_id,
																		changes: {
																			removing: true
																		}
																	})
																)
																// delete
																dispatch(
																	deleteMultiForm({
																		id: item.multi_form_id
																	})
																)
																	.then(unwrapResult)
																	.then(() => {
																		dispatch(
																			deleteFormInDepartment({
																				id: item.multi_form_id
																			})
																		)
																		dispatch(
																			deleteGenericForm({
																				id: item.multi_form_id
																			})
																		)
																		dispatch(
																			showMessage({
																				message: 'form successfully deleted',
																				variant: 'success'
																			})
																		)
																	})
																	.catch(actualError => {
																		dispatch(
																			showMessage({
																				message: actualError.message,
																				variant: 'error'
																			})
																		)
																	})
																dispatch(
																	deleteRelatedFormTab({
																		form_id: item.multi_form_id,
																		folder_name: folder
																	})
																)
															}}
															color="primary"
															autoFocus
														>
															Yes
														</Button>
													</DialogActions>
												</>
											)
										})
									)
								}}
							>
								<ListItemIcon className="min-w-40">
									<Icon>delete</Icon>
								</ListItemIcon>
								<ListItemText primary="Remove" />
							</MenuItem>
						)}
					</Menu>
				</>
				<IconButton
					title="Open in New Tab"
					onClick={() => {
						dispatch(
							addTab({
								name: item.form_name,
								link: link
							})
						)
					}}
				>
					<Icon>tab</Icon>
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default withRouter(FormRow)
