//
// formSlice.js
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import MultiFormModel from '../../form/model/MultiFormModel'
import { formatDate } from 'app/@artemis/helper'

/**
 * Get the Multi Form
 */
export const getMultiForm = createAsyncThunk(
	// action type string
	'formsApp/multiForm/getForm',
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/MultiForm/form`, params)
			return response.data
		} catch (err) {
			// Use `err.response.data` as `action.payload` for a `rejected` action,
			// by explicitly returning it using the `rejectWithValue()` utility
			return rejectWithValue(err.response)
		}
	}
)

/**
 * Insert
 * @async Thunk
 */
export const createMultiForm = createAsyncThunk(
	// action type string
	'formsApp/multiForm/addForm',
	// callback function
	async (params, { dispatch, getState }) => {
		const { type, formData } = params
		//  post to API
		const response = await axios.post(
			`${process.env.REACT_APP_API_PULSE_URL}/MultiForm/add`,
			new MultiFormModel(formData, type)
		)
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Update
 * @async Thunk
 */
export const updateMultiForm = createAsyncThunk(
	// action type string
	'formsApp/multiForm/editForm',
	// callback function
	async (params, { dispatch, getState }) => {
		const { formData } = params
		// post data to API
		const response = await axios.post(
			`${process.env.REACT_APP_API_PULSE_URL}/MultiForm/update`,
			new MultiFormModel(formData)
		)
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Delete
 * @async Thunk
 */
export const deleteMultiForm = createAsyncThunk(
	// action type string
	'formsApp/multiForm/deleteForm',
	// callback function
	async (params, { dispatch }) => {
		// eslint-disable-next-line camelcase
		const { id } = params
		// post-form-to-delete
		const response = await axios.post(
			`${process.env.REACT_APP_API_PULSE_URL}/MultiForm/delete`,
			{ id },
			{ validateStatus: () => true }
		)
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Add Shortcut to dashboard
 * @async Thunk
 */
export const addMultiFormShortcut = createAsyncThunk('formsApp/multiForm/addShortcut', async params => {
	// eslint-disable-next-line camelcase
	const { id } = params

	const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/MultiForm/add/shortcut`, { id })
	const { data } = response

	// eslint-disable-next-line no-return-await
	return await data
})

const initialState = {
	multi_form_id: null,
	title: '',
	provider_id: 0,
	department_id: 0,
	layout: '',
	main_form_id: 0,
	second_form_id: 0,
	third_form_id: 0,
	main_form: null,
	second_form: null,
	third_form: null,
	main_relations: null,
	second_relations: null,
	users: [],
	groups: [],
	route: ''
}

/**
 * Create object
 */
const makeFormObject = formData => {
	if (formData === null) {
		return null
	}

	let newFields = []

	const { settings_formatted } = formData
	const { fields } = settings_formatted

	// Ignore
	for (const i in fields) {
		if (!fields[i].dataField.includes('_displayname') && !fields[i].dataField.includes('rowguid')) {
			newFields.push(fields[i])
		}
	}

	// Format filter dates
	formData.settings_formatted.filters = formatDate({ mode: 'view' }, formData.settings_formatted.filters)

	return {
		form: formData,
		selectedFields: newFields
	}
}

const multiFormSlice = createSlice({
	// A name, used in action types
	name: 'formsApp/multiForm',
	// The initial state for the reducer
	initialState: initialState,
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {},
	/**
	 * A "builder callback" function used to add more reducers,
	 * or an additional object of "case reducers", where the keys should be other action types
	 */
	extraReducers: {
		// Reset form state
		[getMultiForm.pending]: (state, action) => {
			if (action.meta.arg.formID !== state.multi_form_id) {
				return { ...initialState, type: action.meta.arg.parent ? 'new' : 'edit' }
			} else {
				return { ...state, selectedRows: 0, type: action.meta.arg.parent ? 'new' : 'edit' }
			}
		},

		// Add form to state object
		[getMultiForm.fulfilled]: (state, action) => {
			const { multi_form, main_form, second_form, third_form } = action.payload

			// eslint-disable-next-line no-shadow,camelcase
			const { users, groups } = multi_form

			// Collect users into userData array
			const userData = []
			for (const i in users) {
				const user = users[i]
				userData.push({
					label: user.full_name,
					value: user.UserID
				})
			}

			// Collect user groups into groupsData array
			const groupsData = []
			for (const i in groups) {
				const group = groups[i]
				groupsData.push({
					label: group.user_group_name,
					value: group.user_group_id
				})
			}

			return {
				...state, //<-- To be overridden
				title: multi_form.form_name,
				provider_id: multi_form.provider_id,
				department_id: multi_form.department_id,
				layout: multi_form.layout,
				main_form_id: multi_form.main_form_id,
				second_form_id: multi_form.second_form_id,
				third_form_id: multi_form.third_form_id,
				main_form: makeFormObject(main_form),
				second_form: makeFormObject(second_form),
				third_form: makeFormObject(third_form),
				main_relations: JSON.parse(multi_form.main_relations) || null,
				second_relations: JSON.parse(multi_form.second_relations) || null,
				users: userData,
				groups: groupsData,
				multi_form_id: multi_form.multi_form_id,
				route: multi_form.generic_route,
				owner: multi_form.created_by
			}
		}
		// [addMultiFormShortcut.fulfilled]: (state, action) => {},
		// [deleteMultiForm.fulfilled]: (state, action) => {},
		// [createMultiForm.fulfilled]: (state, action) => {}
	}
})

export default multiFormSlice.reducer
