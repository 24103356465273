import _ from '@lodash'
import { createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-duplicates
import apiService from 'app/services/apiService'
// eslint-disable-next-line import/no-duplicates
import pulseApiService from 'app/services/pulseApiService'
import { setDefaultSettings } from 'app/store/fuse/settingsSlice'
// eslint-disable-next-line import/no-cycle
import { loginError } from './loginSlice'

export const setAccessToken = accessToken => async dispatch => {
	return (
		pulseApiService
			// eslint-disable-next-line no-undef
			.signInWithToken(accessToken)
			// eslint-disable-next-line no-shadow
			.then(() => {
				apiService.emit('onAutoLogin', true)
			})
			.catch(error => {
				return dispatch(loginError(true))
			})
	)
}

export const goToProfile = () => async (dispatch, getState) => {
	window.location.href = process.env.REACT_APP_ID_URL + '/profile'
}

export const setUserData = user => async (dispatch, getState) => {
	/*
	 * You can redirect the logged-in user to a specific route depending on his role
	 */

	/*
	 * history.location.state = {
	 * 	  redirectUrl: user.redirectUrl // for example 'apps/academy'
	 * }
	 */

	/*
	 * Set User Settings
	 */
	dispatch(setDefaultSettings(user.data.settings))

	dispatch(setUser(user))
}

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user
	const user = _.merge({}, oldUser, { data: { settings } })

	dispatch(updateUserData(user))

	return dispatch(setUserData(user))
}

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	}

	dispatch(updateUserData(user))

	return dispatch(setUserData(newUser))
}

// logoutUser: (state, action) => {
// 	// history.push({
// 	// 	pathname: '/'
// 	// })
// 	apiService.setSession(null)

// 	// setInitialSettings()

// 	dispatch(userLoggedOut())
// 	console.log(state)
// }

export const logoutUser = () => async (dispatch, getState) => {
	/*
	 *	history.push({
	 *		pathname: '/'
	 *	})
	 */

	apiService.setSession(null)

	// dispatch(setInitialSettings())

	return dispatch(userLoggedOut())
}

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return
	}
	switch (user.from) {
		default: {
			apiService.updateUserData(user)
			/*
				.then(() => {
					dispatch(showMessage({ message: 'User data saved with api' }))
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }))
				})
				*/
			break
		}
	}
}

const initialState = {
	role: [], // guest
	data: {
		displayName: 'User',
		photoURL: 'assets/images/avatars/Velazquez.jpg',
		email: 'user@artemis-its.com',
		shortcuts: ['forms', 'data', 'contacts']
	}
}

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		setAccess: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState
	},
	extraReducers: {}
})

export const { setUser, setAccess, userLoggedOut } = userSlice.actions

export default userSlice.reducer
