//
// projectsForecastsSlice.js
//
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getProjectsForecasts = createAsyncThunk(
	'dashboardApp/projectsForecasts/getProjectsForecasts',
	async () => {
		const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/forecasts')
		return await response.data.data
	}
)

export const addProjectForecast = createAsyncThunk('dashboardApp/projectsForecasts/addProjectForecast', async data => {
	const response = await axios.post(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/forecasts/add', data)
	return await response.data.item
})

export const removeProjectForecast = createAsyncThunk(
	'dashboardApp/projectsForecasts/removeProjectForecast',
	async data => {
		const response = await axios.post(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/forecasts/delete', {
			id: data.project_id
		})
		return await response.data.item
	}
)

const projectsForecastsAdapter = createEntityAdapter({
	selectId: projectForecasts => projectForecasts.id
})

export const { selectAll: selectProjectsForecasts } = projectsForecastsAdapter.getSelectors(
	state => state.dashboardApp.projectsForecasts
)

const projectsForecastsSlice = createSlice({
	name: 'dashboardApp/projectsForecasts',
	initialState: projectsForecastsAdapter.getInitialState({
		dialog: {
			data: null,
			open: false
		},
		isLoading: true,
		isUpdating: false
	}),
	reducers: {
		openForecastDialog: (state, action) => {
			state.dialog = {
				data: {
					project_id: '0'
				},
				open: true
			}
		},
		closeForecastDialog: (state, action) => {
			state.dialog = {
				data: null,
				open: false
			}
		}
	},
	extraReducers: {
		[getProjectsForecasts.fulfilled]: (state, action) => {
			projectsForecastsAdapter.setAll(state, action.payload)
		},
		[addProjectForecast.fulfilled]: (state, action) => {
			projectsForecastsAdapter.addOne(state, action.payload)
		},
		[removeProjectForecast.fulfilled]: (state, action) => {
			projectsForecastsAdapter.removeOne(state, action.payload.id)
		}
	}
})

export const { openForecastDialog, closeForecastDialog } = projectsForecastsSlice.actions

export default projectsForecastsSlice.reducer
