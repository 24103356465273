//
// LayoutIcon.js
//
import Box from '@material-ui/core/Box'
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline'
import React from 'react'

const LayoutIcon = props => {
	const { template } = props
	const zoom = props.zoom || 100

	// One
	if (template === '1') {
		return (
			<Box style={{ zoom: `${zoom}%` }}>
				<Box>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon style={{ marginLeft: '-8px' }} />
				</Box>
				<Box style={{ marginTop: '-10px' }}>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon style={{ marginLeft: '-8px' }} />
				</Box>
				<Box style={{ marginTop: '-10px' }}>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon style={{ marginLeft: '-8px' }} />
				</Box>
			</Box>
		)
	}

	// Left-Right
	if (template === '1/1') {
		return (
			<Box style={{ zoom: `${zoom}%` }}>
				<Box>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon style={{ marginLeft: '-8px' }} />
				</Box>
				<Box>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon style={{ marginLeft: '-8px' }} />
				</Box>
			</Box>
		)
	}

	// Up-Down
	if (template === '1-1') {
		return (
			<Box style={{ zoom: `${zoom}%` }}>
				<Box>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon />
				</Box>
				<Box style={{ marginTop: '-10px' }}>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon />
				</Box>
				<Box style={{ marginTop: '-10px' }}>
					<ViewHeadlineIcon />
					<ViewHeadlineIcon />
				</Box>
			</Box>
		)
	}

	// Up - Left/Right
	return (
		<Box style={{ zoom: `${zoom}%` }}>
			<Box>
				<ViewHeadlineIcon />
				<ViewHeadlineIcon />
			</Box>
			<Box style={{ marginTop: '-10px' }}>
				<ViewHeadlineIcon style={{ marginLeft: '-24px' }} />
			</Box>
			<Box style={{ marginTop: '-10px' }}>
				<ViewHeadlineIcon />
				<ViewHeadlineIcon />
			</Box>
		</Box>
	)
}

export default LayoutIcon
