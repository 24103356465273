import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

/**
 * Get the tabs
 * @async Thunk
 */
export const getTabs = createAsyncThunk(
	// action type string
	'tabsPanel/list',
	// callback function
	async (params, { dispatch }) => {
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Tabs`)

		return response.data.items
	}
)

/**
 * Insert
 * @async Thunk
 */
export const addTab = createAsyncThunk('tabsPanel/add', async (tab, { dispatch }) => {
	const item = { title: tab.name, link: tab.link, edit: false }
	const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Tabs/add`, item)

	return response.data
})

/**
 * Update
 * @async Thunk
 */
export const updateTab = createAsyncThunk('tabsPanel/update', async payload => {
	await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Tabs/update`, payload.item)
	return payload
})

/**
 * Delete
 * @async Thunk
 */
export const deleteTab = createAsyncThunk('tabsPanel/deleteTab', async (payload, { dispatch, getState }) => {
	const { id } = payload
	// dispatch(removeTabFromItems({ index }))
	const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Tabs/delete`, { id })
	// eslint-disable-next-line no-return-await
	return await response.data
})

/**
 * Delete related tab
 * @async Thunk
 */
export const deleteRelatedFormTab = createAsyncThunk('tabsPanel/delete', async (payload, { dispatch, getState }) => {
	// eslint-disable-next-line camelcase
	const { form_id, folder_name } = payload

	// eslint-disable-next-line camelcase
	const temp_link = `/forms/${folder_name}/form/view/${form_id}`

	const { items } = getState().tabs
	// eslint-disable-next-line camelcase
	const items_with_temp_link = items.filter(item => item.link === temp_link)

	// eslint-disable-next-line camelcase
	const item_ids_to_remove = items_with_temp_link.map(item => {
		return item.id
	})

	// eslint-disable-next-line camelcase
	if (item_ids_to_remove) {
		const response = await axios
			.post(`${process.env.REACT_APP_API_PULSE_URL}/Tabs/delete_multiple`, {
				ids: item_ids_to_remove
			})
			.then(() => dispatch(removeTabsFromItems({ item_ids_to_remove })))
		// eslint-disable-next-line no-return-await
		return await response.data
	}
})

const initialState = {
	selected: -1,
	loading: false,
	loaded: false,
	items: [],
	rootTabLink: null,
	defaultTabLink: null
}

const tabsSlice = createSlice({
	// A name, used in action types
	name: 'tabsPanel',
	// The initial state for the reducer
	initialState: initialState,
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {
		loading: (state, action) => {
			state.loading = true
		},
		createTab: (state, action) => {
			const { item } = action.payload
			state.items.push(item)
			state.selected = state.items.length - 1
		},
		selectTab: (state, action) => {
			const index = action.payload
			state.selected = index

			if (index >= 0) {
				state.items[index].edit = false
				state.items[index].focus = false
			}
		},
		removeTabFromItems: (state, action) => {
			const { index } = action.payload
			state.selected = index - 1
			state.items.splice(index, 1)
		},
		removeTabsFromItems: (state, action) => {
			const { item_ids_to_remove } = action.payload
			// ..remove items
			item_ids_to_remove.map(id => {
				return state.items.splice(
					state.items.findIndex(item => item.id === id),
					1
				)
			})
		},
		setRootTabLink: (state, action) => {
			state.rootTabLink = action.payload
		},
		startEditTab: (state, action) => {
			state.items[state.selected].edit = true
		},
		focusTab: (state, action) => {
			state.items[state.selected].focus = true
		},
		setItemId: (state, action) => {
			state.items[state.selected].id = action.payload
		}
	},
	/**
	 * A "builder callback" function used to add more reducers,
	 * or an additional object of "case reducers", where the keys should be other action types
	 */
	extraReducers: {
		[getTabs.fulfilled]: (state, action) => {
			state.items = [...action.payload]
			state.loading = false
			state.loaded = true
		},
		[addTab.fulfilled]: (state, action) => {
			const { item } = action.payload
			state.items.push(item)
			state.selected = state.items.length - 1
			state.items[state.selected].edit = false
		},
		[updateTab.fulfilled]: (state, action) => {
			const { selected, item } = action.payload
			state.items[selected] = item
			state.items[selected].edit = false
		},
		[deleteTab.pending]: (state, action) => {
			const { meta } = action
			const { arg } = meta
			const { index } = arg
			state.items[index].deleting = true
		},
		[deleteTab.fulfilled]: (state, action) => {
			const { tab } = action.payload
			const { id } = tab
			const index = state.items.findIndex(item => item.id === id)
			state.selected = state.items.length - 2
			state.items.splice(index, 1)
		}
	}
})

export const {
	createTab,
	selectTab,
	startEditTab,
	focusTab,
	setRootTabLink,
	removeTabFromItems,
	removeTabsFromItems,
	setItemId
} = tabsSlice.actions

export default tabsSlice.reducer
