//
// Forms.js
//
import FuseAnimate from '@fuse/core/FuseAnimate'
import { TableContainer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import { getComparator, stableSort } from 'app/@artemis/helper'
import { TableLoader } from 'app/@artemis/Loader'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EnhancedTableHead from '../../EnhancedTableHead'
import { selectFormsByDepartment } from '../../store/departments/departmentSlice'
import LayoutFormRow from './LayoutFormRow'

const useStyles = makeStyles({
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
})

const Forms = () => {
	const classes = useStyles()

	const initialHeader = [
		{ id: 'form_name', numeric: true, disablePadding: false, label: 'Name' },
		{ id: 'type', numeric: true, disablePadding: false, label: 'Type' },
		{ id: 'users_count', numeric: true, disablePadding: false, label: 'Users/Group' },
		{ id: 'updated_at_formatted', numeric: true, disablePadding: false, label: 'Created at' },
		{ id: 'creators_user_name', numeric: true, disablePadding: false, label: 'Owner' },
		{ id: 'shortcut', numeric: true, disablePadding: false, label: 'In dashboard' },
		{ id: 'new_tab', numeric: true, disablePadding: false, label: '' }
	]

	/**
	 * Local instance
	 */
	const [order, setOrder] = React.useState('asc')

	const [orderBy, setOrderBy] = React.useState('form_name')

	const [header, setHeader] = React.useState(initialHeader)

	const [hasChildren, setHasChildren] = React.useState(false)

	/**
	 * Use Selectors.
	 */
	// eslint-disable-next-line no-shadow
	const forms = useSelector(selectFormsByDepartment)
	// eslint-disable-next-line no-shadow
	const loading = useSelector(({ formsApp }) => formsApp.department.loading)

	useEffect(() => {
		if (forms.length) {
			const personalizedIndex = forms.findIndex(form => form.personalized && form.personalized.length > 0)
			if (personalizedIndex !== -1) {
				setHasChildren(true)
				// check if empty space exists
				if (header && header[0].id !== null) {
					const newHeader = header.slice()
					newHeader.unshift({ id: null, numeric: false, disablePadding: false, label: null })
					setHeader(newHeader)
				}
			} else {
				setHeader(initialHeader)
				setHasChildren(false)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forms])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	if (!forms.length && !loading) {
		return (
			<FuseAnimate delay={50}>
				<div className="flex flex-1 items-center justify-center h-full">
					<Typography color="textSecondary" variant="h6">
						No data where found
					</Typography>
				</div>
			</FuseAnimate>
		)
	}

	return (
		<TableLoader length={forms.length} loading={loading}>
			<TableContainer>
				<Table stickyHeader>
					<EnhancedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						headCells={header}
					/>
					<TableBody>
						{stableSort(forms, getComparator(order, orderBy)).map((item, key) => {
							return (
								<LayoutFormRow
									folder="project-specific"
									key={key}
									index={key}
									hasChildren={hasChildren}
									item={item}
								/>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</TableLoader>
	)
}

export default withRouter(Forms)
