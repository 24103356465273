import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getCommentDepartments = createAsyncThunk(
	'formsApp/commentDepartments/getCommentDepartments',
	async (params, { dispatch, getState }) => {
		const { comment_set_id } = params
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Comments/departments`, {
			comment_set_id: comment_set_id
		})
		// eslint-disable-next-line no-return-await
		return await response.data.data
	}
)

// Since we don't provide `comment_department_id`, it defaults to assuming `entity.id` is the right field
const departmentsAdapter = createEntityAdapter({
	selectId: department => department.comment_department_id
})

export const {
	selectAll: selectCommentDepartments,
	selectEntities: selectCommentDepartmentEntities,
	selectById: selectCommentDepartmentByRowId
} = departmentsAdapter.getSelectors(state => state.formsApp.commentDepartments)

const commentDepartmentsSlice = createSlice({
	name: 'formsApp/commentDepartments',
	initialState: departmentsAdapter.getInitialState({}),
	reducers: {},
	extraReducers: {
		[getCommentDepartments.fulfilled]: (state, action) => {
			departmentsAdapter.setAll(state, action.payload)
		}
	}
})

export default commentDepartmentsSlice.reducer
