import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getFormShortcuts = createAsyncThunk('dashboardApp/form_shortcuts/getFormShortcuts', async () => {
	const response = await axios.get(process.env.REACT_APP_API_PULSE_URL + '/Dashboard/forms')

	return await response.data.data
})

// The unique IDs of each item. Must be strings or numbers
const dashboardFormsAdapter = createEntityAdapter({
	// Assume IDs are stored in a field other than `shortcut.shortcut_id`
	selectId: shortcut => shortcut.shortcut_id
})

export const { selectAll: selectFormShortcuts, selectById: selectFormShortcutById } =
	dashboardFormsAdapter.getSelectors(
		// select the entity adapter data from the root state
		state => state.dashboardApp.form_shortcuts
	)

const formsSlice = createSlice({
	// A name, used in action types
	name: 'dashboardApp/form_shortcuts',
	// The initial state for the reducer
	initialState: dashboardFormsAdapter.getInitialState({}),
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {},
	/**
	 * A "builder callback" function used to add more reducers,
	 * or an additional object of "case reducers", where the keys should be other action types
	 */
	extraReducers: {
		[getFormShortcuts.fulfilled]: (state, action) => {
			dashboardFormsAdapter.setAll(state, action.payload)
		}
	}
})

export default formsSlice.reducer
