const navigationConfig = [
	{
		id: 'user',
		auth: ['guest'], //<-- all users
		title: 'User',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'dashboard',
				auth: ['guest'], //<-- all users
				title: 'Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/dashboard'
			},
			{
				id: 'notifications',
				auth: ['guest'], //<-- all users
				title: 'Notifications',
				type: 'toggle',
				action: 'toggleNotifications',
				icon: 'notifications',
				url: '/notifications'
			},
			{
				id: 'profile',
				auth: ['guest'], //<-- all users
				title: 'Profile',
				type: 'item',
				icon: 'person',
				url: '/user/profile'
			}
		]
	},
	{
		id: 'applications',
		auth: [1], //<-- default for admins
		title: 'Applications',
		type: 'group',
		icon: 'apps',
		url: null,
		children: [
			{
				id: 'forms',
				auth: [1], //<-- default for admins
				title: 'Forms',
				type: 'collapse',
				icon: 'art_track',
				url: null,
				children: [
					{
						id: 'project-specific',
						auth: [1], //<-- default for admins
						title: 'Project Specific',
						type: 'item',
						icon: 'architecture',
						url: '/forms/project-specific',
						tab: -1
					},
					{
						id: 'generic',
						auth: [1], //<-- default for admins
						title: 'Generic',
						type: 'item',
						icon: 'design_services',
						url: '/forms/generic',
						tab: -1
					}
				]
			},
			{
				id: 'data',
				title: 'Application Data',
				auth: [1], //<-- default for admins
				type: 'collapse',
				icon: 'filter_drama',
				url: null,
				children: [
					{
						id: 'planning-data',
						auth: [1], //<-- default for admins
						title: 'Planning',
						type: 'item',
						icon: 'design_services',
						url: '/data/planning',
						order: '1'
					},
					{
						id: 'administration-data',
						auth: [1], //<-- default for admins
						title: 'Administration',
						type: 'item',
						icon: 'security',
						url: '/data/administration'
					}
				]
			},
			{
				id: 'reports',
				auth: [1], //<-- default for admins
				title: 'Reports',
				type: 'collapse',
				icon: 'bar_chart',
				url: null,
				children: [
					{
						id: 'reports-project-specific',
						auth: [1], //<-- default for admins
						title: 'Project Specific',
						type: 'item',
						icon: 'architecture',
						url: '/reports/project-specific',
						tab: -1
					},
					{
						id: 'reports-generic',
						auth: [1], //<-- default for admins
						title: 'Generic',
						type: 'item',
						icon: 'design_services',
						url: '/reports/generic',
						tab: -1
					},
					{
						id: 'reports-admin-settings',
						auth: [1], //<-- default for admins
						title: 'Settings',
						type: 'item',
						icon: 'admin_panel_settings',
						url: '/reports/admin/data',
						tab: -1
					}
				]
			},
			{
				id: 'contacts',
				auth: [1], //<-- default for admins
				title: 'Contacts',
				type: 'collapse',
				icon: 'account_box',
				url: null,
				parent_id: 'applications'
			}
		]
	},
	{
		id: 'system',
		auth: [1], //<-- default for admins
		title: 'System',
		type: 'group',
		children: [
			{
				id: 'users',
				auth: [1], //<-- default for admins
				title: 'Organization & users',
				type: 'collapse',
				icon: 'account_tree',
				url: null,
				children: [
					{
						id: 'groups',
						auth: [1], //<-- default for admins
						title: 'User Groups',
						type: 'item',
						icon: 'people',
						url: '/user/groups'
					},
					{
						id: 'permissions',
						auth: [1], //<-- default for admins
						title: 'Group Permissions',
						type: 'item',
						icon: 'groups',
						url: '/user/permissions'
					}
				]
			},
			{
				id: 'settings',
				title: 'Settings',
				type: 'collapse',
				icon: 'admin_panel_settings',
				auth: [1], //<-- default for admins
				children: [
					{
						id: 'prototype',
						auth: [1], //<-- default for admins
						title: 'Prototypes',
						type: 'item',
						icon: 'miscellaneous_services',
						url: '/settings/prototypes'
					}
				]
			}
		]
	}
]

export default navigationConfig
