//
// storageSlice.js
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import StorageModel from '../../form/model/StorageModel'

/**
 * Get storage
 * @async Thunk
 */
export const getStorage = createAsyncThunk(
	// action type string
	'formsApp/storage/getStorage',
	// callback function
	async (tab, { dispatch, getState }) => {
		// post data to API
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/storage/${tab}`)
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Set storage
 * @async Thunk
 */
export const setStorage = createAsyncThunk(
	// action type string
	'formsApp/storage/setStorage',
	// callback function
	async (tab, options, { dispatch, getState }) => {
		alert('hourei')
		// post data to API
		const response = await axios.post(`${process.env.REACT_APP_API_PULSE_URL}/Form/storage/${tab}/set`, {
			options: new StorageModel(options)
		})
		// eslint-disable-next-line no-return-await
		return await response.data
	}
)

/**
 * Slice Configuration.
 */
const storageSlice = createSlice({
	// A name, used in action types
	name: 'formsApp/storage',
	// The initial state for the reducer
	initialState: null,
	// An object of "case reducers". Key names will be used to generate actions.
	reducers: {
		saveFieldGroup: (state, action) => {
			const { from, to, field, fieldGroups } = action.payload

			// Set if not exists
			if (typeof fieldGroups !== 'undefined') {
				state.options.groups = fieldGroups
				return
			}

			// Reorder form groups
			if (typeof from !== 'undefined' && typeof to !== 'undefined') {
				state.options.groups.splice(from, 0, state.groups.splice(to, 1)[0])
			}

			// Add
			else if (typeof from !== 'undefined') {
				state.options.groups[from] = field // .push(column.dataField)
			}

			// Remove
			else if (typeof to !== 'undefined') {
				const groupIndex = state.fieldGroups.indexOf(field)

				state.options.groups.splice(groupIndex, 1)
			}
		}
	},
	// A "builder callback" function used to add more reducers, or
	// an additional object of "case reducers", where the keys should be other
	// action types
	extraReducers: {
		[getStorage.fulfilled]: (state, action) => {
			//const { data } = action.payload
		},
		[setStorage.fulfilled]: (state, action) => {
			//const { data } = action.payload
		}
	}
})

export default storageSlice.reducer
