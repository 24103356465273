//
// ProviderConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import DepartmentApp from './DepartmentApp'

const DepartmentConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/forms/:folder/provider/:providerID/department/:departmentID',
			component: DepartmentApp
		}
	]
}

export default DepartmentConfig
