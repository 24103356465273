// eslint-disable-next-line import/extensions
import ProfileConfig from './profile/ProfileConfig.js'
// eslint-disable-next-line import/extensions
import GroupsConfig from './groups/GroupsConfig.js'
// eslint-disable-next-line import/extensions
import PermissionsConfig from './permissions/PermissionsConfig.js'
import globalConfig from '../../fuse-configs/globalConfig'
import React from 'react'

const UserConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: ['/user'],
			component: React.lazy(() => import('./UserApp'))
		}
	]
}

const UserConfigs = [ProfileConfig, GroupsConfig, PermissionsConfig, UserConfig]

export default UserConfigs
