import Dashboard from './Dashboard'
import globalConfig from 'app/fuse-configs/globalConfig'
import { Redirect } from 'react-router-dom'
import React from 'react'

const DashboardConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: ['guest'],
	routes: [
		{
			path: '/',
			exact: true,
			component: () => <Redirect to="/dashboard" />
		},
		{
			path: '/dashboard',
			component: Dashboard
		}
	]
}

export default DashboardConfig
