//
// FormControlSelection.js
//

import { InputLabel, MenuItem, OutlinedInput } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import React from 'react'

const FormControlSelection = props => {
	const [value, setValue] = React.useState(props.value)

	const handleSelect = e => {
		setValue(e.target.value)
		props.onChange(e)
	}

	return (
		<FormControl className="mt-16 mb-16 w-full sm:w-320" variant="outlined" fullWidth>
			<InputLabel htmlFor={`{props.id}-label-placeholder`}>{props.title}</InputLabel>
			<Select
				name={props.name}
				value={value}
				onChange={handleSelect}
				input={
					<OutlinedInput
						labelWidth={`{props.title}`.length * 8 * 0.75}
						name={props.name}
						id={`{props.name}-label-placeholder`}
					/>
				}
				disabled={props.disabled}
			>
				<MenuItem value="0">
					<em>{props.items.length ? props.placeholder : 'Loading...'}</em>
				</MenuItem>
				{props.items}
			</Select>
		</FormControl>
	)
}

export default FormControlSelection
