import { createSlice } from '@reduxjs/toolkit'
import apiService from 'app/services/apiService'
// eslint-disable-next-line import/no-cycle
import { setAccessToken } from './userSlice'

export const submitGetToken = code => async dispatch => {
	return apiService
		.getValidToken(code)
		.then(accessToken => {
			return dispatch(setAccessToken(accessToken))
		})
		.catch(error => {
			return dispatch(loginError(true))
		})
}

const initialState = {
	success: false,
	error: {
		username: null,
		password: null
	}
}

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true
		},
		loginError: (state, action) => {
			state.success = false
			state.error = action.payload
		}
	},
	extraReducers: {}
})

export const { loginSuccess, loginError } = loginSlice.actions

export default loginSlice.reducer
