import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { socketGet } from 'app/SocketContext'
import { showMessage } from 'app/store/fuse/messageSlice'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useDispatch } from 'react-redux'
function CircularProgressWithLabel(props) {
	return props.value > 0 ? (
		<Box
			sx={{
				top: '3px',
				right: '3px',
				position: 'absolute',
				zIndex: 10000
			}}
		>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	) : (
		<></>
	)
}

CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired
}

export default function CircularProgressPercent() {
	const [progress, setProgress] = React.useState(0)

	const dispatch = useDispatch()

	const socket = socketGet()

	React.useEffect(() => {
		socket.on('download_progress', data => {
			const { percent } = data
			setProgress(percent)

			if (percent === 100) {
				setTimeout(() => setProgress(0), 5000)
			}
		})

		socket.on('download_error', data => {
			const { event_id } = data
			setProgress(0)

			dispatch(
				showMessage({
					message: `Download error with id ${event_id}`,
					variant: 'error',
					autoHideDuration: null,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right'
					}
				})
			)
		})
	}, [dispatch, socket])

	return <CircularProgressWithLabel value={progress} />
}
