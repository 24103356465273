import clsx from 'clsx'
import { openNewShortcutDialog } from './store/dashboardSlice'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
	board: {
		cursor: 'pointer',
		transitionProperty: 'box-shadow border-color',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		background: theme.palette.primary.dark,
		color: theme.palette.getContrastText(theme.palette.primary.dark)
	},
	newBoard: {
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: fade(theme.palette.getContrastText(theme.palette.primary.main), 0.6),
		'&:hover': {
			borderColor: fade(theme.palette.getContrastText(theme.palette.primary.main), 0.8)
		}
	}
}))

const ShortcutAdd = props => {
	const dispatch = useDispatch()
	const classes = useStyles(props)

	return (
		<div className="w-192 h-192 p-16">
			<div
				className={clsx(
					classes.board,
					classes.newBoard,
					'flex flex-col items-center justify-center w-full h-full rounded-8 py-24 shadow hover:shadow-lg'
				)}
				onClick={ev => {
					ev.preventDefault()
					dispatch(openNewShortcutDialog())
				}}
				role="button"
				tabIndex={0}
			>
				<Icon className="text-56">add_circle</Icon>
				<Typography className="text-16 font-300 text-center pt-16 px-32" color="inherit">
					Add new shortcut
				</Typography>
			</div>
		</div>
	)
}

export default ShortcutAdd
