//
// FormAppConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'

const FormAppConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/forms/:folder/form/extend/:formID',
			component: React.lazy(() => import('./MyFormCreateApp'))
		},
		{
			path: '/forms/:folder/form/edit/:formID',
			component: React.lazy(() => import('./FormEditApp'))
		},
		{
			path: [
				'/forms/:folder/form/view/:formID/row/:uuid',
				'/forms/:folder/form/view/:formID/tab/:tab',
				'/forms/:folder/form/view/:formID/:params/tab/:tab',
				'/forms/:folder/form/view/:formID/:params?'
			],
			component: React.lazy(() => import('./FormViewApp'))
		},
		{
			path: '/forms/:folder/create/:prototype/:hash',
			component: React.lazy(() => import('./FormCreateApp'))
		},
		{
			path: '/forms/:folder/create/:prototype/path/:path',
			component: React.lazy(() => import('./FormCreateApp'))
		},
		{
			path: [
				'/forms/:folder/form/custom/view/:personalFormID/tab/:tab',
				'/forms/:folder/form/custom/view/:personalFormID/:filters/tab/:tab',
				'/forms/:folder/form/custom/view/:personalFormID/:filters?'
			],
			component: React.lazy(() => import('./MyFormViewApp'))
		},
		{
			path: '/forms/:folder/form/custom/edit/:personalFormID',
			component: React.lazy(() => import('./MyFormEditApp'))
		}
	]
}

export default FormAppConfig
