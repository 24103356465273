//
// projectsSlice.js
//
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

// eslint-disable-next-line no-empty-pattern
export const getProjects = createAsyncThunk(
	'dashboardApp/projects/getProjects',
	async (params, { dispatch, getState }) => {
		const cached = getState().dashboardApp.projects
		if (Object.keys(cached.entities).length > 0) {
			return cached.entities
		}
		const response = await axios.get(`${process.env.REACT_APP_API_PULSE_URL}/Project/location`)
		return await response.data.data
	}
)

const projectsAdapter = createEntityAdapter({
	// assume IDs are stored in a field other than `project.project_id`
	selectId: project => project.project_id
})

export const { selectAll: selectProjects, selectById: selectProjectById } = projectsAdapter.getSelectors(
	state => state.dashboardApp.projects
)

const projectsSlice = createSlice({
	name: 'dashboardApp/projects',
	initialState: projectsAdapter.getInitialState({}),
	reducers: {
		reloadProjects: (state, action) => {
			projectsAdapter.setAll(state, [])
		}
	},
	extraReducers: {
		[getProjects.fulfilled]: projectsAdapter.setAll
	}
})

// eslint-disable-next-line no-empty-pattern
export const { reloadProjects } = projectsSlice.actions

export default projectsSlice.reducer
